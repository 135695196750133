export var HealthCheckType;
(function (HealthCheckType) {
    HealthCheckType["Firmware"] = "firmware";
    HealthCheckType["SdCard"] = "sd-card";
    HealthCheckType["Storage"] = "storage";
    HealthCheckType["Location"] = "location";
    HealthCheckType["GPS"] = "gps";
    HealthCheckType["TelemetryRaw"] = "telemetry-raw";
})(HealthCheckType || (HealthCheckType = {}));
export var OdometerUnit;
(function (OdometerUnit) {
    OdometerUnit[OdometerUnit["Miles"] = 0] = "Miles";
    OdometerUnit[OdometerUnit["Kilometres"] = 1] = "Kilometres";
})(OdometerUnit || (OdometerUnit = {}));
export var MediaMimeType;
(function (MediaMimeType) {
    MediaMimeType[MediaMimeType["VideoMp4"] = 1] = "VideoMp4";
    MediaMimeType[MediaMimeType["ImageJpg"] = 2] = "ImageJpg";
    MediaMimeType[MediaMimeType["ImageJpeg"] = 3] = "ImageJpeg";
})(MediaMimeType || (MediaMimeType = {}));

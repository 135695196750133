var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Button } from 'Components.Form';
import { AuthService } from 'Services';
import { FormattedMessage } from 'Intl';
import { ConfirmationDialog } from '../index';
import { CommissioningApi } from 'Api';
var TopBarContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 3rem;\n  justify-content: space-between;\n  display: flex;\n  align-items: center;\n  padding: .5rem 1rem;\n"], ["\n  height: 3rem;\n  justify-content: space-between;\n  display: flex;\n  align-items: center;\n  padding: .5rem 1rem;\n"])));
var Username = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: 600;\n"], ["\n  color: ", ";\n  font-weight: 600;\n"])), function (props) { return props.theme.colors.white; });
var Location = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color:  ", ";\n  font-size: ", ";\n"], ["\n  color:  ", ";\n  font-size: ", ";\n"])), function (props) { return props.theme.colors.white; }, function (props) { return props.theme.type.sizes.small; });
var LeftSection = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: column;\n"], ["\n  display: flex;\n  flex-flow: column;\n"])));
var LogoutButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: none;\n  color: ", ";\n  font-size: ", ";\n  cursor: pointer;\n  padding: 0;\n"], ["\n  background: none;\n  color: ", ";\n  font-size: ", ";\n  cursor: pointer;\n  padding: 0;\n"])), function (props) { return props.theme.colors.white; }, function (props) { return props.theme.type.sizes.small; });
var TopBar = /** @class */ (function (_super) {
    __extends(TopBar, _super);
    function TopBar(props) {
        var _this = _super.call(this, props) || this;
        _this.commissioningApi = new CommissioningApi();
        _this.onLogout = function () {
            if (_this.props.history.location.pathname === '/device/commission') {
                _this.setState({
                    showConfirmationDialog: true,
                });
            }
            else {
                AuthService.logout();
            }
        };
        _this.handleDismissDialog = function () {
            _this.setState({
                showConfirmationDialog: false,
            });
        };
        _this.componentDidMount = function () {
            _this.GetOrganisationName();
        };
        _this.GetOrganisationName = function () { return __awaiter(_this, void 0, void 0, function () {
            var user, orgId, org;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        user = AuthService.getAccessTokenDetail();
                        orgId = "";
                        if (typeof (user === null || user === void 0 ? void 0 : user.organisation_id) === 'string') {
                            orgId = user.organisation_id;
                        }
                        else if (Array.isArray(user === null || user === void 0 ? void 0 : user.organisation_id)) {
                            orgId = user === null || user === void 0 ? void 0 : user.organisation_id[0];
                        }
                        return [4 /*yield*/, this.commissioningApi.lookupOrganisations({ s: orgId })];
                    case 1:
                        org = _a.sent();
                        if (org.isSuccess && org.payload.organisation.name != 'MSG Automotive Solutions Limited' && org.payload.organisation.name != 'Commissioning') {
                            this.setState({
                                organisationName: org.payload.organisation.name,
                            });
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.state = {
            showConfirmationDialog: false,
            organisationName: 'VisionTrack',
        };
        return _this;
    }
    TopBar.prototype.render = function () {
        var user = AuthService.getAccessTokenDetail();
        var organisationName = this.state.organisationName;
        var showConfirmationDialog = this.state.showConfirmationDialog;
        return (React.createElement(TopBarContainer, null,
            showConfirmationDialog && (React.createElement(ConfirmationDialog, { onDismissDialog: this.handleDismissDialog, onProceed: AuthService.logout })),
            React.createElement(LeftSection, { id: "topBar_userNameAndRole_container" },
                React.createElement(Username, null,
                    user == null ? '-' : user.first_name,
                    " ",
                    user == null ? '-' : user.last_name),
                React.createElement(Location, null,
                    React.createElement(FormattedMessage, { id: 'navigation.role', values: {
                            organisation: organisationName,
                        } }))),
            React.createElement(LogoutButton, { id: "topBar_logout_button", onClick: this.onLogout },
                React.createElement(FormattedMessage, { id: 'common.logOut' }))));
    };
    return TopBar;
}(React.Component));
export default withRouter(TopBar);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

var IdentityService = /** @class */ (function () {
    function IdentityService() {
    }
    IdentityService.getAuthority = function () {
        return window.ATNM != null ? window.ATNM.userManager.settings.authority : undefined;
    };
    IdentityService.getClientId = function () {
        return window.ATNM != null ? window.ATNM.userManager.settings.client_id : undefined;
    };
    IdentityService.getProfileUrl = function () {
        var clientId = IdentityService.getClientId();
        var authority = IdentityService.getAuthority();
        if (clientId == null) {
            throw 'Attempt to call getProfileUrl with a null client id.';
        }
        var params = new URLSearchParams();
        params.append('clientId', clientId);
        return "".concat(authority, "/account/manage?").concat(params.toString());
    };
    IdentityService.getRecoverAccountUrl = function (params) {
        var clientId = IdentityService.getClientId();
        var authority = IdentityService.getAuthority();
        if (clientId == null) {
            throw 'Attempt to call getRecoverAccountUrl with a null client id.';
        }
        params.append('clientId', clientId);
        return "".concat(authority, "/account/recover?").concat(params.toString());
    };
    IdentityService.getCompleteRegistrationUrl = function (params) {
        var authority = IdentityService.getAuthority();
        var clientId = IdentityService.getClientId();
        if (clientId == null) {
            throw 'Attempt to call getCompleteRegistrationUrl with a null client id.';
        }
        params.append('clientId', clientId);
        return "".concat(authority, "/account/complete?").concat(params.toString());
    };
    return IdentityService;
}());
export default IdentityService;

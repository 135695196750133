var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styled from 'styled-components';
import { ProcessStep, CompletedStep } from 'Components.Structure';
import { toast } from 'react-toastify';
var TextArea = styled.textarea(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: 150px;\n  padding: .5rem;\n  line-height: 1.3rem;\n  font-family: ", ";\n  background-color: #f7f7f7;\n  border-radius: 3px;\n  border: none;\n  resize: none;\n"], ["\n  min-height: 150px;\n  padding: .5rem;\n  line-height: 1.3rem;\n  font-family: ", ";\n  background-color: #f7f7f7;\n  border-radius: 3px;\n  border: none;\n  resize: none;\n"])), function (props) { return props.theme.type.family; });
var FreeTextProcessStep = /** @class */ (function (_super) {
    __extends(FreeTextProcessStep, _super);
    function FreeTextProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.handleTextAreaChange = function (evt) {
            _this.setState({
                value: evt.currentTarget.value,
            });
        };
        _this.handleConfirm = function () {
            if (!_this.state.value) {
                toast.error('Please enter notes first');
                return;
            }
            _this.setState({
                confirmed: true,
            });
            _this.props.onConfirm(_this.state.value);
        };
        _this.handleEditStep = function () {
            _this.setState({
                confirmed: false,
            });
            _this.props.onEdit();
        };
        _this.state = {
            confirmed: false,
            value: '',
        };
        return _this;
    }
    FreeTextProcessStep.prototype.render = function () {
        var _a = this.props, label = _a.label, subtitle = _a.subtitle, confirmedTitle = _a.confirmedTitle, confirmedSubtitle = _a.confirmedSubtitle, placeholder = _a.placeholder, required = _a.required, baseId = _a.baseId;
        var _b = this.state, confirmed = _b.confirmed, value = _b.value;
        return (React.createElement(ProcessStep, { baseId: baseId, label: label, subtitle: subtitle, confirmed: confirmed, confirmDisabled: required != null && required && value.length === 0, activeContent: React.createElement(TextArea, { id: "".concat(baseId, "_textArea"), placeholder: placeholder, value: value, onChange: this.handleTextAreaChange }), onConfirm: this.handleConfirm, onEditStep: this.handleEditStep, confirmedContent: (React.createElement(CompletedStep, { title: confirmedTitle, subtitle: confirmedSubtitle })) }));
    };
    FreeTextProcessStep.defaultProps = {
        confirmedTitle: null,
        confirmedSubtitle: null,
        placeholder: '',
        required: false,
    };
    return FreeTextProcessStep;
}(React.PureComponent));
export default FreeTextProcessStep;
var templateObject_1;

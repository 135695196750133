var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { ProcessStep, CompletedStep } from 'Components.Structure';
var ConfirmProcessStep = /** @class */ (function (_super) {
    __extends(ConfirmProcessStep, _super);
    function ConfirmProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.handleConfirm = function () {
            _this.setState({
                confirmed: true,
            });
            _this.props.onConfirm();
        };
        _this.handleEdit = function () {
            _this.setState({
                confirmed: false,
            });
            _this.props.onEdit();
        };
        _this.state = {
            confirmed: false,
        };
        return _this;
    }
    ConfirmProcessStep.prototype.render = function () {
        var _a = this.props, label = _a.label, subtitle = _a.subtitle, completedTitle = _a.completedTitle, completedSubtitle = _a.completedSubtitle, baseId = _a.baseId;
        var confirmed = this.state.confirmed;
        return (React.createElement(ProcessStep, { baseId: baseId, label: label, subtitle: subtitle, confirmed: confirmed, activeContent: null, confirmedContent: (React.createElement(CompletedStep, { title: completedTitle, subtitle: completedSubtitle })), onConfirm: this.handleConfirm, onEditStep: this.handleEdit }));
    };
    ConfirmProcessStep.defaultProps = {
        subtitle: null,
        completedSubtitle: null,
    };
    return ConfirmProcessStep;
}(React.PureComponent));
export default ConfirmProcessStep;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { DeviceService } from 'Services';
import { ConfirmProcessStep, ProcessStep, CompletedStep, } from 'Components.Structure';
import { injectIntl } from 'Intl';
import { CalibrateCommandViewer } from './components';
var AccelerometerCalibrateProcessStep = /** @class */ (function (_super) {
    __extends(AccelerometerCalibrateProcessStep, _super);
    function AccelerometerCalibrateProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.handleConfirm = function () {
            _this.props.onConfirm(_this.state.commandSent, false);
        };
        _this.handleEdit = function () {
            _this.setState({
                confirmed: false,
            });
            _this.props.onEdit();
        };
        _this.handleCalibrateComplete = function () {
            _this.setState({
                confirmed: true,
                commandSent: true,
            }, _this.handleConfirm);
        };
        _this.handleTimeout = function () {
            _this.setState({ commandTimeout: true });
        };
        _this.handleSelfCertify = function () {
            _this.setState({ confirmed: true });
            _this.props.onConfirm(_this.state.commandSent, true);
        };
        var requireCommand = DeviceService.requireAccelerometerCalibrationCommand(props.device.model);
        _this.state = {
            confirmed: false,
            commandSent: false,
            requireCommand: requireCommand,
            commandTimeout: false,
        };
        return _this;
    }
    AccelerometerCalibrateProcessStep.prototype.render = function () {
        var _a = this.props, label = _a.label, baseId = _a.baseId, intl = _a.intl, sensorsSelfCertifed = _a.sensorsSelfCertifed, showSelfCertify = _a.showSelfCertify;
        var _b = this.state, requireCommand = _b.requireCommand, confirmed = _b.confirmed, commandTimeout = _b.commandTimeout;
        return (React.createElement(React.Fragment, null,
            !requireCommand && (React.createElement(ConfirmProcessStep, { baseId: "".concat(baseId, "_confirm"), label: label, subtitle: intl.formatMessage('accelerometerCalibrate.description'), completedTitle: intl.formatMessage('accelerometerCalibrate.confirmedLabel'), onConfirm: this.handleConfirm, onEdit: this.handleEdit })),
            requireCommand && (React.createElement(ProcessStep, { baseId: baseId, label: label, subtitle: intl.formatMessage('accelerometerCalibrate.description'), confirmed: confirmed, confirmDisabled: !confirmed, activeContent: (React.createElement(CalibrateCommandViewer, { baseId: baseId, device: this.props.device, handleComplete: this.handleCalibrateComplete, onTimeout: this.handleTimeout })), confirmedContent: (React.createElement(CompletedStep, { title: intl.formatMessage('accelerometerCalibrate.confirmation'), subtitle: intl.formatMessage('accelerometerCalibrate.confirmationSubtitle') })), onConfirm: this.handleConfirm, onEditStep: this.handleEdit, showSelfCertify: showSelfCertify && commandTimeout, onSelfCertify: this.handleSelfCertify, hasSelfCertifed: sensorsSelfCertifed }))));
    };
    return AccelerometerCalibrateProcessStep;
}(React.PureComponent));
export default injectIntl(AccelerometerCalibrateProcessStep);

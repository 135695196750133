import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { UserManager } from 'oidc-client';
import { IntlProvider } from 'react-intl';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import { ConfigurationApi } from 'Api';
import { AuthService, StorageService } from 'Services';
import { translation } from 'Utils';
import App from './components/App';
import pwa from './pwa';
import { store } from './store/store';
import IntlGlobalBinder from './components/intl/IntlGlobalBinder';
pwa.registerServiceWorker();
var configurationApi = new ConfigurationApi();
var locale = navigator.language;
var browserHistory = createBrowserHistory();
var reactPlugin = new ReactPlugin();
var getAppInsightsKey = function () {
    fetch("".concat(window.location.origin, "/api/platform/ai"), {
        method: "GET"
    })
        .then(function (response) { return response.text(); })
        .then(function (result) {
        var _a;
        var appInsights = new ApplicationInsights({
            config: {
                appId: 'Engineering-UI',
                connectionString: result,
                extensions: [reactPlugin],
                disableAjaxTracking: false,
                disableFetchTracking: false,
                autoTrackPageVisitTime: true,
                enableAutoRouteTracking: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                cookieDomain: '.visiontrack.com',
                correlationHeaderDomains: ['visiontrack.com'],
                enableCorsCorrelation: true,
                extensionConfig: (_a = {},
                    _a[reactPlugin.identifier] = { history: browserHistory },
                    _a),
            },
        });
        appInsights.loadAppInsights();
        appInsights.trackPageView(); // Enables AppInsights to handle automated exception logging. Hooks into window.onError() to capture unhandled exceptions.
        window.appInsights = appInsights;
    });
};
getAppInsightsKey();
function bubbleError(error) {
    var token = AuthService.getAccessTokenDetail();
    var userID = '';
    if (token != null && (token === null || token === void 0 ? void 0 : token.email) != null) {
        userID = token.email;
    }
    else if (token != null && token.first_name != null && token.last_name != null) {
        userID = "".concat(token.first_name, " ").concat(token.last_name);
    }
    window.appInsights.trackException({
        error: error,
        exception: error,
        severityLevel: SeverityLevel.Error,
    }, //Custom Properties for Exception Callback
    {
        User_ID: userID
    });
}
window.addEventListener('unhandledrejection', function (event) {
    console.error('Unhandled rejection (promise: ', event.promise, ', reason: ', event.reason, ').');
    bubbleError(event.reason);
});
window.onerror = function (message, source, lineno, colno, error) {
    window.appInsights.trackException({
        error: error,
        exception: error,
        severityLevel: SeverityLevel.Error,
    });
};
var renderApp = function () {
    var element = document.getElementById('app');
    if (!element)
        return;
    render(React.createElement(Provider, { store: store },
        React.createElement(IntlProvider, { locale: locale, messages: translation(locale) },
            React.createElement(IntlGlobalBinder, null),
            React.createElement(App, null))), element);
};
configurationApi.getEndpoints().then(function (result) {
    if (!result.isSuccess)
        return;
    StorageService.setPersisted({
        key: 'vt:eng:endpoints',
        value: JSON.stringify(result.payload),
    });
    window.ATNM = {
        userManager: new UserManager({
            authority: result.payload.authority,
            client_id: result.payload.clientId,
            redirect_uri: result.payload.loginRedirectUri,
            response_type: 'code',
            scope: 'openid profile vt.engineering offline_access',
            post_logout_redirect_uri: result.payload.postLogoutRedirectUri,
        }),
        intl: null,
        testingUsers: result.payload.testingUsers,
    };
    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./components/App', renderApp);
    }
    renderApp();
});

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Button } from 'Components.Form';
var Title = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n"], ["\n  flex: 1;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n"])));
var SubTitle = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-transform: uppercase;\n  font-weight: ", ";\n  color: ", ";\n  margin: 0;\n  padding: 0;\n  font-size: ", ";\n  letter-spacing: 0.65rem;\n  margin-left: 0.65rem;\n"], ["\n  text-transform: uppercase;\n  font-weight: ", ";\n  color: ", ";\n  margin: 0;\n  padding: 0;\n  font-size: ", ";\n  letter-spacing: 0.65rem;\n  margin-left: 0.65rem;\n"])), function (props) { return props.theme.type.weight.regular; }, function (props) { return props.theme.colors.white; }, function (props) { return props.theme.type.sizes.default; });
var LoginContainer = styled.main(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-flow: column;\n  align-items: flex-end;\n"], ["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-flow: column;\n  align-items: flex-end;\n"])));
var LoginLoading = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: ", ";\n  color: ", ";\n  font-size: ", ";\n  flex: 2;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n"], ["\n  font-weight: ", ";\n  color: ", ";\n  font-size: ", ";\n  flex: 2;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n"])), function (props) { return props.theme.type.weight.regular; }, function (props) { return props.theme.colors.white; }, function (props) { return props.theme.type.sizes.default; });
var LoginError = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: ", ";\n  color: ", ";\n  font-size: ", ";\n  flex: 2;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  text-align: center;\n"], ["\n  font-weight: ", ";\n  color: ", ";\n  font-size: ", ";\n  flex: 2;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  text-align: center;\n"])), function (props) { return props.theme.type.weight.regular; }, function (props) { return props.theme.colors.white; }, function (props) { return props.theme.type.sizes.default; });
var LoginButtonContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 90%;\n  margin-bottom: 2rem;\n"], ["\n  width: 90%;\n  margin-bottom: 2rem;\n"])));
var LoginButton = styled(Button)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border: none;\n  background-color: white;\n  width: 100%;\n  text-transform: uppercase;\n  box-shadow: #00000040 1px 1px 3px 0px;\n  color: ", ";\n"], ["\n  border: none;\n  background-color: white;\n  width: 100%;\n  text-transform: uppercase;\n  box-shadow: #00000040 1px 1px 3px 0px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.blue[50]; });
export { Title, SubTitle, LoginContainer, LoginLoading, LoginError, LoginButtonContainer, LoginButton, };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background-color: ", ";\n    color: ", ";\n    padding: .5rem;\n    display: flex;\n    align-items: center;\n    flex-flow: column;\n\n    & + & {\n        margin-top: .5rem;\n    }\n\n    ", "\n"], ["\n    background-color: ", ";\n    color: ", ";\n    padding: .5rem;\n    display: flex;\n    align-items: center;\n    flex-flow: column;\n\n    & + & {\n        margin-top: .5rem;\n    }\n\n    ", "\n"])), function (props) { return props.theme.colors.red[50]; }, function (props) { return props.theme.colors.white; }, function (props) { return props.matches && (css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        background-color: ", ";\n    "], ["\n        background-color: ", ";\n    "])), function (themeProps) { return themeProps.theme.colors.green[50]; })); });
var Title = styled.h5(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size: ", ";\n    margin: 0 0 .2rem 0;\n"], ["\n    font-size: ", ";\n    margin: 0 0 .2rem 0;\n"])), function (props) { return props.theme.type.sizes.small; });
var Message = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: ", ";\n"], ["\n    font-size: ", ";\n"])), function (props) { return props.theme.type.sizes.small; });
var ChannelCompare = function (props) {
    var requiredChannels = props.requiredChannels, receivedChannels = props.receivedChannels, missingTitle = props.missingTitle, matchTitle = props.matchTitle, matchMessage = props.matchMessage, missingMessage = props.missingMessage, baseId = props.baseId;
    var missing = requiredChannels.filter(function (c) { return !receivedChannels.includes(c); });
    if (missing.length === 0 && matchMessage == null && matchTitle == null) {
        return null;
    }
    return (React.createElement(Container, { matches: missing.length === 0 },
        React.createElement(Title, { id: "".concat(baseId, "_title_h5") }, missing.length === 0 ? matchTitle : missingTitle),
        React.createElement(Message, { id: "".concat(baseId, "_message_p") }, missing.length === 0 ? matchMessage : missingMessage(missing))));
};
export default ChannelCompare;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styled from 'styled-components';
import { ProcessStep, CompletedStep } from 'Components.Structure';
var ReviewImage = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 22%;\n  padding-top: .5rem;\n"], ["\n  max-width: 22%;\n  padding-top: .5rem;\n"])));
var ImageContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: row;\n  align-items: center;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n"], ["\n  display: flex;\n  flex-flow: row;\n  align-items: center;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n"])));
var SelectedImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-width: 100%;\n  margin: 0 auto;\n  max-height: 200px;\n"], ["\n  max-width: 100%;\n  margin: 0 auto;\n  max-height: 200px;\n"])));
var ConfirmCommissionImagesProcessStep = /** @class */ (function (_super) {
    __extends(ConfirmCommissionImagesProcessStep, _super);
    function ConfirmCommissionImagesProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.fetchMediaSignatures = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        }); };
        _this.handleImageClick = function (image) { return function () {
            _this.setState({
                selectedImage: image,
            });
        }; };
        _this.handleConfirm = function () {
            _this.setState({
                confirmed: true,
            });
            _this.props.onConfirm();
        };
        _this.handleEdit = function () {
            _this.setState({
                confirmed: false,
            });
            _this.props.onEdit();
        };
        _this.state = {
            tokens: null,
            loading: true,
            confirmed: false,
            selectedImage: null,
        };
        return _this;
    }
    ConfirmCommissionImagesProcessStep.prototype.componentDidMount = function () {
        this.fetchMediaSignatures();
    };
    ConfirmCommissionImagesProcessStep.prototype.render = function () {
        var _this = this;
        return (React.createElement(ProcessStep, { label: this.props.label, subtitle: this.props.subtitle, loading: this.state.loading, confirmed: this.state.confirmed, activeContent: (React.createElement(React.Fragment, null,
                this.state.selectedImage != null && (React.createElement(SelectedImage, { key: this.state.selectedImage, src: this.state.selectedImage, alt: "Current" })),
                this.state.tokens != null && (React.createElement(ImageContainer, null, this.state.tokens.map(function (t) { return React.createElement(ReviewImage, { onClick: _this.handleImageClick(t), key: t, src: t, alt: "Install" }); }))))), confirmedContent: (React.createElement(CompletedStep, { title: "Images Confirmed" })), onConfirm: this.handleConfirm, onEditStep: this.handleEdit }));
    };
    return ConfirmCommissionImagesProcessStep;
}(React.Component));
export default ConfirmCommissionImagesProcessStep;
var templateObject_1, templateObject_2, templateObject_3;

export var orderByFields = function (fields) { return function (a, b) { return fields.map(function (o) {
    var desc = o[0] === '-';
    var dir = desc ? -1 : 1;
    var field = desc ? o.substring(1) : o;
    if (a[field] > b[field])
        return dir;
    if (a[field] < b[field])
        return -(dir);
    return 0;
}).reduce(function (p, n) { return (p ? p : n); }, 0); }; };
export default orderByFields;

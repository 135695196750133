var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import styled, { css } from 'styled-components';
import { FileInput } from 'Components.Form';
import { LoadingStep } from 'Components.Structure';
import { Camera } from 'Icons';
var UploadContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 5px;\n  position: relative;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  min-height: ", ";\n  background-color: ", ";\n  ", "\n"], ["\n  border-radius: 5px;\n  position: relative;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  min-height: ", ";\n  background-color: ", ";\n  ", "\n"])), function (props) { return props.containerHeight; }, function (props) { return props.theme.colors.grey[0]; }, function (props) { return props.noBottomBorderRadius === true && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border-bottom-left-radius: 0;\n    border-bottom-right-radius: 0;\n  "], ["\n    border-bottom-left-radius: 0;\n    border-bottom-right-radius: 0;\n  "]))); });
var FullSizeFileInput = styled(FileInput)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  opacity: 0;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n"], ["\n  opacity: 0;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n"])));
var UploadLabel = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: .8rem;\n  margin: 1rem 0 0;\n  color: ", ";\n"], ["\n  font-size: .8rem;\n  margin: 1rem 0 0;\n  color: ", ";\n"])), function (props) { return props.theme.colors.grey[25]; });
var Image = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 1px;\n  max-width: ", ";\n  max-height: ", ";\n  display: ", ";\n"], ["\n  padding: 1px;\n  max-width: ", ";\n  max-height: ", ";\n  display: ", ";\n"])), function (props) { return (props.multiple ? 'auto' : '175px'); }, function (props) { return (props.multiple ? '100px' : 'auto'); }, function (props) { return (props.loading ? 'none' : 'static'); });
var ImageContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: row;\n  justify-content: space-around;\n  align-items: center;\n  flex-wrap: wrap;\n  padding: .5rem;\n  border: 1px solid ", ";\n\n  ", "\n"], ["\n  display: flex;\n  flex-flow: row;\n  justify-content: space-around;\n  align-items: center;\n  flex-wrap: wrap;\n  padding: .5rem;\n  border: 1px solid ", ";\n\n  ", "\n"])), function (props) { return props.theme.colors.grey[0]; }, function (props) { return props.multiple === true && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    border-top: none;\n  "], ["\n    border-top: none;\n  "]))); });
var ImageUploader = /** @class */ (function (_super) {
    __extends(ImageUploader, _super);
    function ImageUploader(props) {
        var _this = _super.call(this, props) || this;
        _this.inputRef = React.createRef();
        _this.canvasRef = React.createRef();
        _this.imageRef = React.createRef();
        _this.handleChangeCallback = function () {
            _this.props.onChange(_this.state.files);
        };
        _this.handleChange = function (evt) {
            var files = evt.currentTarget.files;
            var fileArray = [];
            if (files != null && files.length > 0) {
                for (var i = 0; i < files.length; i += 1) {
                    fileArray.push(files[i]);
                }
            }
            _this.setState(function (prevState) { return ({
                files: __spreadArray(__spreadArray([], prevState.files, true), fileArray, true),
            }); }, _this.handleChangeCallback);
        };
        _this.handleClearImage = function (index) { return function () {
            _this.setState(function (prevState) { return ({
                files: prevState.files.filter(function (f, i) { return i !== index; }),
            }); }, _this.handleChangeCallback);
        }; };
        _this.state = {
            files: props.images || [],
        };
        return _this;
    }
    ImageUploader.prototype.render = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null,
            (this.state.files.length === 0 || this.props.multiple) && (React.createElement(UploadContainer, { containerHeight: this.props.height, noBottomBorderRadius: this.state.files.length > 0 },
                React.createElement(FullSizeFileInput, { ref: this.inputRef, onChange: this.handleChange, accept: "image/*", capture: this.props.capture, multiple: this.props.multiple }),
                React.createElement(Camera, { width: "62px", color: "#bfbfbf" }),
                React.createElement(UploadLabel, null, "Upload Image"))),
            this.props.loading && (React.createElement(LoadingStep, null)),
            this.state.files.length > 0 && (React.createElement(ImageContainer, { multiple: this.props.multiple }, this.state.files.length > 0 && this.state.files.map(function (f, index) { return (React.createElement(Image, { key: f.name, loading: _this.props.loading, ref: _this.imageRef, src: URL.createObjectURL(f), multiple: _this.props.multiple, onClick: _this.handleClearImage(index) })); })))));
    };
    ImageUploader.defaultProps = {
        height: '200px',
        capture: null,
        multiple: false,
        images: null,
    };
    return ImageUploader;
}(React.PureComponent));
export default ImageUploader;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

export var Locales;
(function (Locales) {
    Locales["csCZ"] = "cs-CZ";
    Locales["daDK"] = "da-DK";
    Locales["deDE"] = "de-DE";
    Locales["enGB"] = "en-GB";
    Locales["enUS"] = "en-US";
    Locales["esES"] = "es-ES";
    Locales["frFR"] = "fr-FR";
    Locales["itIT"] = "it-IT";
    Locales["nlNL"] = "nl-NL";
    Locales["plPL"] = "pl-PL";
    Locales["ptPT"] = "pt-PT";
    Locales["svSE"] = "sv-SE";
})(Locales || (Locales = {}));
export var LocalesMap = new Map([
    [Locales.csCZ, 'čeština (Česko)'],
    [Locales.daDK, 'dansk (Danmark)'],
    [Locales.deDE, 'Deutsch (Deutschland)'],
    [Locales.enGB, 'English (United Kingdom)'],
    [Locales.enUS, 'English (United States)'],
    [Locales.esES, 'español (España)'],
    [Locales.frFR, 'français (France)'],
    [Locales.itIT, 'italiano (Italia)'],
    [Locales.nlNL, 'Nederlands (Nederland)'],
    [Locales.plPL, 'polski (Polska)'],
    [Locales.ptPT, 'português (Portugal)'],
    [Locales.svSE, 'svenska (Sverige)'],
]);
export var getPhoneNumberCodeForLocale = function (value) {
    switch (value.toLowerCase()) {
        case 'cs-cz': return '420';
        case 'da-dk': return '45';
        case 'de-de': return '49';
        case 'en-db': return '44';
        case 'en-us': return '1';
        case 'es-es': return '34';
        case 'fr-fr': return '33';
        case 'it-it': return '39';
        case 'nl-nl': return '31';
        case 'pl-pl': return '48';
        case 'pt-pt': return '351';
        case 'sv-se': return '46';
        default: return '44';
    }
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { QuestionOctagon } from 'Icons';
import { FormattedMessage } from 'Intl';
var SelfCertifiedStepContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: row;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-flow: row;\n  align-items: center;\n"])));
var SelfCertifiedStepInfo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n  padding-left: .5rem;\n"], ["\n  flex: 1;\n  padding-left: .5rem;\n"])));
var SelfCertifiedStepHeader = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: bold;\n"], ["\n  font-weight: bold;\n"])));
var SelfCertifiedStepSubtitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: ", ";\n"], ["\n  font-size: ", ";\n"])), function (props) { return props.theme.type.sizes.small; });
var QuestionOctagonContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border-radius: 100%;\n  background-color: ", ";\n  width: 2rem;\n  height: 2rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  border-radius: 100%;\n  background-color: ", ";\n  width: 2rem;\n  height: 2rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])), function (props) { return props.theme.colors.amber[50]; });
var SelfCertifiedStep = function () { return (React.createElement(SelfCertifiedStepContainer, null,
    React.createElement(QuestionOctagonContainer, null,
        React.createElement(QuestionOctagon, { fill: "white", width: "1.1rem", height: "1.1rem" })),
    React.createElement(SelfCertifiedStepInfo, null,
        React.createElement(SelfCertifiedStepHeader, null,
            React.createElement(FormattedMessage, { id: 'common.selfCertified.title' })),
        React.createElement(SelfCertifiedStepSubtitle, null,
            React.createElement(FormattedMessage, { id: 'common.selfCertified.description' }))))); };
export default SelfCertifiedStep;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styled from 'styled-components';
import { getMoment } from 'Lazy';
import { toast } from 'react-toastify';
import { injectIntl, FormattedMessage } from 'Intl';
import { ProcessStep, CompletedStep } from 'Components.Structure';
import { CommissioningApi } from 'Api';
import { Button } from 'Components.Form';
import { DeviceHealthStep } from './components';
var ConfirmationContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: column;\n  padding: 1rem;\n"], ["\n  display: flex;\n  flex-flow: column;\n  padding: 1rem;\n"])));
var ConfirmationMessage = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n  text-align: center;\n  color: ", ";\n  font-size: .9rem;\n"], ["\n  margin-bottom: 1rem;\n  text-align: center;\n  color: ", ";\n  font-size: .9rem;\n"])), function (props) { return props.theme.colors.red[100]; });
var ConfirmDeviceHealthProcessStep = /** @class */ (function (_super) {
    __extends(ConfirmDeviceHealthProcessStep, _super);
    function ConfirmDeviceHealthProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.getLocation = function () { return new Promise(function (resolve) {
            navigator.geolocation.getCurrentPosition(function (position) {
                resolve({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            });
        }); };
        _this.handleRunHealthCheckClick = function (_a) {
            var startDate = _a.startDate;
            return function () {
                _this.fetchDeviceHealthOverview({
                    deviceId: _this.props.deviceId,
                    startDate: startDate,
                });
            };
        };
        _this.handleConfirm = function () {
            var deviceHealth = _this.state.deviceHealth;
            if (deviceHealth == null)
                return;
            _this.setState({
                confirmed: true,
            });
            _this.props.onConfirm(deviceHealth, false);
        };
        _this.handleEditStep = function () {
            _this.setState({
                confirmed: false,
            });
            _this.props.onEdit();
        };
        _this.handleSelfCertify = function () {
            var deviceHealth = _this.state.deviceHealth;
            if (deviceHealth == null)
                return;
            _this.setState({
                confirmed: true,
            });
            _this.props.onConfirm(deviceHealth, true);
        };
        _this.state = {
            confirmed: false,
            loading: false,
            deviceHealth: null,
            showConfirmation: true,
            moment: null,
        };
        _this.commissioningApi = new CommissioningApi();
        return _this;
    }
    ConfirmDeviceHealthProcessStep.prototype.componentDidMount = function () {
        this.fetchMoment();
    };
    ConfirmDeviceHealthProcessStep.prototype.fetchDeviceHealthOverview = function (_a) {
        var deviceId = _a.deviceId, startDate = _a.startDate;
        return __awaiter(this, void 0, void 0, function () {
            var intl, location, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        intl = this.props.intl;
                        this.setState({
                            loading: true,
                            showConfirmation: false,
                        });
                        return [4 /*yield*/, this.getLocation()];
                    case 1:
                        location = _b.sent();
                        if (location == null) {
                            toast.error(intl.formatMessage('confirmDeviceHealth.locationError'));
                            this.setState({
                                loading: false,
                            });
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.commissioningApi.lookupDeviceHealth(__assign({ deviceId: deviceId, deviceLocation: location }, ((startDate != null) ? { startDate: startDate.format() } : null)))];
                    case 2:
                        result = _b.sent();
                        if (result.isSuccess) {
                            this.setState({
                                loading: false,
                                deviceHealth: result.payload,
                            });
                        }
                        else {
                            this.setState({
                                loading: false,
                                showConfirmation: true,
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ConfirmDeviceHealthProcessStep.prototype.resolveConfirmDisabled = function () {
        var deviceHealth = this.state.deviceHealth;
        if (deviceHealth == null)
            return true;
        if (deviceHealth.isValid)
            return false;
        return true;
    };
    ConfirmDeviceHealthProcessStep.prototype.fetchMoment = function () {
        return __awaiter(this, void 0, void 0, function () {
            var moment;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getMoment()];
                    case 1:
                        moment = _a.sent();
                        this.setState({
                            moment: moment.default,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ConfirmDeviceHealthProcessStep.prototype.render = function () {
        var _a = this.state, showConfirmation = _a.showConfirmation, deviceHealth = _a.deviceHealth, moment = _a.moment;
        var _b = this.props, baseId = _b.baseId, intl = _b.intl, selfCertifed = _b.selfCertifed, allowSelfCertification = _b.allowSelfCertification;
        return (React.createElement(ProcessStep, { baseId: baseId, label: this.props.label, subtitle: intl.formatMessage('confirmDeviceHealth.description'), confirmed: this.state.confirmed, confirmDisabled: this.resolveConfirmDisabled(), loading: this.state.loading, confirmedContent: (React.createElement(CompletedStep, { title: intl.formatMessage('confirmDeviceHealth.healthyTitle'), subtitle: intl.formatMessage('confirmDeviceHealth.healthyDescription') })), onConfirm: this.handleConfirm, onEditStep: this.handleEditStep, showSelfCertify: allowSelfCertification && !showConfirmation && !(deviceHealth === null || deviceHealth === void 0 ? void 0 : deviceHealth.isValid), onSelfCertify: this.handleSelfCertify, hasSelfCertifed: selfCertifed, activeContent: (React.createElement(React.Fragment, null,
                showConfirmation && (React.createElement(ConfirmationContainer, null,
                    React.createElement(ConfirmationMessage, null,
                        React.createElement(FormattedMessage, { id: 'confirmDeviceHealth.warning' })),
                    React.createElement(Button, { id: "".concat(baseId, "_runHealthCheck_button"), primary: true, onClick: this.handleRunHealthCheckClick({ startDate: moment && moment.utc().subtract('minutes', 30) }) },
                        React.createElement(FormattedMessage, { id: 'confirmDeviceHealth.runHealthCheckButton' })))),
                !showConfirmation && (React.createElement(DeviceHealthStep, { baseId: "".concat(baseId, "_deviceHealth"), deviceHealth: deviceHealth, onRefresh: this.handleRunHealthCheckClick({ startDate: moment && moment.utc().subtract('minutes', 2) }) })))) }));
    };
    return ConfirmDeviceHealthProcessStep;
}(React.Component));
export default injectIntl(ConfirmDeviceHealthProcessStep);
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from 'styled-components';
import { debounce, isEmpty } from 'Utils';
import { CompletedStep, ProcessStep } from 'Components.Structure';
import { Button, ButtonGroup, AsyncSelect } from 'Components.Form';
import { CommissioningApi } from 'Api';
import { injectIntl, FormattedMessage } from 'Intl';
var FindVehicleContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: .3rem;\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n"], ["\n  margin-bottom: .3rem;\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n"])));
var NewVehicleContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: .3rem;\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n"], ["\n  margin-bottom: .3rem;\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n"])));
var SearchCreateDivider = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: .5rem;\n  display: flex;\n  justify-content: center;\n"], ["\n  margin: .5rem;\n  display: flex;\n  justify-content: center;\n"])));
var VrnVinButtonGroup = styled(ButtonGroup)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: .5rem;\n  justify-content: flex-end;\n  > ", " {\n    flex: initial;\n  }\n"], ["\n  margin-bottom: .5rem;\n  justify-content: flex-end;\n  > ", " {\n    flex: initial;\n  }\n"])), Button);
var FindVehicleProcessStep = /** @class */ (function (_super) {
    __extends(FindVehicleProcessStep, _super);
    function FindVehicleProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.setConfirmed = function (confirmed) {
            _this.setState({
                confirmed: confirmed,
            });
        };
        _this.setSelectedVehicle = function (selectedVehicle) {
            _this.setState({
                selectedVehicle: selectedVehicle,
            });
        };
        _this.setLookupType = function (lookupType) { return function () {
            _this.setState({
                lookupType: lookupType,
            });
        }; };
        _this.loadOptions = function () { return function (inputValue, callback) {
            var lookupType = _this.state.lookupType;
            if (inputValue == null || inputValue == '')
                return;
            _this.commissioningApi.lookupVehicle(__assign(__assign({}, ((lookupType === 'vrn') ? { vrn: inputValue } : null)), ((lookupType === 'vin') ? { vin: inputValue } : null))).then(function (result) {
                if (result.isSuccess && result.payload) {
                    callback(isEmpty(result.payload) ? [] : [{
                            label: result.payload.vrn,
                            value: result.payload,
                        }]);
                }
            });
        }; };
        _this.state = {
            selectedVehicle: null,
            confirmed: false,
            lookupType: 'vrn'
        };
        _this.commissioningApi = new CommissioningApi();
        return _this;
    }
    FindVehicleProcessStep.prototype.render = function () {
        var _this = this;
        var _a = this.state, confirmed = _a.confirmed, selectedVehicle = _a.selectedVehicle, lookupType = _a.lookupType;
        var _b = this.props, label = _b.label, loading = _b.loading, vehicle = _b.vehicle, createNew = _b.createNew, baseId = _b.baseId, intl = _b.intl;
        return (React.createElement(ProcessStep, { baseId: baseId, confirmed: confirmed, label: label, subtitle: intl.formatMessage('findVehicle.description'), loading: loading, confirmDisabled: selectedVehicle == null, onEditStep: function () {
                _this.props.onEdit();
                _this.setConfirmed(!confirmed);
                _this.setSelectedVehicle(null);
            }, onConfirm: function () {
                if (selectedVehicle == null)
                    return;
                _this.setConfirmed(!confirmed);
                _this.props.onConfirm({
                    createNew: false,
                    vehicle: selectedVehicle.value,
                });
            }, confirmedContent: (React.createElement(React.Fragment, null, (vehicle != null || createNew != null) && (React.createElement(CompletedStep, { title: createNew
                    ? intl.formatMessage('findVehicle.newVehicle')
                    : (vehicle ? vehicle.vrn : ''), subtitle: createNew
                    ? intl.formatMessage('findVehicle.newVehicleDescription')
                    : (vehicle ? "".concat(vehicle.make, " - ").concat(vehicle.model) : '') })))), activeContent: (React.createElement(FindVehicleContainer, null,
                React.createElement(VrnVinButtonGroup, null,
                    React.createElement(Button, { id: "".concat(baseId, "_vrn_button"), onClick: this.setLookupType('vrn'), primary: lookupType === 'vrn' },
                        React.createElement(FormattedMessage, { id: 'common.vrn' })),
                    React.createElement(Button, { id: "".concat(baseId, "_vin_button"), onClick: this.setLookupType('vin'), primary: lookupType === 'vin' },
                        React.createElement(FormattedMessage, { id: 'common.vin' }))),
                React.createElement(AsyncSelect, { id: "".concat(baseId, "_vehicle_select"), isClearable: true, cacheOptions: true, defaultOptions: true, loadOptions: debounce(this.loadOptions(), 500), onChange: function (evt) {
                        _this.setSelectedVehicle(evt);
                    }, noOptionsMessage: function () { return intl.formatMessage('findVehicle.noVehicleOptions'); }, key: this.state.lookupType }))), extraActiveContent: (React.createElement(NewVehicleContainer, null,
                React.createElement(SearchCreateDivider, null, " or "),
                React.createElement(Button, { id: "".concat(baseId, "_newVehicle_button"), success: true, onClick: function () {
                        _this.setConfirmed(true);
                        _this.setSelectedVehicle(null);
                        _this.props.onConfirm({
                            createNew: true,
                            vehicle: null,
                        });
                    } },
                    React.createElement(FormattedMessage, { id: 'findVehicle.newVehicle' })))) }));
    };
    return FindVehicleProcessStep;
}(React.Component));
export default injectIntl(FindVehicleProcessStep);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

var StorageService = /** @class */ (function () {
    function StorageService() {
    }
    StorageService.getPersisted = function (getParam) {
        var key = typeof getParam === 'string' ? getParam : getParam.key;
        return localStorage.getItem(key);
    };
    StorageService.setPersisted = function (_a) {
        var key = _a.key, value = _a.value;
        localStorage.setItem(key, value);
    };
    StorageService.removePersisted = function (removeParam) {
        var key = typeof removeParam === 'string' ? removeParam : removeParam.key;
        localStorage.removeItem(key);
    };
    StorageService.get = function (getParam) {
        var key = typeof getParam === 'string' ? getParam : getParam.key;
        return sessionStorage.getItem(key);
    };
    StorageService.set = function (_a) {
        var key = _a.key, value = _a.value;
        sessionStorage.setItem(key, value);
    };
    StorageService.remove = function (removeParam) {
        var key = typeof removeParam === 'string' ? removeParam : removeParam.key;
        sessionStorage.removeItem(key);
    };
    return StorageService;
}());
export default StorageService;

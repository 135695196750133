import { useEffect } from 'react';
import useIntl from './useIntl';
var IntlGlobalBinder = function () {
    var intl = useIntl();
    useEffect(function () {
        // Log to console when we change locale.
        // eslint-disable-next-line no-console
        console.warn("Set locale to ".concat(intl.locale));
        if (window.ATNM != null) {
            window.ATNM.intl = intl;
        }
    }, [intl.locale]);
    return null;
};
export default IntlGlobalBinder;

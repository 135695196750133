var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'Intl';
import { DeviceService } from 'Services';
import { CompletedStep, ProcessStep } from 'Components.Structure';
import { Input } from 'Components.Form';
var ProcessStepInput = styled(Input)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: .5rem;\n"], ["\n  margin-bottom: .5rem;\n"])));
var FindDeviceProcessStep = function (props) {
    var _a = useState(), hardwareId = _a[0], setHardwareId = _a[1];
    var _b = useState(), serialNumber = _b[0], setSerialNumber = _b[1];
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(ProcessStep, { baseId: props.baseId, confirmed: props.device != null, label: props.label, subtitle: formatMessage('findDevice.description'), loading: props.loading, onEditStep: function () {
            props.onEdit();
        }, onConfirm: function () {
            if (hardwareId == null || serialNumber == null)
                return;
            props.onConfirm({
                hardwareId: hardwareId,
                serialNumber: serialNumber,
            });
        }, confirmDisabled: hardwareId == null || serialNumber == null, confirmedContent: (React.createElement(React.Fragment, null, props.device && (React.createElement(CompletedStep, { title: props.device.hardwareId, subtitle: DeviceService.getModelLabel(props.device.model) })))), activeContent: (React.createElement(React.Fragment, null,
            React.createElement(ProcessStepInput, { id: "".concat(props.baseId, "_hardwareId_input"), type: "text", placeholder: formatMessage('findDevice.dridPlaceholder'), value: hardwareId, onChange: function (evt) {
                    setHardwareId(evt.currentTarget.value.toLowerCase());
                } }),
            React.createElement(ProcessStepInput, { id: "".concat(props.baseId, "_serialNumber_input"), type: "text", placeholder: formatMessage('findDevice.serialNumberPlaceholder'), value: serialNumber, onChange: function (evt) {
                    setSerialNumber(evt.currentTarget.value);
                } }))) }));
};
export default FindDeviceProcessStep;
var templateObject_1;

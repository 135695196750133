var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { MediaMimeType } from 'Types/commissioning';
import styled from 'styled-components';
import { CommissioningApi } from 'Api';
import { Button } from 'Components.Form';
import { Loading, ChannelCompare, PollingProcess, } from 'Components.Structure';
import { injectIntl, FormattedMessage } from 'Intl';
import TimeoutHelper, { DEFAULT_TIMEOUT } from '../../../../utils/TimeoutHelper';
import DeviceModelHelper from '../../../../utils/DeviceModelHelper';
var resolveStateLabel = function (state) {
    switch (state) {
        case 0:
            return 'commandState.0';
        case 1:
            return 'commandState.1';
        case 2:
            return 'commandState.2';
        case 3:
            return 'commandState.3';
        case 4:
            return 'commandState.4';
        case 5:
            return 'commandState.5';
        case 6:
            return 'commandState.6';
        case 7:
            return 'commandState.7';
        case 8:
            return 'commandState.8';
        default:
            return 'common.unknown';
    }
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: column;\n  padding: 1rem;\n"], ["\n  display: flex;\n  flex-flow: column;\n  padding: 1rem;\n"])));
var VideoLookupProcess = /** @class */ (function (_super) {
    __extends(VideoLookupProcess, _super);
    function VideoLookupProcess(props) {
        var _this = _super.call(this, props) || this;
        _this.deviceModelsAlwaysSendingExtraChannels = [
            'V5'
        ];
        _this.commissioningApi = new CommissioningApi();
        _this.handleRequestMediaClick = function () {
            _this.fetchMediaCommand();
        };
        _this.handleTimeout = function () {
            _this.fetchCommand();
        };
        _this.handleCancelCommand = function () {
            var _a = _this.state, timeoutId = _a.timeoutId, command = _a.command;
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            if (command && [0, 1, 2, 3, 4].includes(command.state)) {
                _this.cancelCommand({
                    commandId: command.id,
                    hardwareId: _this.props.device.hardwareId,
                });
            }
        };
        _this.handleRetryCommand = function () {
            var _a = _this.state, timeoutId = _a.timeoutId, defaultTimeout = _a.defaultTimeout;
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            _this.setState({
                phase: 'request',
                timeout: defaultTimeout,
                command: null,
            }, _this.fetchMediaCommand);
        };
        _this.handleRequestNewVideo = function () {
            _this.props.onRepeat();
            _this.fetchMediaCommand();
        };
        _this.state = {
            phase: 'request',
            channels: null,
            commandId: null,
            timeoutId: null,
            timeout: DEFAULT_TIMEOUT,
            defaultTimeout: DEFAULT_TIMEOUT,
            loading: false,
            command: null,
            showExtraChannels: _this.shouldShowExtraChannels(props.device)
        };
        return _this;
    }
    VideoLookupProcess.prototype.componentDidMount = function () {
        this.setDefaultTimeout();
    };
    VideoLookupProcess.prototype.componentWillUnmount = function () {
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
        }
    };
    VideoLookupProcess.prototype.setDefaultTimeout = function () {
        return __awaiter(this, void 0, void 0, function () {
            var defaultTimeout;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, TimeoutHelper.getDefaultTimeout()];
                    case 1:
                        defaultTimeout = _a.sent();
                        this.setState({ defaultTimeout: defaultTimeout, timeout: defaultTimeout });
                        return [2 /*return*/];
                }
            });
        });
    };
    VideoLookupProcess.prototype.createTimeout = function () {
        var timeoutId = window.setTimeout(this.handleTimeout, 5000);
        this.setState({
            timeoutId: timeoutId,
        });
    };
    VideoLookupProcess.prototype.cancelCommand = function (_a) {
        var commandId = _a.commandId, hardwareId = _a.hardwareId;
        return __awaiter(this, void 0, void 0, function () {
            var result, defaultTimeout;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.commissioningApi.cancelCommand({
                            cId: commandId,
                            hardwareId: hardwareId,
                        })];
                    case 1:
                        result = _b.sent();
                        defaultTimeout = this.state.defaultTimeout;
                        if (result.isSuccess) {
                            this.setState({
                                phase: 'request',
                                timeout: defaultTimeout,
                                command: null,
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    VideoLookupProcess.prototype.fetchMediaCommand = function () {
        return __awaiter(this, void 0, void 0, function () {
            var defaultTimeout, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        defaultTimeout = this.state.defaultTimeout;
                        this.setState(function (prevState) { return ({
                            phase: 'polling',
                            command: null,
                            timeout: defaultTimeout,
                            loading: !prevState.command,
                        }); });
                        return [4 /*yield*/, this.commissioningApi.createMediaCommand({
                                deviceId: this.props.device.id,
                                deviceCommandType: 7,
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.isSuccess && result.payload) {
                            this.setState({
                                commandId: result.payload.id,
                            }, this.fetchCommand);
                        }
                        else {
                            this.setState({
                                phase: 'request',
                                loading: false,
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    VideoLookupProcess.prototype.fetchCommand = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, state, newTimeout;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.state.commandId)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.commissioningApi.lookupCommand({
                                cId: this.state.commandId,
                                hardwareId: this.props.device.hardwareId,
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.isSuccess && result.payload) {
                            state = result.payload.state;
                            if (state !== 3) {
                                newTimeout = this.state.timeout - 5;
                                // Command State is not Done
                                this.setState({
                                    command: result.payload,
                                    timeout: newTimeout < 0 ? 0 : newTimeout,
                                    loading: false,
                                });
                                if (newTimeout === 0) {
                                    this.props.onTimeout();
                                }
                                if (newTimeout > 0) {
                                    this.createTimeout();
                                }
                            }
                            else {
                                // Command State is Done
                                this.setState({
                                    phase: 'complete',
                                    command: result.payload,
                                    loading: false,
                                }, this.commandComplete);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    VideoLookupProcess.prototype.commandComplete = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, channels, channelsMatch;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.state.commandId)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.commissioningApi.lookupMedia({
                                cId: this.state.commandId,
                                hardwareId: this.props.device.hardwareId,
                                filterMimeType: MediaMimeType.VideoMp4
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.isSuccess && result.payload) {
                            channels = result.payload.map(function (m) { return m.channel; });
                            channelsMatch = this.doesChannelsMatchForDevice(this.props.requiredChannels, channels, this.props.device.model);
                            this.setState({
                                channels: channels,
                            });
                            if (channelsMatch) {
                                this.props.onChannelsMatch();
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    VideoLookupProcess.prototype.doesChannelsMatchForDevice = function (requiredChannels, receivedChannels, deviceModel) {
        var _a;
        var deviceModelName = (_a = DeviceModelHelper.getDeviceModelList().find(function (m) { return m.value === deviceModel.toString(); })) === null || _a === void 0 ? void 0 : _a.label;
        if (deviceModelName && this.deviceModelsAlwaysSendingExtraChannels.includes(deviceModelName)) {
            return requiredChannels.every(function (c) { return receivedChannels.includes(c); });
        }
        return requiredChannels.every(function (c) { return receivedChannels.includes(c); }) && receivedChannels.every(function (c) { return requiredChannels.includes(c); });
    };
    VideoLookupProcess.prototype.shouldShowExtraChannels = function (device) {
        var _a;
        var deviceModelName = (_a = DeviceModelHelper.getDeviceModelList().find(function (m) { return m.value === device.model.toString(); })) === null || _a === void 0 ? void 0 : _a.label;
        if (deviceModelName) {
            return !(this.deviceModelsAlwaysSendingExtraChannels.includes(deviceModelName));
        }
        return true;
    };
    VideoLookupProcess.prototype.render = function () {
        var _a = this.state, phase = _a.phase, command = _a.command, channels = _a.channels, timeout = _a.timeout, loading = _a.loading, showExtraChannels = _a.showExtraChannels;
        var _b = this.props, baseId = _b.baseId, intl = _b.intl;
        return (React.createElement(Container, null,
            !loading && phase === 'request' && (React.createElement(Button, { id: "".concat(baseId, "_requestVideo_button"), primary: true, onClick: this.handleRequestMediaClick },
                React.createElement(FormattedMessage, { id: 'videoLookupProcess.requestVideoButton' }))),
            !loading && phase === 'polling' && command && (React.createElement(PollingProcess, { baseId: "".concat(baseId, "_polling"), title: intl.formatMessage('videoLookupProcess.pollingProcess.title'), cancelLabel: intl.formatMessage('command.cancelButton'), retryLabel: intl.formatMessage('common.tryAgain'), barColor: "#387dc1", timeoutLabel: timeout > 0
                    ? (intl.formatMessage('common.timeoutLabel', {
                        timeout: timeout,
                    })) : intl.formatMessage('common.commandTimedOut'), status: intl.formatMessage(resolveStateLabel(command.state)), showCancel: [0, 1, 2, 3, 4].includes(command.state) && timeout > 0, showRetry: timeout === 0 || [5, 6].includes(command.state), onCancel: this.handleCancelCommand, onRetry: this.handleRetryCommand, showStatus: timeout > 0 })),
            !loading && phase === 'complete' && command && channels && (React.createElement(React.Fragment, null,
                React.createElement(ChannelCompare, { baseId: "".concat(baseId, "_videoLookupNoChannels"), requiredChannels: this.props.requiredChannels, receivedChannels: channels, missingMessage: function (missingChannels) { return (intl.formatMessage('channelCompare.missingChannelsMessage', {
                        missingChannels: missingChannels.join(', '),
                    })); }, missingTitle: intl.formatMessage('channelCompare.missingChannelsTitle'), matchMessage: intl.formatMessage('channelCompare.allRequiredChannelsFound'), matchTitle: intl.formatMessage('channelCompare.noChannelsMissing') }),
                showExtraChannels && (React.createElement(ChannelCompare, { baseId: "".concat(baseId, "_videoLookupExtraChannels"), requiredChannels: channels, receivedChannels: this.props.requiredChannels, missingMessage: function (missingChannels) { return (intl.formatMessage('channelCompare.extraChannelsMessage', {
                        extraChannels: missingChannels.join(', '),
                    })); }, missingTitle: intl.formatMessage('channelCompare.extraChannelsTitle') })),
                React.createElement(Button, { id: "".concat(baseId, "_requestNewVideo_button"), primaryText: true, onClick: this.handleRequestNewVideo },
                    React.createElement(FormattedMessage, { id: 'videoLookupProcess.requestNewVideoButton' })))),
            loading && (React.createElement(Loading, { barColor: "#000000", includeText: false }))));
    };
    return VideoLookupProcess;
}(React.PureComponent));
export default injectIntl(VideoLookupProcess);
var templateObject_1;

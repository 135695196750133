var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import { ProcessStep, CompletedStep } from 'Components.Structure';
import { CameraTools, ImagePreview } from 'Components.Camera';
import styled from 'styled-components';
import { useIntl } from 'Intl';
var VrnImageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var ConfirmRegistrationProcessStep = function (props) {
    var _a = useState(false), confirmed = _a[0], setConfirmed = _a[1];
    var _b = useState(props.vrnImage || ''), vrnImage = _b[0], setImageUri = _b[1];
    var formatMessage = useIntl().formatMessage;
    var baseId = props.baseId;
    return (React.createElement(ProcessStep, { baseId: baseId, label: props.label, subtitle: formatMessage('confirmRegistration.description'), confirmed: confirmed, confirmDisabled: vrnImage == null || vrnImage === '', activeContent: (React.createElement(CameraTools, { onConfirm: function (x) { return setImageUri(x); } })), confirmedContent: (React.createElement(React.Fragment, null, props.vrnImage != null && (React.createElement(VrnImageContainer, null,
            React.createElement(ImagePreview, { dataUri: props.vrnImage }),
            React.createElement(CompletedStep, { title: formatMessage('confirmRegistration.confirmation'), subtitle: formatMessage('confirmRegistration.confirmationSubtitle') }))))), onConfirm: function () {
            if (vrnImage == null)
                return;
            props.onConfirm(vrnImage);
            setConfirmed(true);
        }, onEditStep: function () {
            props.onEdit();
            setImageUri('');
            setConfirmed(false);
        } }));
};
export default ConfirmRegistrationProcessStep;
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { Label, Button } from 'Components.Form';
export var ProcessStepHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: row;\n"], ["\n  display: flex;\n  flex-flow: row;\n"])));
export var ProcessStepHeaderInfo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  flex-flow: column;\n"], ["\n  flex: 1;\n  display: flex;\n  flex-flow: column;\n"])));
export var ProcessStepLabel = styled(Label)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-bottom: 0;\n"], ["\n  padding-bottom: 0;\n"])));
export var ProcessStepContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: column;\n  background-color: white;\n  padding: 1rem;\n\n  ", "\n\n  & + & {\n    margin-top: .7rem;\n  }\n"], ["\n  display: flex;\n  flex-flow: column;\n  background-color: white;\n  padding: 1rem;\n\n  ", "\n\n  & + & {\n    margin-top: .7rem;\n  }\n"])), function (props) { return props.stepLevel === 1 && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-left: .5rem;\n    margin-right: .5rem;\n    background-color: #fcfcfc;\n  "], ["\n    margin-left: .5rem;\n    margin-right: .5rem;\n    background-color: #fcfcfc;\n  "]))); });
export var ProcessStepSubtitle = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: ", ";\n  margin-bottom: .5rem;\n"], ["\n  font-size: ", ";\n  margin-bottom: .5rem;\n"])), function (props) { return props.theme.type.sizes.small; });
export var ProcessStepEditButton = styled(Button)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: ", ";\n  color: ", ";\n  font-weight: ", ";\n  background-color: transparent;\n  padding: 0;\n"], ["\n  font-size: ", ";\n  color: ", ";\n  font-weight: ", ";\n  background-color: transparent;\n  padding: 0;\n"])), function (props) { return props.theme.type.sizes.small; }, function (props) { return props.theme.colors.red[50]; }, function (props) { return props.theme.type.weight.semibold; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var Title = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n"], ["\n  flex: 1;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n"])));
var SubTitle = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-transform: uppercase;\n  font-weight: ", ";\n  color: ", ";\n  margin: 0;\n  padding: 0;\n  font-size: ", ";\n  letter-spacing: 0.65rem;\n  margin-left: 0.65rem;\n"], ["\n  text-transform: uppercase;\n  font-weight: ", ";\n  color: ", ";\n  margin: 0;\n  padding: 0;\n  font-size: ", ";\n  letter-spacing: 0.65rem;\n  margin-left: 0.65rem;\n"])), function (props) { return props.theme.type.weight.regular; }, function (props) { return props.theme.colors.white; }, function (props) { return props.theme.type.sizes.default; });
var Container = styled.main(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n"], ["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n"])));
export { Title, SubTitle, Container };
var templateObject_1, templateObject_2, templateObject_3;

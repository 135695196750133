export var kilometersToMilesRatio = 0.621371192;
export var milesToKilometersRatio = 1.60934;
export var metersToMiles = function (meters, precise) {
    if (precise === void 0) { precise = false; }
    var value = (meters / 1000) * kilometersToMilesRatio;
    return precise ? +value.toFixed(2) : Math.round(value);
};
export var milesToMeters = function (miles, precise) {
    if (precise === void 0) { precise = false; }
    var value = miles * milesToKilometersRatio * 1000;
    return precise ? +value.toFixed(2) : Math.round(value);
};

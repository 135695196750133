var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styled from 'styled-components';
import { ProcessStep, LabelledValue } from 'Components.Structure';
import { resolveAccessoryTranslation } from 'Utils';
import { injectIntl, FormattedMessage } from 'Intl';
var SectionLabel = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: .75rem;\n  margin: .5rem 0;\n  font-weight: 700;\n"], ["\n  font-size: .75rem;\n  margin: .5rem 0;\n  font-weight: 700;\n"])));
var SectionInformation = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: .5rem .5rem 0;\n  border-left: 2px solid #b4b4b4;\n"], ["\n  padding: .5rem .5rem 0;\n  border-left: 2px solid #b4b4b4;\n"])));
var ConfirmMessage = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 1rem .2rem;\n  color: ", ";\n"], ["\n  margin: 1rem .2rem;\n  color: ", ";\n"])), function (props) { return props.theme.colors.red[50]; });
var CommissionOverviewProcessStep = /** @class */ (function (_super) {
    __extends(CommissionOverviewProcessStep, _super);
    function CommissionOverviewProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.handleConfirm = function () {
            _this.setState({
                confirmed: true,
            });
            _this.props.onConfirm();
        };
        _this.handleEdit = function () {
            _this.setState({
                confirmed: false,
            });
            _this.props.onEdit();
        };
        _this.state = {
            confirmed: false,
        };
        return _this;
    }
    CommissionOverviewProcessStep.prototype.render = function () {
        var _a = this.props, baseId = _a.baseId, intl = _a.intl;
        return (React.createElement(ProcessStep, { baseId: baseId, label: intl.formatMessage('commissionOverview.label'), subtitle: intl.formatMessage('commissionOverview.description'), activeContent: (React.createElement(React.Fragment, null,
                React.createElement(SectionLabel, null,
                    React.createElement(FormattedMessage, { id: 'commissionOverview.deviceInstalled' })),
                React.createElement(SectionInformation, null,
                    React.createElement(LabelledValue, { id: "".concat(baseId, "_deviceId_container"), label: intl.formatMessage('common.deviceId') }, this.props.device.hardwareId)),
                React.createElement(SectionLabel, null,
                    React.createElement(FormattedMessage, { id: 'commissionOverview.assignedOrganisationAndFleet' })),
                React.createElement(SectionInformation, null,
                    React.createElement(LabelledValue, { id: "".concat(baseId, "_organisation_container"), label: intl.formatMessage('common.organisation') }, this.props.organisation.name),
                    React.createElement(LabelledValue, { id: "".concat(baseId, "_fleet_container"), label: intl.formatMessage('common.fleet') }, this.props.fleet.name)),
                React.createElement(SectionLabel, null,
                    React.createElement(FormattedMessage, { id: 'commissionOverview.assignedVehicle' })),
                React.createElement(SectionInformation, null,
                    React.createElement(LabelledValue, { id: "".concat(baseId, "_vrn_container"), label: intl.formatMessage('common.vrn') }, this.props.vehicle.vrn),
                    React.createElement(LabelledValue, { id: "".concat(baseId, "_vin_container"), label: intl.formatMessage('common.vin') }, this.props.vehicle.vin || React.createElement(FormattedMessage, { id: 'common.notAvailable' })),
                    React.createElement(LabelledValue, { id: "".concat(baseId, "_make_container"), label: intl.formatMessage('common.vehicleMake') }, this.props.vehicle.make),
                    React.createElement(LabelledValue, { id: "".concat(baseId, "_model_container"), label: intl.formatMessage('common.vehicleModel') }, this.props.vehicle.model)),
                this.props.customerNotes != null && this.props.customerNotes.length > 0 && (React.createElement(React.Fragment, null,
                    React.createElement(SectionLabel, null,
                        React.createElement(FormattedMessage, { id: 'commissionOverview.additionalInformation' })),
                    React.createElement(SectionInformation, null,
                        React.createElement(LabelledValue, { id: "".concat(baseId, "_notes_container"), label: intl.formatMessage('common.notes') }, this.props.customerNotes)))),
                this.props.accessories.length > 0 && (React.createElement(React.Fragment, null,
                    React.createElement(SectionLabel, null,
                        React.createElement(FormattedMessage, { id: 'commissionOverview.accessoriesInstalled' })),
                    React.createElement(SectionInformation, null, this.props.accessories.map(function (a, i) { return (React.createElement(LabelledValue, { id: "".concat(baseId, "_accessories_container_").concat(i) }, a.catalogueId != null
                        ? React.createElement(FormattedMessage, { id: resolveAccessoryTranslation(a.catalogueId) })
                        : a.name)); })))),
                !this.state.confirmed && this.props.customerPresent && (React.createElement(ConfirmMessage, null,
                    React.createElement(FormattedMessage, { id: 'commissionOverview.customerConfirmationMessage' }))))), confirmed: this.state.confirmed, onConfirm: this.handleConfirm, onEditStep: this.handleEdit, showActiveWhenConfirmed: true }));
    };
    return CommissionOverviewProcessStep;
}(React.Component));
export default injectIntl(CommissionOverviewProcessStep);
var templateObject_1, templateObject_2, templateObject_3;

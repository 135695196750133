var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var ImagePreviewContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var ImagePreview = function (_a) {
    var dataUri = _a.dataUri;
    return (React.createElement(ImagePreviewContainer, null,
        React.createElement("img", { src: dataUri, width: "100%", alt: "" })));
};
export default ImagePreview;
var templateObject_1;

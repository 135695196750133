var isEqual = function (value1, value2) {
    if (typeof value1 !== 'object' || value1 === null || typeof value2 !== 'object' || value2 === null) {
        return value1 === value2;
    }
    var keys1 = Object.keys(value1);
    var keys2 = Object.keys(value2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (var _i = 0, keys1_1 = keys1; _i < keys1_1.length; _i++) {
        var key = keys1_1[_i];
        if (!isEqual(value1[key], value2[key])) {
            return false;
        }
    }
    return true;
};
export default isEqual;

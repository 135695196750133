var initialState = {
    hereMap: {
        vehicleLocations: {
            credentials: null,
            zoom: 15,
            center: null,
        },
    },
};
export default initialState;

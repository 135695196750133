var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styled, { css } from 'styled-components';
import { Check, Cross, Expand, } from 'Icons';
import { HealthCheckType } from 'Types/commissioning';
import { FormattedMessage } from 'Intl';
var DeviceHealthRowGroup = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: row;\n  color: ", ";\n  padding: .5rem 1rem;\n  justify-content: space-between;\n\n  ", "\n\n  ", "\n\n  & + & {\n    margin-top: .5rem;\n  }\n"], ["\n  display: flex;\n  flex-flow: row;\n  color: ", ";\n  padding: .5rem 1rem;\n  justify-content: space-between;\n\n  ", "\n\n  ", "\n\n  & + & {\n    margin-top: .5rem;\n  }\n"])), function (props) { return props.theme.colors.white; }, function (props) { return props.valid && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: ", ";\n  "], ["\n    background-color: ", ";\n  "])), function (themeProps) { return themeProps.theme.colors.green[50]; }); }, function (props) { return !props.valid && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background-color: ", "};\n  "], ["\n    background-color: ", "};\n  "])), function (themeProps) { return themeProps.theme.colors.red[50]; }); });
var DeviceHealthRowLabel = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: .9rem;\n  display: flex;\n  align-items: center;\n"], ["\n  font-size: .9rem;\n  display: flex;\n  align-items: center;\n"])));
var DeviceHealthRowContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: .5rem;\n  font-size: .9rem;\n"], ["\n  margin-top: .5rem;\n  font-size: .9rem;\n"])));
var DeviceHealthDescription = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 0 10px;\n"], ["\n  padding: 0 10px;\n"])));
var DeviceHealthRowExpandIcon = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n  align-items: center;\n"], ["\n  color: ", ";\n  display: flex;\n  align-items: center;\n"])), function (props) { return props.theme.colors.white; });
var resolveLabelTranslations = function (key) {
    switch (key) {
        case HealthCheckType.Firmware:
            return 'common.firmware';
        case HealthCheckType.SdCard:
            return 'confirmDeviceHealth.sd';
        case HealthCheckType.Storage:
            return 'confirmDeviceHealth.storage';
        case HealthCheckType.Location:
            return 'confirmDeviceHealth.locationReceived';
        case HealthCheckType.GPS:
            return 'confirmDeviceHealth.gps';
        case HealthCheckType.TelemetryRaw:
            return 'confirmDeviceHealth.driveData';
        default:
            throw new Error('One or more health check types not accounted for.');
    }
};
var DeviceHealthRow = /** @class */ (function (_super) {
    __extends(DeviceHealthRow, _super);
    function DeviceHealthRow(props) {
        var _this = _super.call(this, props) || this;
        _this.handleExpandClick = function () {
            var _a = _this.props, valid = _a.valid, helpOptions = _a.helpOptions;
            if (valid || !helpOptions)
                return;
            _this.setState(function (prevState) { return ({
                expanded: !prevState.expanded,
            }); });
        };
        _this.state = {
            expanded: false,
        };
        return _this;
    }
    DeviceHealthRow.prototype.render = function () {
        var expanded = this.state.expanded;
        var _a = this.props, valid = _a.valid, labelType = _a.labelType, helpOptions = _a.helpOptions, engineerActions = _a.engineerActions, baseIdWithIteration = _a.baseIdWithIteration;
        if (valid == null)
            return null;
        return (React.createElement(DeviceHealthRowContainer, null,
            React.createElement(DeviceHealthRowGroup, { id: "".concat(baseIdWithIteration.baseId, "_dropdown_").concat(baseIdWithIteration.i), "data-isValid": valid, valid: valid, onClick: this.handleExpandClick },
                React.createElement(DeviceHealthRowLabel, null,
                    valid && (React.createElement(Check, { style: {
                            width: '15px',
                            height: '15px',
                            fill: 'white',
                            marginRight: '.5rem',
                        } })),
                    !valid && (React.createElement(Cross, { style: {
                            width: '15px',
                            height: '15px',
                            fill: 'white',
                            marginRight: '.5rem',
                        } })),
                    React.createElement(FormattedMessage, { id: resolveLabelTranslations(labelType) })),
                !valid && helpOptions && (React.createElement(DeviceHealthRowExpandIcon, null,
                    React.createElement(Expand, { style: {
                            width: '20px',
                            height: '20px',
                            fill: 'white',
                            marginRight: '.2rem',
                            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                        } })))),
            expanded && (React.createElement(React.Fragment, null,
                helpOptions && (React.createElement(DeviceHealthDescription, null,
                    React.createElement("h4", null,
                        React.createElement(FormattedMessage, { id: resolveLabelTranslations(labelType) })),
                    React.createElement("ul", null, helpOptions.map(function (x) { return (React.createElement("li", null, React.createElement(FormattedMessage, { id: x }))); })))),
                engineerActions && (React.createElement(DeviceHealthDescription, null,
                    React.createElement("h4", null,
                        React.createElement(FormattedMessage, { id: 'confirmDeviceHealth.actionsTitle' })),
                    React.createElement("ul", null, engineerActions.map(function (x) { return React.createElement("li", null, React.createElement(FormattedMessage, { id: x })); }))))))));
    };
    return DeviceHealthRow;
}(React.Component));
export default DeviceHealthRow;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

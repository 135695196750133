var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Button, ButtonGroup } from 'Components.Form';
import { LoadingStep, SelfCertifiedStep } from 'Components.Structure';
import { FormattedMessage, useIntl } from 'Intl';
import { ProcessStepContainer, ProcessStepHeader, ProcessStepHeaderInfo, ProcessStepLabel, ProcessStepSubtitle, ProcessStepEditButton, } from './components';
var ActiveContentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: .5rem;\n  display: flex;\n  flex-flow: column;\n"], ["\n  margin-bottom: .5rem;\n  display: flex;\n  flex-flow: column;\n"])));
var ExtraActiveContentContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: .5rem;\n  display: flex;\n  flex-flow: column;\n"], ["\n  margin-bottom: .5rem;\n  display: flex;\n  flex-flow: column;\n"])));
var ProcessStep = function (props) {
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(ProcessStepContainer, { stepLevel: props.stepLevel },
        React.createElement(ProcessStepHeader, null,
            React.createElement(ProcessStepHeaderInfo, null,
                React.createElement(ProcessStepLabel, null, props.label),
                React.createElement(ProcessStepSubtitle, null, props.subtitle)),
            props.confirmed && props.isEditable && (React.createElement(ProcessStepEditButton, { id: props.baseIdWithIteration
                    ? "".concat(props.baseIdWithIteration.baseId, "_edit_button_").concat(props.baseIdWithIteration.i)
                    : "".concat(props.baseId, "_edit_button"), onClick: props.onEditStep }, formatMessage('common.edit'))),
            !props.confirmed && props.actionEnabled && props.action),
        !props.hasSelfCertifed && !props.loading
            && (props.confirmed && !props.showActiveWhenConfirmed) && (props.confirmedContent),
        props.hasSelfCertifed && !props.loading && (React.createElement(SelfCertifiedStep, null)),
        !props.loading && (!props.confirmed || props.showActiveWhenConfirmed) && (React.createElement(ActiveContentContainer, null, props.activeContent)),
        !props.loading && !props.confirmed && !props.showSelfCertify && (React.createElement(Button, { id: props.baseIdWithIteration
                ? "".concat(props.baseIdWithIteration.baseId, "_confirm_button_").concat(props.baseIdWithIteration.i)
                : "".concat(props.baseId, "_confirm_button"), success: true, disabled: props.confirmDisabled, onClick: props.onConfirm }, props.confirmLabel || formatMessage('common.confirm'))),
        !props.loading && !props.confirmed && props.showSelfCertify && (React.createElement(ButtonGroup, null,
            React.createElement(Button, { id: props.baseIdWithIteration
                    ? "".concat(props.baseIdWithIteration.baseId, "_confirm_button_").concat(props.baseIdWithIteration.i)
                    : "".concat(props.baseId, "_confirm_button"), success: true, disabled: props.confirmDisabled, onClick: props.onConfirm }, props.confirmLabel || formatMessage('common.confirm')),
            React.createElement(Button, { id: props.baseIdWithIteration
                    ? "".concat(props.baseIdWithIteration.baseId, "_selfCertify_button_").concat(props.baseIdWithIteration.i)
                    : "".concat(props.baseId, "_selfCertify_button"), primary: true, onClick: props.onSelfCertify },
                React.createElement(FormattedMessage, { id: 'common.selfCertify.button' })))),
        !props.loading && (!props.confirmed || props.showActiveWhenConfirmed) && (React.createElement(ExtraActiveContentContainer, null, props.extraActiveContent)),
        props.loading && React.createElement(LoadingStep, null)));
};
ProcessStep.defaultProps = {
    loading: false,
    isEditable: true,
    stepLevel: null,
    showActiveWhenConfirmed: false,
    confirmLabel: null,
    baseId: null,
    baseIdWithIteration: null,
};
export default ProcessStep;
var StepCondition = function (_a) {
    var condition = _a.condition, children = _a.children;
    if (!condition)
        return null;
    return children;
};
var ActionContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var Action = function (props) { return (React.createElement(ActionContainer, null,
    React.createElement(Button, { primaryText: true, onClick: props.onClick }, props.children))); };
export { StepCondition, Action };
var templateObject_1, templateObject_2, templateObject_3;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { CheckCircle } from 'Components.Structure';
import { Button } from 'Components.Form';
import { FormattedMessage } from 'Intl';
import DeviceModelHelper from '../../../../utils/DeviceModelHelper';
import { ChannelType } from '../../../../data/ChannelType';
var SnapShotContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n"], ["\n  display: flex;\n  width: 100%;\n"])));
var CameraImage = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: calc(100% - 10px);\n  height: auto;\n  margin-bottom: 15px;\n  border: 5px solid ", ";\n\n  ", "\n\n  ", "\n"], ["\n  width: calc(100% - 10px);\n  height: auto;\n  margin-bottom: 15px;\n  border: 5px solid ", ";\n\n  ", "\n\n  ", "\n"])), function (props) { return props.theme.colors.white; }, function (props) { return props.approved && (css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border: 5px solid  ", ";\n  "], ["\n    border: 5px solid  ", ";\n  "])), function (themeProps) { return themeProps.theme.colors.green[50]; })); }, function (props) { return props.extraChannel && (css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    border: 5px solid ", ";\n  "], ["\n    border: 5px solid ", ";\n  "])), function (themeProps) { return themeProps.theme.colors.red[50]; })); });
var CameraConfirm = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: absolute;\n  margin: 1rem;\n\n  ", "\n\n  ", "\n"], ["\n  position: absolute;\n  margin: 1rem;\n\n  ", "\n\n  ", "\n"])), function (props) { return props.extraChannel && (css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: none;\n  "], ["\n    display: none;\n  "])))); }, function (props) { return props.approved === false && (css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    filter: grayscale(100%);\n  "], ["\n    filter: grayscale(100%);\n  "])))); });
var SectionChannelType = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding-bottom: .5rem;\n"], ["\n  padding-bottom: .5rem;\n"])));
var InformationRemovableChannel = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: #f50000;\n  margin-top: .5rem;\n"], ["\n  color: #f50000;\n  margin-top: .5rem;\n"])));
var SnapshotList = function (props) {
    var images = props.images, onApproveSnapshots = props.onApproveSnapshots, onChangeChannelType = props.onChangeChannelType, baseId = props.baseId, deviceModel = props.deviceModel;
    if (!images) {
        return (React.createElement("div", null,
            React.createElement(FormattedMessage, { id: 'snapshotList.noSnapshots' })));
    }
    return (React.createElement("div", null, images.sort(function (a, b) { return a.channel - b.channel; }).map(function (image, i) { return (React.createElement(React.Fragment, null,
        React.createElement(SectionChannelType, null,
            React.createElement(FormattedMessage, { id: 'snapshotList.selectChannelType' }),
            " \u00A0",
            React.createElement(Button, { primary: image.channelType === ChannelType.Fixed, onClick: function () { return onChangeChannelType({ channelType: 1, channel: image.channel }); } },
                React.createElement(FormattedMessage, { id: 'snapshotList.fixed' })),
            !DeviceModelHelper.isChannelsBuiltIn(deviceModel, image.channel) && (React.createElement(Button, { primary: image.channelType === ChannelType.Removable, onClick: function () { return onChangeChannelType({ channelType: 2, channel: image.channel }); } },
                React.createElement(FormattedMessage, { id: 'snapshotList.removable' }))),
            (image.channelType === ChannelType.Removable) && (React.createElement(InformationRemovableChannel, null,
                React.createElement(FormattedMessage, { id: 'snapshotList.removableDescription' })))),
        React.createElement(SnapShotContainer, { id: "".concat(baseId, "_snapShot_div_").concat(i), onClick: function () { return onApproveSnapshots({ approved: !image.approved, extraChannel: image.extraChannel, url: image.uri }); } },
            React.createElement(CameraImage, { key: image.uri, src: image.uri, alt: "".concat(i), approved: image.approved, extraChannel: image.extraChannel }),
            React.createElement(CameraConfirm, { approved: image.approved, extraChannel: image.extraChannel },
                React.createElement(CheckCircle, null))))); })));
};
export default SnapshotList;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;

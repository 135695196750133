var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { ProcessStep } from 'Components.Structure';
var SignatureProcessStep = /** @class */ (function (_super) {
    __extends(SignatureProcessStep, _super);
    function SignatureProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.signatureRef = React.createRef();
        _this.handleConfirm = function () {
            var signatureRef = _this.signatureRef;
            if (signatureRef != null && signatureRef.current != null) {
                _this.setState({
                    confirmed: true,
                });
                if (signatureRef.current != null) {
                    _this.props.onConfirm({
                        signature: signatureRef.current.toDataURL(),
                    });
                }
            }
        };
        _this.handleEdit = function () {
            _this.setState({
                confirmed: false,
            }, _this.props.onEdit);
        };
        _this.handleEnd = function () {
            if (_this.state.disabled) {
                _this.setState({
                    disabled: false,
                });
            }
        };
        _this.state = {
            confirmed: false,
            disabled: true,
        };
        return _this;
    }
    SignatureProcessStep.prototype.render = function () {
        var _a = this.props, label = _a.label, subtitle = _a.subtitle;
        return (React.createElement(ProcessStep, { label: label, subtitle: subtitle, confirmed: this.state.confirmed, confirmDisabled: this.state.disabled, activeContent: (React.createElement(SignatureCanvas, { ref: this.signatureRef, canvasProps: {
                    style: {
                        touchAction: 'none',
                        width: '300px',
                        height: '150px',
                        margin: '0 auto',
                        border: '1px solid #ccc',
                        backgroundColor: '#f2f2f2',
                        borderRadius: '3px',
                    },
                    width: '300px',
                    height: '150px',
                }, onEnd: this.handleEnd })), showActiveWhenConfirmed: true, confirmLabel: "Sign", onConfirm: this.handleConfirm, onEditStep: this.handleEdit }));
    };
    return SignatureProcessStep;
}(React.Component));
export default SignatureProcessStep;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { ProcessStep, CompletedStep } from 'Components.Structure';
import { injectIntl } from 'Intl';
import VideoLookupProcess from './components/VideoLookupProcess';
var ConfirmVideoReceivedProcessStep = /** @class */ (function (_super) {
    __extends(ConfirmVideoReceivedProcessStep, _super);
    function ConfirmVideoReceivedProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.handleChannelsMatch = function () {
            _this.setState({
                channelsMatch: true,
            });
        };
        _this.handleConfirm = function () {
            _this.setState({ confirmed: true });
            _this.props.onConfirm(false);
        };
        _this.handleEdit = function () {
            _this.setState({
                confirmed: false,
            });
            _this.props.onEdit();
        };
        _this.handleVideoLookupRepeat = function () {
            _this.setState({
                channelsMatch: null,
            });
        };
        _this.handleTimeout = function () {
            _this.setState({ commandTimeout: true });
        };
        _this.handleSelfCertify = function () {
            _this.setState({ confirmed: true });
            _this.props.onConfirm(true);
        };
        _this.state = {
            confirmed: false,
            channelsMatch: null,
            commandTimeout: false,
        };
        return _this;
    }
    ConfirmVideoReceivedProcessStep.prototype.render = function () {
        var _a = this.props, baseId = _a.baseId, intl = _a.intl, videoSelfCertifed = _a.videoSelfCertifed, showSelfCertify = _a.showSelfCertify;
        var commandTimeout = this.state.commandTimeout;
        return (React.createElement(ProcessStep, { baseId: baseId, label: this.props.label, subtitle: intl.formatMessage('confirmVideoReceived.description'), confirmed: this.state.confirmed, confirmDisabled: !this.state.channelsMatch, activeContent: (React.createElement(VideoLookupProcess, { baseId: baseId, device: this.props.device, requiredChannels: this.props.channels, onChannelsMatch: this.handleChannelsMatch, onRepeat: this.handleVideoLookupRepeat, onTimeout: this.handleTimeout })), confirmedContent: (React.createElement(CompletedStep, { title: intl.formatMessage('confirmVideoReceived.confirmation'), subtitle: intl.formatMessage('confirmVideoReceived.confirmationDescription') })), onConfirm: this.handleConfirm, onEditStep: this.handleEdit, showSelfCertify: showSelfCertify && commandTimeout, onSelfCertify: this.handleSelfCertify, hasSelfCertifed: videoSelfCertifed }));
    };
    return ConfirmVideoReceivedProcessStep;
}(React.Component));
export default injectIntl(ConfirmVideoReceivedProcessStep);

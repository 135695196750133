var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var HealthCheckTextContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: .5rem;\n  margin-top: .5rem;\n  flex-flow: column;\n"], ["\n  display: flex;\n  margin-bottom: .5rem;\n  margin-top: .5rem;\n  flex-flow: column;\n"])));
var HealthCheckTextLabel = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: .85rem;\n"], ["\n  font-size: .85rem;\n"])));
var HealthCheckTextValue = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: .9rem;\n  color: ", ";\n"], ["\n  font-size: .9rem;\n  color: ", ";\n"])), function (props) { return props.theme.colors.grey[25]; });
var HealthCheckText = function (props) { return (React.createElement(HealthCheckTextContainer, { id: props.id },
    React.createElement(HealthCheckTextLabel, null, props.label),
    React.createElement(HealthCheckTextValue, null, props.children))); };
export default HealthCheckText;
var templateObject_1, templateObject_2, templateObject_3;

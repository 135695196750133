export var resolveAccessoryTypeTranslation = function (accessoryType) {
    switch (accessoryType) {
        case 'ADAS_CAMERA':
            return 'accessoryType.ADAS_CAMERA';
        case 'CAMERAS_EXTERNAL':
            return 'accessoryType.CAMERAS_EXTERNAL';
        case 'CAMERAS_INTERNAL':
            return 'accessoryType.CAMERAS_INTERNAL';
        case 'DSM_CAMERA':
            return 'accessoryType.DSM_CAMERA';
        case 'MONITORS':
            return 'accessoryType.MONITORS';
        case 'PANIC_BUTTONS':
            return 'accessoryType.PANIC_BUTTONS';
        case 'POWER_DELAY_KITS':
            return 'accessoryType.POWER_DELAY_KITS';
        case 'SAFETY_SYSTEMS':
            return 'accessoryType.SAFETY_SYSTEMS';
        case 'DIAC':
            return 'accessoryType.DIAC';
        default:
            return 'common.unknown';
    }
};
export var resolveAccessoryTranslation = function (accessoryType) {
    switch (accessoryType) {
        case 'VT-PANIC':
            return 'accessory.VT-PANIC';
        case 'VT-PANIC-LED':
            return 'accessory.VT-PANIC-LED';
        case 'VT2000PWK':
            return 'accessory.VT2000PWK';
        case 'OBD':
            return 'accessory.OBD';
        case 'ADAS_CAMERA_1':
            return 'accessory.ADAS_CAMERA_1';
        case 'DSM_CAMERA_1':
            return 'accessory.DSM_CAMERA_1';
        case 'VT3000-PANIC':
            return 'accessory.VT3000-PANIC';
        case 'RFID Card Reader':
            return 'accessory.RFID_CARD_READER';
        default:
            return 'common.unknown';
    }
};

import { useIntl as intlUseIntl } from 'react-intl';
import en from '../../locale/en.json';
var useIntl = function () {
    var intl = intlUseIntl();
    var formatMessage = function (id, values) { return (intl.formatMessage({ id: id, defaultMessage: en[id] }, values)); };
    return {
        formatMessage: formatMessage,
        locale: intl.locale,
    };
};
export default useIntl;

import React from 'react';
import { FormattedMessage } from 'Intl';
import Logo from '../../../assets/logo.svg';
import { Title, SubTitle, Container, } from './components';
var ExternalView = function (_a) {
    var children = _a.children;
    return (React.createElement(Container, null,
        React.createElement(Title, null,
            React.createElement(Logo, { fill: "white", width: "70%", style: { marginBottom: '.5rem', maxWidth: '17.5rem' } }),
            React.createElement(SubTitle, null,
                React.createElement(FormattedMessage, { id: 'common.tagLine' }))),
        children));
};
export default ExternalView;

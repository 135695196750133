var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'Intl';
import { Button } from 'Components.Form';
var DialogOverlay = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: fixed;\n    top:0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    background-color: rgba(0, 0, 0, 0.75);\n     z-index: 1000;\n"], ["\n    position: fixed;\n    top:0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    background-color: rgba(0, 0, 0, 0.75);\n     z-index: 1000;\n"])));
var DialogCard = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: absolute;\n    min-width: 300px;\n    width: 80%;\n    max-width: 500px;\n    max-height: 500px;\n    background: #fff;\n    top: 50%;\n    left: 50%;\n    display: flex;\n    flex-direction: column;\n    border-radius: 2px;\n    transform: translate(-50%, -50%);\n"], ["\n    position: absolute;\n    min-width: 300px;\n    width: 80%;\n    max-width: 500px;\n    max-height: 500px;\n    background: #fff;\n    top: 50%;\n    left: 50%;\n    display: flex;\n    flex-direction: column;\n    border-radius: 2px;\n    transform: translate(-50%, -50%);\n"])));
var DialogHeader = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding: 15px;\n    color: ", ";\n    background-color: ", ";\n    font-weight: bold;\n"], ["\n    padding: 15px;\n    color: ", ";\n    background-color: ", ";\n    font-weight: bold;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.red[50];
});
var DialogContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding: 15px;\n    display: flex;\n    align-items: center;\n    flex-grow: 1;\n"], ["\n    padding: 15px;\n    display: flex;\n    align-items: center;\n    flex-grow: 1;\n"])));
var DialogActions = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    padding: 10px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n"], ["\n    padding: 10px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n"])));
var ConfirmationDialog = function (_a) {
    var onDismissDialog = _a.onDismissDialog, onProceed = _a.onProceed;
    return (React.createElement(DialogOverlay, null,
        React.createElement(DialogCard, null,
            React.createElement(DialogHeader, null,
                React.createElement(FormattedMessage, { id: 'confirmationDialog.areYouSure' })),
            React.createElement(DialogContent, null,
                React.createElement(FormattedMessage, { id: 'confirmationDialog.leaveCommission' })),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: onDismissDialog },
                    React.createElement(FormattedMessage, { id: 'confirmationDialog.stayOnPage' })),
                React.createElement(Button, { warning: true, onClick: onProceed },
                    React.createElement(FormattedMessage, { id: 'confirmationDialog.leaveThisPage' }))))));
};
export default ConfirmationDialog;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

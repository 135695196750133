var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styled from 'styled-components';
import { Button } from 'Components.Form';
import { Loading, PollingProcess, ChannelCompare } from 'Components.Structure';
import { CommissioningApi } from 'Api';
import { injectIntl, FormattedMessage } from 'Intl';
import TimeoutHelper, { DEFAULT_TIMEOUT } from '../../../../utils/TimeoutHelper';
import SnapshotList from './SnapshotList';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: column;\n  padding: 1rem;\n"], ["\n  display: flex;\n  flex-flow: column;\n  padding: 1rem;\n"])));
var ChannelSwitchContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n"], ["\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n"])));
var CheckBox = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  width: 1rem;\n  height: 1rem;\n  margin-left: 1rem;\n"], ["\n  position: absolute;\n  width: 1rem;\n  height: 1rem;\n  margin-left: 1rem;\n"])));
var dtegMessage = 'snapshotViewer.dtegMessage';
var dtegAiMessage = 'snapshotViewer.dtegAiMessage';
var streammaxMessage = 'snapshotViewer.streammaxMessage';
var resolveChannelLabel = function (deviceModel) {
    switch (deviceModel) {
        case 2:
            return dtegMessage;
        case 8:
            return dtegMessage;
        case 19:
            return dtegMessage;
        case 16:
            return dtegAiMessage;
        case 4:
            return streammaxMessage;
        case 5:
            return streammaxMessage;
        case 6:
            return streammaxMessage;
        case 7:
            return streammaxMessage;
        case 9:
            return streammaxMessage;
        case 15:
            return streammaxMessage;
        case 18:
            return streammaxMessage;
        default:
            return 'common.unknown';
    }
};
var resolveStateLabel = function (state) {
    switch (state) {
        case 0:
            return 'commandState.0';
        case 1:
            return 'commandState.1';
        case 2:
            return 'commandState.2';
        case 3:
            return 'commandState.3';
        case 4:
            return 'commandState.4';
        case 5:
            return 'commandState.5';
        case 6:
            return 'commandState.6';
        default:
            return 'common.unknown';
    }
};
var showChannelSwitch = function (deviceModel) {
    switch (deviceModel) {
        case 2:
            return true;
        case 8:
            return true;
        case 19:
            return true;
        case 16:
            return true;
        case 4:
            return true;
        case 5:
            return true;
        case 6:
            return true;
        case 7:
            return true;
        case 9:
            return true;
        case 15:
            return true;
        case 18:
            return true;
        default:
            return false;
    }
};
var SnapshotViewer = /** @class */ (function (_super) {
    __extends(SnapshotViewer, _super);
    function SnapshotViewer(props) {
        var _this = _super.call(this, props) || this;
        _this.commissioningApi = new CommissioningApi();
        _this.handleChannelType = function (_a) {
            var channelType = _a.channelType, channel = _a.channel;
            var snapshots = _this.state.snapshots;
            if (snapshots == null)
                return;
            var newSnapshots = snapshots.map(function (item) {
                if (item.channel === channel) {
                    return __assign(__assign({}, item), { channelType: channelType });
                }
                return item;
            });
            _this.setState({ snapshots: newSnapshots });
            _this.props.onSnapshotsLoaded(newSnapshots);
        };
        _this.handleRequestSnapshotsClick = function () {
            _this.fetchSnapshotCommand();
        };
        _this.handleTimeout = function () {
            _this.fetchCommand();
        };
        _this.handleCancelCommand = function () {
            var _a = _this.state, timeoutId = _a.timeoutId, command = _a.command;
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            if (command && [0, 1, 2, 3, 4].includes(command.state)) {
                _this.cancelCommand({
                    commandId: command.id,
                    hardwareId: _this.props.device.hardwareId,
                });
            }
        };
        _this.handleRetryCommand = function () {
            var _a = _this.state, timeoutId = _a.timeoutId, defaultTimeout = _a.defaultTimeout;
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            _this.setState({
                phase: 'request',
                timeout: defaultTimeout,
                command: null,
                snapshots: null,
            }, _this.fetchSnapshotCommand);
        };
        _this.handleRequestNewSnapshots = function () {
            _this.props.onRepeat();
            _this.handleRetryCommand();
        };
        _this.handleApproveSnapshots = function (_a) {
            var approved = _a.approved, extraChannel = _a.extraChannel, url = _a.url;
            var snapshots = _this.state.snapshots;
            if (snapshots == null || extraChannel)
                return;
            var newSnapshots = snapshots.map(function (item) {
                if (item.uri === url) {
                    return __assign(__assign({}, item), { approved: approved });
                }
                return item;
            });
            _this.setState({ snapshots: newSnapshots });
            _this.props.onSnapshotsLoaded(newSnapshots);
        };
        _this.state = {
            phase: 'request',
            commandId: null,
            timeoutId: null,
            command: null,
            timeout: DEFAULT_TIMEOUT,
            defaultTimeout: DEFAULT_TIMEOUT,
            loading: false,
            snapshots: null,
            channels: null,
        };
        return _this;
    }
    SnapshotViewer.prototype.componentDidMount = function () {
        this.setDefaultTimeout();
    };
    SnapshotViewer.prototype.componentWillUnmount = function () {
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
        }
    };
    SnapshotViewer.prototype.setDefaultTimeout = function () {
        return __awaiter(this, void 0, void 0, function () {
            var defaultTimeout;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, TimeoutHelper.getDefaultTimeout()];
                    case 1:
                        defaultTimeout = _a.sent();
                        this.setState({ defaultTimeout: defaultTimeout, timeout: defaultTimeout });
                        return [2 /*return*/];
                }
            });
        });
    };
    SnapshotViewer.prototype.getMedia = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, channels_1, channelsMatch, snapshots;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.state.commandId)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.commissioningApi.lookupMedia({
                                cId: this.state.commandId,
                                hardwareId: this.props.device.hardwareId,
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.isSuccess && result.payload) {
                            channels_1 = result.payload.map(function (m) { return m.channel; });
                            channelsMatch = this.props.requiredChannels
                                .every(function (c) { return channels_1.includes(c); })
                                && channels_1.every(function (c) { return _this.props.requiredChannels.includes(c); });
                            snapshots = result.payload.map(function (snapshot) { return (__assign(__assign({}, snapshot), { approved: false, extraChannel: !_this.props.requiredChannels.includes(snapshot.channel), channelType: 1 })); });
                            this.setState({
                                snapshots: snapshots,
                                channels: channels_1,
                            });
                            if (channelsMatch) {
                                this.props.onChannelsMatch();
                            }
                            this.props.onSnapshotsLoaded(result.payload);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SnapshotViewer.prototype.fetchSnapshotCommand = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState(function (prevState) { return ({
                            phase: 'polling',
                            loading: !prevState.command,
                        }); });
                        return [4 /*yield*/, this.commissioningApi.createMediaCommand({
                                deviceId: this.props.device.id,
                                deviceCommandType: 8,
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.isSuccess && result.payload) {
                            this.setState({
                                commandId: result.payload.id,
                            }, this.fetchCommand);
                        }
                        else {
                            this.setState({
                                phase: 'request',
                                loading: false,
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SnapshotViewer.prototype.cancelCommand = function (_a) {
        var commandId = _a.commandId, hardwareId = _a.hardwareId;
        return __awaiter(this, void 0, void 0, function () {
            var result, defaultTimeout;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.commissioningApi.cancelCommand({
                            cId: commandId,
                            hardwareId: hardwareId,
                        })];
                    case 1:
                        result = _b.sent();
                        defaultTimeout = this.state.defaultTimeout;
                        if (result.isSuccess) {
                            this.setState({
                                phase: 'request',
                                timeout: defaultTimeout,
                                command: null,
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SnapshotViewer.prototype.fetchCommand = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, state, newTimeout;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.state.commandId)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.commissioningApi.lookupCommand({
                                cId: this.state.commandId,
                                hardwareId: this.props.device.hardwareId,
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.isSuccess && result.payload) {
                            state = result.payload.state;
                            if (state !== 3) {
                                newTimeout = this.state.timeout - 5;
                                // Command State is not Done
                                this.setState({
                                    command: result.payload,
                                    timeout: newTimeout < 0 ? 0 : newTimeout,
                                    loading: false,
                                });
                                if (newTimeout === 0) {
                                    this.props.onTimeout();
                                }
                                if (newTimeout > 0) {
                                    this.createTimeout();
                                }
                            }
                            else {
                                // Command State is Done
                                this.setState({
                                    phase: 'complete',
                                    command: result.payload,
                                    loading: false,
                                }, this.getMedia);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SnapshotViewer.prototype.createTimeout = function () {
        var timeoutId = window.setTimeout(this.handleTimeout, 5000);
        this.setState({
            timeoutId: timeoutId,
        });
    };
    SnapshotViewer.prototype.render = function () {
        var _a = this.state, phase = _a.phase, command = _a.command, timeout = _a.timeout, loading = _a.loading, snapshots = _a.snapshots, channels = _a.channels;
        var _b = this.props, requiredChannels = _b.requiredChannels, baseId = _b.baseId, device = _b.device, intl = _b.intl, channelSwitched = _b.channelSwitched;
        var approvedChannels = snapshots != null
            ? snapshots.filter(function (x) { return x.approved; }).map(function (x) { return x.channel; })
            : null;
        return (React.createElement(Container, null,
            !loading && phase === 'request' && (React.createElement(Button, { id: "".concat(baseId, "_requestSnapshots_button"), primary: true, onClick: this.handleRequestSnapshotsClick },
                React.createElement(FormattedMessage, { id: 'snapshotViewer.requestSnapshotsButton' }))),
            !loading && phase === 'polling' && command && (React.createElement(PollingProcess, { baseId: "".concat(baseId, "_polling"), title: intl.formatMessage('pollingProcess.title'), cancelLabel: intl.formatMessage('command.cancelButton'), retryLabel: intl.formatMessage('common.tryAgain'), barColor: "#387dc1", timeoutLabel: timeout > 0
                    ? intl.formatMessage('common.timeoutLabel', {
                        timeout: timeout,
                    })
                    : intl.formatMessage('common.commandTimedOut'), status: intl.formatMessage(resolveStateLabel(command.state)), showCancel: [0, 1, 2, 3, 4].includes(command.state) && timeout > 0, showRetry: timeout === 0 || [5, 6].includes(command.state), onCancel: this.handleCancelCommand, onRetry: this.handleRetryCommand, showStatus: timeout > 0 })),
            !loading && phase === 'complete' && command && channels && snapshots && (React.createElement(React.Fragment, null,
                React.createElement(SnapshotList, { baseId: "".concat(baseId, "_complete"), images: snapshots, onApproveSnapshots: this.handleApproveSnapshots, onChangeChannelType: this.handleChannelType, deviceModel: device.model }),
                React.createElement(ChannelCompare, { baseId: "".concat(baseId, "_snapshotNoChannels"), requiredChannels: requiredChannels, receivedChannels: channels, missingMessage: function (missingChannels) { return (intl.formatMessage('channelCompare.missingChannelsMessage', {
                        missingChannels: missingChannels.map(function (x) { return x + 1; }).join(', '),
                    })); }, missingTitle: intl.formatMessage('channelCompare.missingChannelsTitle'), matchMessage: intl.formatMessage('channelCompare.allRequiredChannelsFound'), matchTitle: intl.formatMessage('channelCompare.noChannelsMissing') }),
                React.createElement(ChannelCompare, { baseId: "".concat(baseId, "_snapshotExtraChannels"), requiredChannels: channels, receivedChannels: requiredChannels, missingMessage: function (missingChannels) { return (intl.formatMessage('channelCompare.extraChannelsMessage', {
                        extraChannels: missingChannels.map(function (x) { return x + 1; }).join(', '),
                    })); }, missingTitle: intl.formatMessage('channelCompare.extraChannelsTitle') }),
                !loading && phase === 'complete' && command && channels && snapshots && showChannelSwitch(device.model) && (React.createElement(React.Fragment, null,
                    React.createElement(ChannelSwitchContainer, null,
                        intl.formatMessage(resolveChannelLabel(device.model)),
                        React.createElement(CheckBox, { type: "checkbox", checked: channelSwitched, onChange: this.props.onChannelLabelSwitch })))),
                React.createElement(Button, { id: "".concat(baseId, "_complete_requestNewSnapshots_button"), disabled: approvedChannels != null
                        && requiredChannels.every(function (c) { return approvedChannels.includes(c); }), primaryText: true, onClick: this.handleRequestNewSnapshots },
                    React.createElement(FormattedMessage, { id: 'snapshotViewer.requestNewSnapshots' })))),
            loading && (React.createElement(Loading, { barColor: "#000000", includeText: false }))));
    };
    return SnapshotViewer;
}(React.Component));
export default injectIntl(SnapshotViewer);
var templateObject_1, templateObject_2, templateObject_3;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styled from 'styled-components';
import { Button } from 'Components.Form';
import { Loading, PollingProcess } from 'Components.Structure';
import { CommissioningApi } from 'Api';
import { injectIntl, FormattedMessage } from 'Intl';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: column;\n  padding: 1rem;\n"], ["\n  display: flex;\n  flex-flow: column;\n  padding: 1rem;\n"])));
var resolveStateLabel = function (state) {
    switch (state) {
        case 0:
            return 'commandState.0';
        case 1:
            return 'commandState.1';
        case 2:
            return 'commandState.2';
        case 3:
            return 'commandState.3';
        case 4:
            return 'commandState.4';
        case 5:
            return 'commandState.5';
        case 6:
            return 'commandState.6';
        default:
            return 'common.unknown';
    }
};
var CalibrateCommandViewer = /** @class */ (function (_super) {
    __extends(CalibrateCommandViewer, _super);
    function CalibrateCommandViewer(props) {
        var _this = _super.call(this, props) || this;
        _this.commissioningApi = new CommissioningApi();
        _this.handleRequestCalibrateClick = function () {
            _this.fetchCalibrateCommand();
        };
        _this.handleTimeout = function () {
            _this.fetchCommand();
        };
        _this.handleCancelCommand = function () {
            var _a = _this.state, timeoutId = _a.timeoutId, command = _a.command;
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            if (command && command.state === 0) {
                _this.cancelCommand({
                    commandId: command.id,
                    hardwareId: _this.props.device.hardwareId,
                });
            }
        };
        _this.handleRetryCommand = function () {
            var timeoutId = _this.state.timeoutId;
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            _this.setState({
                phase: 'request',
                timeout: 120,
                command: null,
            }, _this.fetchCalibrateCommand);
        };
        _this.state = {
            phase: 'request',
            commandId: null,
            timeoutId: null,
            command: null,
            timeout: 120,
            loading: false,
        };
        return _this;
    }
    CalibrateCommandViewer.prototype.componentWillUnmount = function () {
        if (this.state.timeoutId) {
            clearTimeout(this.state.timeoutId);
        }
    };
    CalibrateCommandViewer.prototype.createTimeout = function () {
        var timeoutId = window.setTimeout(this.handleTimeout, 5000);
        this.setState({
            timeoutId: timeoutId,
        });
    };
    CalibrateCommandViewer.prototype.fetchCommand = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, state, newTimeout;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.state.commandId)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.commissioningApi.lookupCommand({
                                cId: this.state.commandId,
                                hardwareId: this.props.device.hardwareId,
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.isSuccess && result.payload) {
                            state = result.payload.state;
                            if (state !== 3) {
                                newTimeout = this.state.timeout - 5;
                                // Command State is not Done
                                this.setState({
                                    command: result.payload,
                                    timeout: newTimeout < 0 ? 0 : newTimeout,
                                    loading: false,
                                });
                                if (newTimeout === 0) {
                                    this.props.onTimeout();
                                }
                                if (newTimeout > 0) {
                                    this.createTimeout();
                                }
                            }
                            else {
                                // Command State is Done
                                this.setState({
                                    phase: 'complete',
                                    command: result.payload,
                                    loading: false,
                                }, this.props.handleComplete);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CalibrateCommandViewer.prototype.cancelCommand = function (_a) {
        var commandId = _a.commandId, hardwareId = _a.hardwareId;
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.commissioningApi.cancelCommand({ cId: commandId, hardwareId: hardwareId })];
                    case 1:
                        result = _b.sent();
                        if (result.isSuccess) {
                            this.setState({
                                phase: 'request',
                                timeout: 120,
                                command: null,
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CalibrateCommandViewer.prototype.fetchCalibrateCommand = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState(function (prevState) { return ({
                            phase: 'polling',
                            loading: !prevState.command,
                        }); });
                        return [4 /*yield*/, this.commissioningApi.CreateCalibrateCommand({
                                deviceId: this.props.device.id.toLowerCase(),
                                deviceCommandType: 10,
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.isSuccess && result.payload) {
                            this.setState({
                                commandId: result.payload.id,
                            }, this.fetchCommand);
                        }
                        else {
                            this.setState({
                                phase: 'request',
                                loading: false,
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CalibrateCommandViewer.prototype.render = function () {
        var _a = this.state, phase = _a.phase, command = _a.command, timeout = _a.timeout, loading = _a.loading;
        var _b = this.props, baseId = _b.baseId, intl = _b.intl;
        return (React.createElement(Container, null,
            !loading && phase === 'request' && (React.createElement(Button, { id: "".concat(baseId, "_sendCalibrateCommand_button"), primary: true, onClick: this.handleRequestCalibrateClick },
                React.createElement(FormattedMessage, { id: 'calibrateCommand.sendCalibrateCommand' }))),
            !loading && phase !== 'request' && command && (React.createElement(PollingProcess, { baseId: "".concat(baseId, "_polling"), title: intl.formatMessage('calibrateCommand.calibrateCommandSent'), cancelLabel: intl.formatMessage('command.cancelButton'), retryLabel: intl.formatMessage('common.tryAgain'), barColor: "#387dc1", timeoutLabel: timeout > 0
                    ? (intl.formatMessage('common.timeoutLabel', {
                        timeout: timeout,
                    })) : intl.formatMessage('common.commandTimedOut'), status: intl.formatMessage(resolveStateLabel(command.state)), showCancel: command.state === 0 && timeout > 0, showRetry: timeout === 0 || [5, 6].includes(command.state), onCancel: this.handleCancelCommand, onRetry: this.handleRetryCommand, showStatus: timeout > 0 })),
            loading && (React.createElement(Loading, { barColor: "#000000", includeText: false }))));
    };
    return CalibrateCommandViewer;
}(React.Component));
export default injectIntl(CalibrateCommandViewer);
var templateObject_1;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { ProcessStep, CompletedStep } from 'Components.Structure';
import { injectIntl } from 'Intl';
import { SnapshotViewer } from './components';
var ConfirmCameraPositionsProcessStep = /** @class */ (function (_super) {
    __extends(ConfirmCameraPositionsProcessStep, _super);
    function ConfirmCameraPositionsProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.handleConfirm = function () {
            var media = _this.state.media;
            if (!media)
                return;
            _this.setState({ confirmed: true });
            _this.props.onConfirm(media, false);
        };
        _this.handleEdit = function () {
            _this.setState({
                confirmed: false,
                snapshotsLoaded: false,
            });
            _this.props.onEdit();
        };
        _this.handleSnapshotsLoaded = function (media) {
            var allApproved = media.filter(function (x) { return x.approved === true; }).length;
            _this.setState({
                snapshotsLoaded: true,
                media: media,
                allApproved: allApproved,
            });
        };
        _this.handleRepeat = function () {
            _this.setState({
                snapshotsLoaded: false,
                media: null,
            });
        };
        _this.handleChannelsMatch = function () {
            _this.setState({
                channelsMatch: true,
            });
        };
        _this.handleTimeout = function () {
            _this.setState({ commandTimeout: true });
        };
        _this.handleSelfCertify = function () {
            _this.setState({ confirmed: true });
            _this.props.onConfirm(null, true);
        };
        _this.state = {
            confirmed: false,
            snapshotsLoaded: false,
            media: null,
            allApproved: 0,
            channelsMatch: null,
            commandTimeout: false,
        };
        return _this;
    }
    ConfirmCameraPositionsProcessStep.prototype.render = function () {
        var _a = this.props, baseId = _a.baseId, channels = _a.channels, intl = _a.intl, snapshotSelfCertifed = _a.snapshotSelfCertifed, showSelfCertify = _a.showSelfCertify;
        var _b = this.state, channelsMatch = _b.channelsMatch, snapshotsLoaded = _b.snapshotsLoaded, allApproved = _b.allApproved, commandTimeout = _b.commandTimeout;
        return (React.createElement(ProcessStep, { baseId: baseId, label: this.props.label, subtitle: intl.formatMessage('confirmCameraPositions.description'), confirmed: this.state.confirmed, confirmDisabled: !channelsMatch || !snapshotsLoaded || allApproved !== channels.length, activeContent: (React.createElement(SnapshotViewer, { baseId: baseId, device: this.props.device, requiredChannels: this.props.channels, onSnapshotsLoaded: this.handleSnapshotsLoaded, onChannelsMatch: this.handleChannelsMatch, onRepeat: this.handleRepeat, onChannelLabelSwitch: this.props.onChannelLabelSwitch, channelSwitched: this.props.channelSwitched, onTimeout: this.handleTimeout })), confirmedContent: (React.createElement(CompletedStep, { title: intl.formatMessage('confirmCameraPositions.confirmation'), subtitle: intl.formatMessage('confirmCameraPositions.confirmationDescription') })), onConfirm: this.handleConfirm, onEditStep: this.handleEdit, showSelfCertify: showSelfCertify && commandTimeout, onSelfCertify: this.handleSelfCertify, hasSelfCertifed: snapshotSelfCertifed }));
    };
    return ConfirmCameraPositionsProcessStep;
}(React.Component));
export default injectIntl(ConfirmCameraPositionsProcessStep);

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'Intl';
import { AuthService } from 'Services';
import { LoginButtonContainer, LoginButton, LoginError, } from './components';
import ExternalView from '../ExternalView';
var LoginView = /** @class */ (function (_super) {
    __extends(LoginView, _super);
    function LoginView(props) {
        var _this = _super.call(this, props) || this;
        _this.handleLoginClick = function () {
            if (window.ATNM == null) {
                throw 'Unable to sign in, window.ATNM not set.';
            }
            window.ATNM.userManager.signinRedirect().catch(function (e) {
                if ('message' in e) {
                    _this.setState({
                        error: e.message,
                    });
                }
            });
        };
        _this.state = {
            error: null,
        };
        return _this;
    }
    LoginView.prototype.render = function () {
        if (AuthService.isLoggedIn()) {
            return React.createElement(Redirect, { to: "/" });
        }
        return (React.createElement(ExternalView, null,
            this.state.error == null && (React.createElement(LoginButtonContainer, null,
                React.createElement(LoginButton, { id: "loginView_login_button", taller: true, onClick: this.handleLoginClick },
                    React.createElement(FormattedMessage, { id: 'common.logIn' })))),
            this.state.error === 'Network Error' && (React.createElement(LoginError, null,
                React.createElement(FormattedMessage, { id: 'login.networkError' }),
                React.createElement("br", null),
                React.createElement(FormattedMessage, { id: 'login.contactSupport' })))));
    };
    return LoginView;
}(React.Component));
export default LoginView;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Loading } from 'Components.Structure';
var LoadingStepContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 2rem;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n"], ["\n  height: 2rem;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n"])));
var LoadingStep = function () { return (React.createElement(LoadingStepContainer, null,
    React.createElement(Loading, { includeText: false, barColor: "#387dc1" }))); };
export default LoadingStep;
var templateObject_1;

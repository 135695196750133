var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Button } from 'Components.Form';
import { FormattedMessage } from 'Intl';
var handleReload = function (closeToast) { return function () {
    window.location.reload();
    closeToast();
}; };
var ReloadButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  color: ", ";\n  background-color: transparent;\n"], ["\n  border: 1px solid ", ";\n  color: ", ";\n  background-color: transparent;\n"])), function (props) { return props.theme.colors.white; }, function (props) { return props.theme.colors.white; });
var ReloadContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: row;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-flow: row;\n  align-items: center;\n"])));
var ReloadText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var ReloadToast = function (_a) {
    var closeToast = _a.closeToast;
    return (React.createElement(ReloadContainer, null,
        React.createElement(ReloadText, null,
            React.createElement(FormattedMessage, { id: 'pwa.newVersionAvailable' })),
        React.createElement(ReloadButton, { onClick: handleReload(closeToast) },
            React.createElement(FormattedMessage, { id: 'pwa.update' }))));
};
var reloadToast = function () {
    toast(ReloadToast, { type: toast.TYPE.ERROR, autoClose: false, closeButton: false });
};
// Temporary to test changes to reload toast.
window.reloadToast = reloadToast;
export default reloadToast;
var templateObject_1, templateObject_2, templateObject_3;

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { default as enDateLocale } from 'date-fns/locale/en-GB';
import cs from '../locale/cs.json';
import da from '../locale/da.json';
import de from '../locale/de.json';
import en from '../locale/en.json';
import enUS from '../locale/en-US.json';
import fr from '../locale/fr.json';
import es from '../locale/es.json';
import it from '../locale/it.json';
import nl from '../locale/nl.json';
import pl from '../locale/pl.json';
import pt from '../locale/pt.json';
import sv from '../locale/sv.json';
var translation = function (lang) {
    var parsedResult = lang.match('([a-z]{2})[-_]*([a-zA-Z]*)');
    if (parsedResult === null)
        return en;
    var locale = parsedResult[0], language = parsedResult[1], culture = parsedResult[2];
    console.log(locale, language, culture);
    import("date-fns/locale/".concat(locale, "/index.js"))
        .then(function (module) {
        registerLocale(locale, module);
        setDefaultLocale(locale);
    })
        .catch(function () {
        import("date-fns/locale/".concat(language, "/index.js"))
            .then(function (module) {
            registerLocale(language, module);
            setDefaultLocale(language);
        })
            .catch(function () {
            registerLocale('en-GB', enDateLocale);
            setDefaultLocale('en-GB');
        });
    });
    switch (language) {
        case 'cs':
            return cs;
        case 'da':
            return da;
        case 'de':
            return de;
        case 'en': {
            switch (culture) {
                case 'US':
                    return enUS;
                default:
                    return en;
            }
        }
        case 'es':
            return es;
        case 'fr':
            return fr;
        case 'it':
            return it;
        case 'nl':
            return nl;
        case 'pl':
            return pl;
        case 'pt':
            return pt;
        case 'sv':
            return sv;
        default:
            return en;
    }
};
export { translation, };

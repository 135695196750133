var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { ProcessStep, CompletedStep, LabelledValue } from 'Components.Structure';
import { Input, Button, ButtonGroup } from 'Components.Form';
import { injectIntl, FormattedMessage } from 'Intl';
import { UtilitiesApi } from 'Api';
var VehicleLookupContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: column;\n  padding: .5rem .5rem 1rem;\n"], ["\n  display: flex;\n  flex-flow: column;\n  padding: .5rem .5rem 1rem;\n"])));
var VrnVinButtonGroup = styled(ButtonGroup)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: .5rem;\n  justify-content: flex-end;\n  > ", " {\n    flex: initial;\n  }\n"], ["\n  margin-bottom: .5rem;\n  justify-content: flex-end;\n  > ", " {\n    flex: initial;\n  }\n"])), Button);
var VrnVinSearchInput = styled(Input)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n  display: block;\n  width: 100%;\n"], ["\n  margin-bottom: 1rem;\n  display: block;\n  width: 100%;\n"])));
var LookupInput = styled(Input)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var FindButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var LookupOptions = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: row;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  flex-flow: row;\n  justify-content: space-between;\n"])));
var VehicleLookupProcessStep = /** @class */ (function (_super) {
    __extends(VehicleLookupProcessStep, _super);
    function VehicleLookupProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.handleChangeLookupType = function (lookupType) { return function () {
            _this.setState({
                lookupType: lookupType,
            });
        }; };
        _this.handleFindVehicle = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, searchTerm, lookupType, intl, result, cantFindVehicleError;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.setState({
                            vehicleLoading: true,
                        });
                        _a = this.state, searchTerm = _a.searchTerm, lookupType = _a.lookupType;
                        intl = this.props.intl;
                        return [4 /*yield*/, this.utilitiesApi.findVehicle(__assign(__assign({}, ((lookupType === 'vrn') ? { vrn: searchTerm } : null)), ((lookupType === 'vin') ? { vin: searchTerm } : null)))];
                    case 1:
                        result = _b.sent();
                        if (result.isSuccess && result.payload) {
                            if (result.payload.vrn || result.payload.vin) {
                                this.setState({
                                    vehicleLoading: false,
                                    vehicle: {
                                        colour: result.payload.colour,
                                        engine: result.payload.engine,
                                        fuel: result.payload.fuel,
                                        make: result.payload.make,
                                        model: result.payload.model,
                                        vrn: result.payload.vrn,
                                        vin: result.payload.vin,
                                    },
                                });
                                return [2 /*return*/];
                            }
                            cantFindVehicleError = intl.formatMessage('vehicleLookup.couldNotFindVehicleError', {
                                searchTerm: this.state.searchTerm,
                            });
                            toast.warn(cantFindVehicleError);
                        }
                        this.setState({
                            vehicleLoading: false,
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        _this.handleSearchTermChange = function (evt) {
            var value = evt.currentTarget.value;
            _this.setState({
                searchTerm: value,
            });
        };
        _this.handleConfirm = function () {
            var vehicle = _this.state.vehicle;
            _this.setState({
                confirmed: true,
            });
            _this.props.onConfirm({ found: vehicle != null, vehicle: vehicle });
        };
        _this.handleEditStep = function () {
            _this.setState({
                confirmed: false,
                vehicle: null,
            });
            _this.props.onEdit();
        };
        _this.handleEnterManually = function () {
            _this.setState({
                confirmed: true,
            });
            _this.props.onConfirm({ found: false, vehicle: null });
        };
        _this.handleChangeInput = function (evt) {
            var _a = evt.currentTarget, value = _a.value, name = _a.name;
            _this.setState(function (prevState) {
                var _a;
                return ({
                    vehicle: __assign(__assign({ colour: null, engine: null, fuel: null, make: null, model: null, vrn: null, vin: null }, prevState.vehicle), (_a = {}, _a[name] = value, _a)),
                });
            });
        };
        _this.state = {
            confirmed: false,
            vehicle: null,
            lookupType: 'vrn',
            vehicleLoading: false,
            searchTerm: '',
        };
        _this.utilitiesApi = new UtilitiesApi();
        return _this;
    }
    VehicleLookupProcessStep.prototype.render = function () {
        var _a = this.props, baseId = _a.baseId, intl = _a.intl;
        return (React.createElement(ProcessStep, { baseId: baseId, label: this.props.label, subtitle: intl.formatMessage('vehicleLookup.description'), stepLevel: 1, loading: this.state.vehicleLoading, confirmed: this.state.confirmed, confirmDisabled: this.state.vehicle == null, activeContent: (React.createElement(VehicleLookupContainer, null,
                !this.state.vehicle && (React.createElement(React.Fragment, null,
                    React.createElement(LookupOptions, null,
                        React.createElement(Button, { id: "".concat(baseId, "_enterManually_button"), primaryText: true, onClick: this.handleEnterManually },
                            React.createElement(FormattedMessage, { id: 'vehicleLookup.manualEntryButton' })),
                        React.createElement(VrnVinButtonGroup, null,
                            React.createElement(Button, { id: "".concat(baseId, "_vrn_button"), onClick: this.handleChangeLookupType('vrn'), primary: this.state.lookupType === 'vrn' },
                                React.createElement(FormattedMessage, { id: 'common.vrn' })),
                            React.createElement(Button, { id: "".concat(baseId, "_vin_button"), onClick: this.handleChangeLookupType('vin'), primary: this.state.lookupType === 'vin' },
                                React.createElement(FormattedMessage, { id: 'common.vin' })))),
                    React.createElement(VrnVinSearchInput, { id: "".concat(baseId, "_vrnVinSearch_input"), value: this.state.searchTerm, placeholder: this.state.lookupType === 'vrn'
                            ? intl.formatMessage('vehicleLookup.searchByVRN')
                            : intl.formatMessage('vehicleLookup.searchByVIN'), alignText: "center", onChange: this.handleSearchTermChange }),
                    React.createElement(FindButton, { id: "".concat(baseId, "_find_button"), disabled: this.state.searchTerm.length < 2, primary: true, onClick: this.handleFindVehicle },
                        React.createElement(FormattedMessage, { id: 'common.find' })))),
                this.state.vehicle && [
                    React.createElement(LabelledValue, { label: intl.formatMessage('common.vrn') },
                        React.createElement(LookupInput, { id: "".concat(baseId, "_vrn_input"), name: "registration", value: this.state.vehicle.vrn || '', onChange: this.handleChangeInput })),
                    React.createElement(LabelledValue, { label: intl.formatMessage('common.vin') },
                        React.createElement(LookupInput, { id: "".concat(baseId, "_vin_input"), name: "vin", value: this.state.vehicle.vin || '', onChange: this.handleChangeInput })),
                    React.createElement(LabelledValue, { label: intl.formatMessage('common.vehicleMake') },
                        React.createElement(LookupInput, { id: "".concat(baseId, "_make_input"), name: "make", value: this.state.vehicle.make || '', onChange: this.handleChangeInput })),
                    React.createElement(LabelledValue, { label: intl.formatMessage('common.vehicleModel') },
                        React.createElement(LookupInput, { id: "".concat(baseId, "_model_input"), name: "model", value: this.state.vehicle.model || '', onChange: this.handleChangeInput })),
                    React.createElement(LabelledValue, { label: intl.formatMessage('common.colour') },
                        React.createElement(LookupInput, { id: "".concat(baseId, "_colour_input"), name: "colour", value: this.state.vehicle.colour || '', onChange: this.handleChangeInput })),
                ])), confirmedContent: (React.createElement(React.Fragment, null,
                this.props.vehicle && this.props.found && (React.createElement(CompletedStep, { title: this.props.vehicle.vrn || '', subtitle: "".concat(this.props.vehicle.make || '-', " ").concat(this.props.vehicle.model || '-') })),
                this.props.found != null && !this.props.found && (React.createElement(CompletedStep, { title: intl.formatMessage('vehicleLookup.manualDetailsConfirmation'), subtitle: intl.formatMessage('vehicleLookup.manualDetailsDescription') })))), onConfirm: this.handleConfirm, onEditStep: this.handleEditStep }));
    };
    return VehicleLookupProcessStep;
}(React.Component));
export default injectIntl(VehicleLookupProcessStep);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

export { vehicleTypes } from './vehicleTypes';
export { CommissionJobType } from './CommissionJobType';
export { Locales } from './Locales';
export { MapTileType } from './MapTileType';
export { MapLayerZIndexType } from './MapLayerZIndexType';
var SelfCertification;
(function (SelfCertification) {
    SelfCertification[SelfCertification["None"] = 0] = "None";
    SelfCertification[SelfCertification["HealthChecks"] = 1] = "HealthChecks";
    SelfCertification[SelfCertification["Snapshot"] = 2] = "Snapshot";
    SelfCertification[SelfCertification["Video"] = 3] = "Video";
    SelfCertification[SelfCertification["DeviceCalibration"] = 4] = "DeviceCalibration";
    SelfCertification[SelfCertification["AccessoryCalibration"] = 5] = "AccessoryCalibration";
})(SelfCertification || (SelfCertification = {}));
export { SelfCertification };

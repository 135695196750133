var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Button } from 'Components.Form';
import ExternalView from '../ExternalView';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex: 1;\n    display: flex;\n    flex-flow: column;\n    align-items: center;\n    color: ", ";\n"], ["\n    flex: 1;\n    display: flex;\n    flex-flow: column;\n    align-items: center;\n    color: ", ";\n"])), function (props) { return props.theme.colors.white; });
var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), function (props) { return props.theme.type.weight.semibold; });
var ErrorMessage = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 1rem;\n  border-radius: .2rem;\n  margin-top: 1rem;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  color: ", ";\n  background-color: ", ";\n"], ["\n  padding: 1rem;\n  border-radius: .2rem;\n  margin-top: 1rem;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  color: ", ";\n  background-color: ", ";\n"])), function (props) { return props.theme.colors.red[50]; }, function (props) { return props.theme.colors.white; });
var QuitButton = styled(Button)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 1rem;\n"], ["\n  margin-top: 1rem;\n"])));
export default (function (props) {
    var handleQuitClick = function () {
        if (window.ATNM == null) {
            throw 'Unable to sign out, window.ATNM not set.';
        }
        window.ATNM.userManager.signoutRedirect();
    };
    return (React.createElement(ExternalView, null,
        React.createElement(Container, null,
            React.createElement(ErrorMessage, null,
                React.createElement(Title, null, "Access Denied"),
                "You do not have permission to access this application.",
                React.createElement(QuitButton, { primary: true, onClick: handleQuitClick }, "Return to Log In")))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Button } from 'Components.Form';
import { Loading } from 'Components.Structure';
import { useIntl } from 'Intl';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: column;\n"], ["\n  display: flex;\n  flex-flow: column;\n"])));
var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: center;\n  color: #7f7f7f;\n  font-size: .8rem;\n  margin: 0 0 1rem 0;\n"], ["\n  text-align: center;\n  color: #7f7f7f;\n  font-size: .8rem;\n  margin: 0 0 1rem 0;\n"])));
var StatusContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: center;\n  color: #404040;\n  margin: .5rem 0;\n"], ["\n  text-align: center;\n  color: #404040;\n  margin: .5rem 0;\n"])));
var TimeoutLabel = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: #f97e7e;\n  font-size: .8rem;\n  margin: 1rem 0 .5rem;\n  text-align: center;\n"], ["\n  color: #f97e7e;\n  font-size: .8rem;\n  margin: 1rem 0 .5rem;\n  text-align: center;\n"])));
var PollingProcess = function (props) {
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(Container, null,
        props.showStatus && (React.createElement(StatusContainer, null,
            React.createElement(Title, null, props.title),
            React.createElement("div", { id: "".concat(props.baseId, "_status_container") }, props.status || formatMessage('pollingProcess.defaultStatus')),
            React.createElement(Loading, { includeText: props.includeText, barColor: props.barColor }))),
        React.createElement(TimeoutLabel, null, props.timeoutLabel),
        props.showCancel && (React.createElement(Button, { id: "".concat(props.baseId, "_cancel_button"), primary: true, onClick: props.onCancel }, props.cancelLabel)),
        props.showRetry && (React.createElement(Button, { id: "".concat(props.baseId, "_retry_button"), primary: true, onClick: props.onRetry }, props.retryLabel))));
};
PollingProcess.defaultProps = {
    status: null,
    includeText: false,
    barColor: '#387dc1',
    cancelLabel: 'Cancel',
    retryLabel: 'Retry',
};
export default PollingProcess;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styled from 'styled-components';
import { Button } from 'Components.Form';
import { CompletedStep, ProcessStep, YesNoProcessStep } from 'Components.Structure';
import { injectIntl } from 'Intl';
import DeviceModelHelper from '../../../utils/DeviceModelHelper';
var ChannelContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 10px 0;\n"], ["\n  margin: 10px 0;\n"])));
var ChannelButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 10px 10px 10px 0;\n  width: 75px;\n  height: 2.5rem;\n  cursor: pointer;\n"], ["\n  margin: 10px 10px 10px 0;\n  width: 75px;\n  height: 2.5rem;\n  cursor: pointer;\n"])));
var ConfirmChannelSelectionProcessStep = /** @class */ (function (_super) {
    __extends(ConfirmChannelSelectionProcessStep, _super);
    function ConfirmChannelSelectionProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.setConfirmed = function (confirmed) {
            _this.setState({
                confirmed: confirmed,
            });
        };
        _this.updateChannel = function (channel) { return function () {
            var selectedChannels = _this.state.selectedChannels;
            var newChannels = selectedChannels;
            if (selectedChannels.includes(channel)) {
                newChannels = newChannels.filter(function (x) { return x !== channel; });
            }
            else {
                newChannels.push(channel);
            }
            _this.setState({
                selectedChannels: newChannels,
            });
        }; };
        _this.state = {
            maxChannels: [],
            selectedChannels: [],
            confirmed: false,
        };
        return _this;
    }
    ConfirmChannelSelectionProcessStep.prototype.componentDidMount = function () {
        this.setChannels();
    };
    ConfirmChannelSelectionProcessStep.prototype.setChannels = function () {
        var _a = this.props, channels = _a.channels, maxDeviceChannels = _a.maxDeviceChannels;
        var deviceChannels = maxDeviceChannels == null ? 0 : maxDeviceChannels;
        var maxChannels = [];
        for (var i = 0; i < deviceChannels; i += 1) {
            maxChannels.push(i);
        }
        this.setState({
            maxChannels: maxChannels,
            selectedChannels: channels != null ? channels : maxChannels,
        });
    };
    ConfirmChannelSelectionProcessStep.prototype.render = function () {
        var _this = this;
        var _a = this.state, confirmed = _a.confirmed, maxChannels = _a.maxChannels, selectedChannels = _a.selectedChannels;
        var _b = this.props, device = _b.device, label = _b.label, channels = _b.channels, baseId = _b.baseId, intl = _b.intl;
        if (device.model === 2 || device.model === 8) {
            return (React.createElement(YesNoProcessStep, { label: label, subtitle: intl.formatMessage('confirmChannelSelection.secondCameraDescription'), completedYesSubtitle: intl.formatMessage('confirmChannelSelection.secondCameraYesConfirmation'), completedNoSubtitle: intl.formatMessage('confirmChannelSelection.secondCameraNoConfirmation'), onConfirm: function (selected) {
                    var chnl = selected ? [0, 1] : [0];
                    _this.props.onConfirm(chnl);
                }, onEdit: function () {
                    _this.props.onEdit();
                }, baseId: baseId }));
        }
        return (React.createElement(ProcessStep, { baseId: baseId, confirmed: confirmed, label: label, subtitle: intl.formatMessage('confirmChannelSelection.multipleCameraDescription'), onEditStep: function () {
                _this.props.onEdit();
                _this.setConfirmed(!confirmed);
            }, onConfirm: function () {
                if (selectedChannels == null)
                    return;
                _this.setConfirmed(!confirmed);
                _this.props.onConfirm(selectedChannels);
            }, confirmLabel: intl.formatMessage('confirmChannelSelection.confirmChannels', {
                selectedChannelsTotal: selectedChannels.length,
            }), confirmDisabled: selectedChannels == null || selectedChannels.length === 0, confirmedContent: (React.createElement(React.Fragment, null, channels != null && (React.createElement(CompletedStep, { title: channels.map(function (x) { return x + 1; }).sort(function (a, b) { return a - b; }).join(', '), subtitle: intl.formatMessage('confirmChannelSelection.channelsConfirmation') })))), activeContent: (React.createElement(ChannelContainer, null, maxChannels.map(function (channel, i) { return (React.createElement(ChannelButton, { id: "".concat(baseId, "_channel_button_").concat(i), primary: selectedChannels.includes(channel), onClick: _this.updateChannel(channel), disabled: DeviceModelHelper.isChannelsBuiltIn(device.model, channel) }, channel + 1)); }))) }));
    };
    return ConfirmChannelSelectionProcessStep;
}(React.Component));
export default injectIntl(ConfirmChannelSelectionProcessStep);
var templateObject_1, templateObject_2;

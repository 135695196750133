var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { CompletedStep, ProcessStep } from 'Components.Structure';
import { AsyncSelect } from 'Components.Form';
import { debounce } from 'Utils';
import { CommissioningApi } from 'Api';
import { injectIntl } from 'Intl';
import { AuthService } from 'Services';
var OrganisationLookupProcessStep = /** @class */ (function (_super) {
    __extends(OrganisationLookupProcessStep, _super);
    function OrganisationLookupProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.commissioningApi = new CommissioningApi();
        _this.loadOptions = function () { return function (inputValue, callback) {
            if (inputValue == null || inputValue === '')
                return;
            _this.commissioningApi.lookupOrganisations({
                s: inputValue,
            }).then(function (result) {
                if (result.isSuccess && result.payload) {
                    if (result.payload.organisation != null) {
                        callback([{
                                label: result.payload.organisation.name,
                                value: result.payload.organisation.id,
                                fleet: result.payload.fleet,
                            }]);
                    }
                    else if (result.payload.organisations != null) {
                        callback(result.payload.organisations.map(function (r) { return ({
                            label: r.name,
                            value: r.id,
                            fleet: null,
                        }); }));
                    }
                    else {
                        callback([]);
                    }
                }
            });
        }; };
        _this.state = {
            selectedOrganisation: null,
            confirmed: false,
        };
        return _this;
    }
    OrganisationLookupProcessStep.prototype.componentDidMount = function () {
        var user = AuthService.getAccessTokenDetail();
        if ((user != null && user.organisation_id === '622f6533-00a2-473b-8fe1-8131065054cf') || !this.props.isNewVehicleCreated) {
            this.setConfirmed(true);
        }
    };
    OrganisationLookupProcessStep.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.isNewVehicleCreated !== this.props.isNewVehicleCreated) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setConfirmed(!this.props.isNewVehicleCreated);
        }
    };
    OrganisationLookupProcessStep.prototype.setSelectedOrganisation = function (organisationType) {
        this.setState({
            selectedOrganisation: organisationType,
        });
    };
    OrganisationLookupProcessStep.prototype.setConfirmed = function (confirmed) {
        this.setState({
            confirmed: confirmed,
        });
    };
    OrganisationLookupProcessStep.prototype.renderOrganisationSelect = function () {
        var _this = this;
        var _a = this.props, baseId = _a.baseId, readonly = _a.readonly, organisation = _a.organisation, intl = _a.intl;
        if (readonly && organisation) {
            return (React.createElement(CompletedStep, { title: organisation ? organisation.name : '' }));
        }
        return (React.createElement(AsyncSelect, { id: "".concat(baseId, "_organisation_select"), placeholder: intl.formatMessage('organisationLookup.selectPlaceholder'), isClearable: true, cacheOptions: true, defaultOptions: true, loadOptions: debounce(this.loadOptions(), 500), onChange: function (evt) {
                _this.setSelectedOrganisation(evt);
            }, noOptionsMessage: function () { return intl.formatMessage('organisationLookup.noOrganisations'); } }));
    };
    OrganisationLookupProcessStep.prototype.render = function () {
        var _this = this;
        var _a = this.state, confirmed = _a.confirmed, selectedOrganisation = _a.selectedOrganisation;
        var _b = this.props, label = _b.label, organisation = _b.organisation, baseId = _b.baseId, readonly = _b.readonly, intl = _b.intl, isNewVehicleCreated = _b.isNewVehicleCreated;
        var user = AuthService.getAccessTokenDetail();
        return (React.createElement(ProcessStep, { baseId: baseId, confirmed: confirmed, label: label, isEditable: isNewVehicleCreated && (user == null || user.organisation_id !== '622f6533-00a2-473b-8fe1-8131065054cf'), subtitle: readonly
                ? intl.formatMessage('organisationLookup.confirmDescription')
                : intl.formatMessage('organisationLookup.findDescription'), confirmDisabled: !readonly && selectedOrganisation == null, onEditStep: function () {
                _this.props.onEdit();
                _this.setConfirmed(!confirmed);
                _this.setSelectedOrganisation(null);
            }, onConfirm: function () {
                if (!readonly && selectedOrganisation == null)
                    return;
                _this.setConfirmed(!confirmed);
                _this.props.onConfirm(selectedOrganisation);
            }, confirmedContent: (React.createElement(React.Fragment, null, (organisation != null) && (React.createElement(CompletedStep, { title: organisation ? organisation.name : '' })))), activeContent: this.renderOrganisationSelect() }));
    };
    return OrganisationLookupProcessStep;
}(React.Component));
export default injectIntl(OrganisationLookupProcessStep);

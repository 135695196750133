var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Loading } from 'Components.Structure';
import { Button } from 'Components.Form';
import { Callback, } from './components';
var Error = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: ", ";\n  color: ", ";\n  font-size: ", ";\n  flex: 1;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  width: 100%;\n"], ["\n  font-weight: ", ";\n  color: ", ";\n  font-size: ", ";\n  flex: 1;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  width: 100%;\n"])), function (props) { return props.theme.type.weight.regular; }, function (props) { return props.theme.colors.white; }, function (props) { return props.theme.type.sizes.default; });
var LoadingWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n  color: ", ";\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  width: 100%;\n"], ["\n  flex: 1;\n  color: ", ";\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  width: 100%;\n"])), function (props) { return props.theme.colors.white; });
var ReloadButton = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 20px;\n"], ["\n  margin-top: 20px;\n"])));
var CallbackView = /** @class */ (function (_super) {
    __extends(CallbackView, _super);
    function CallbackView(props) {
        var _this = _super.call(this, props) || this;
        _this.handleReload = function () {
            var atnm = window.ATNM;
            if (atnm == null) {
                throw 'Unable to sign in, window.ATNM not set.';
            }
            var userManager = atnm.userManager;
            userManager.getUser().then(function (u) {
                if (u == null) {
                    _this.props.history.push('/');
                }
                else {
                    userManager.signoutRedirect();
                }
            });
        };
        _this.state = {
            isError: false
        };
        if (window.ATNM == null) {
            throw 'Unable to sign in, window.ATNM not set.';
        }
        window.ATNM.userManager.signinRedirectCallback().then(function () {
            props.history.push('/');
        }).catch(function (e) {
            toast.error('An error has occurred');
            _this.setState({ isError: true });
            console.error(e);
        });
        return _this;
    }
    CallbackView.prototype.render = function () {
        var isError = this.state.isError;
        return (React.createElement(Callback, null,
            !isError && (React.createElement(LoadingWrapper, null,
                React.createElement(Loading, null))),
            isError && (React.createElement(Error, null,
                "Unable to log you in, please try refreshing the page.",
                React.createElement("br", null),
                "If the problem persists please contact support.",
                React.createElement(ReloadButton, { onClick: this.handleReload }, "Reload")))));
    };
    return CallbackView;
}(React.Component));
export default withRouter(CallbackView);
var templateObject_1, templateObject_2, templateObject_3;

var VT2000 = 1;
var VT22 = 2;
var VT4000 = 3;
var VT5500 = 4;
var VT5500G = 5;
var VT8000 = 6;
var VT16000 = 7;
var VT3000 = 8;
var VT62 = 9;
var VT1510 = 10;
var VT1511 = 11;
var VT1520 = 12;
var VT1521 = 13;
var VT10 = 14;
var VT4500 = 15;
var VT3000AI = 16;
var VT3500 = 17;
var VT5500C = 18;
var VT2500 = 19;
var VT60AI = 20;
var VT3600 = 24;
var VT3600AI = 25;
var VT63 = 26;
var V5 = 27;
var HEROME40 = 28;
var HEROME41 = 29;
var BasicSupportDTEG = 30;
var BasicSupportStreamax = 31;
var BasicSupportYuwei = 32;
var BasicSupportHowen = 33;
var BasicSupportDahua = 34;
var VTM1 = 35;
var VTM1N20 = 36;
var DeviceService = /** @class */ (function () {
    function DeviceService() {
    }
    DeviceService.requireAccelerometerCalibrationCommand = function (deviceModel) {
        switch (deviceModel) {
            case VT2000:
            case VT2500:
            case VT22:
                return true;
            default:
                return false;
        }
    };
    DeviceService.requirePanicCheck = function (deviceModel) {
        switch (deviceModel) {
            case VT2000:
            case VT22:
            case VT4000:
            case VT4500:
            case VT2500:
                return true;
            default:
                return false;
        }
    };
    DeviceService.requireSnapshots = function (deviceModel) {
        switch (deviceModel) {
            case VT2000:
            case VT22:
            case VT4000:
            case VT2500:
                return true;
            default:
                return false;
        }
    };
    DeviceService.getModelLabel = function (deviceModel) {
        switch (deviceModel) {
            case VT2000:
                return 'VT2000';
            case VT22:
                return 'VT2.2';
            case VT4000:
                return 'VT4000';
            case VT5500:
                return 'VT5500';
            case VT5500G:
                return 'VT5500G';
            case VT8000:
                return 'VT8000';
            case VT16000:
                return 'VT16000';
            case VT3000:
                return 'VT3000';
            case VT62:
                return 'VT6.2';
            case VT63:
                return 'VT6.3';
            case VT1510:
                return 'VT1510';
            case VT1511:
                return 'VT1511';
            case VT1520:
                return 'VT1520';
            case VT1521:
                return 'VT1521';
            case VT10:
                return 'VT10';
            case VT4500:
                return 'VT4500';
            case VT3000AI:
                return 'VT3000-AI';
            case VT2500:
                return 'VT2500';
            case VT3500:
                return 'VT3500';
            case VT3600:
                return 'VT3600';
            case VT3600AI:
                return 'VT3600-AI';
            case VT5500C:
                return 'VT5500-C';
            case VT60AI:
                return 'VT6.0-AI';
            case V5:
                return 'V5';
            case HEROME40:
                return 'HEROME40';
            case HEROME41:
                return 'HEROME41';
            case BasicSupportDTEG:
                return 'BasicSupportDTEG';
            case BasicSupportStreamax:
                return 'BasicSupportStreamax';
            case BasicSupportYuwei:
                return 'BasicSupportYuwei';
            case BasicSupportHowen:
                return 'BasicSupportHowen';
            case BasicSupportDahua:
                return 'BasicSupportDahua';
            case VTM1:
                return 'VTM1';
            case VTM1N20:
                return 'VTM1N20';
            default:
                return 'Unknown';
        }
    };
    return DeviceService;
}());
export default DeviceService;

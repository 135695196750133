var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { ProcessStep, CompletedStep } from 'Components.Structure';
import { Select } from 'Components.Form';
import { toast } from 'react-toastify';
var JobTypeProcessStep = /** @class */ (function (_super) {
    __extends(JobTypeProcessStep, _super);
    function JobTypeProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.handleChange = function (option) {
            _this.setState({
                selectedJobType: option,
            });
        };
        _this.handleConfirm = function () {
            if (!_this.state.selectedJobType) {
                toast.error('Please select a Job Type first');
                return;
            }
            _this.setState({
                confirmed: true,
            });
            _this.props.onConfirm(_this.state.selectedJobType.value);
        };
        _this.handleEditStep = function () {
            _this.setState({
                confirmed: false,
            });
            _this.props.onEdit();
        };
        _this.state = {
            confirmed: false,
            selectedJobType: null,
        };
        return _this;
    }
    JobTypeProcessStep.prototype.render = function () {
        var _a = this.props, label = _a.label, baseId = _a.baseId, subtitle = _a.subtitle, placeholder = _a.placeholder, confirmedTitle = _a.confirmedTitle, jobTypeOptions = _a.jobTypeOptions;
        var _b = this.state, confirmed = _b.confirmed, selectedJobType = _b.selectedJobType;
        return (React.createElement(ProcessStep, { baseId: baseId, label: label, subtitle: subtitle, confirmed: confirmed, confirmDisabled: !selectedJobType, activeContent: React.createElement(Select, { id: "".concat(baseId, "_jobTypeSelect"), options: jobTypeOptions, placeholder: placeholder, value: selectedJobType, onChange: this.handleChange }), onConfirm: this.handleConfirm, onEditStep: this.handleEditStep, confirmedContent: (React.createElement(CompletedStep, { title: confirmedTitle })) }));
    };
    return JobTypeProcessStep;
}(React.PureComponent));
export default JobTypeProcessStep;

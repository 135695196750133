var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { UploadImageProcessStep } from 'Components.Structure';
var removeAfterKey = function (key, imageRequirements) { return (function (item) {
    var removeIndex = imageRequirements.findIndex(function (ir) { return ir.key === key; });
    var itemIndex = imageRequirements.findIndex(function (ir) { return ir.key === item.key; });
    return removeIndex > itemIndex;
}); };
var UploadImageListProcessStep = /** @class */ (function (_super) {
    __extends(UploadImageListProcessStep, _super);
    function UploadImageListProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.setStateCallback = function () {
            if (_this.props.container == null)
                return;
            _this.props.container.scrollTo(0, 1000000);
        };
        _this.handleEdit = function (key, index) { return function () {
            _this.setState(function (prevState) { return ({
                currentImage: index,
                images: prevState.images.filter(removeAfterKey(key, _this.props.imageRequirements)),
            }); });
        }; };
        _this.handleConfirm = function (key, type) { return function (imageUris) {
            _this.setState(function (prevState) { return ({
                images: __spreadArray(__spreadArray([], prevState.images, true), [
                    { key: key, arr: imageUris.map(function (img) {
                            var _a;
                            return (_a = {}, _a[type] = img, _a);
                        }) },
                ], false),
                currentImage: prevState.currentImage === _this.props.imageRequirements.length
                    ? prevState.currentImage
                    : prevState.currentImage + 1,
            }); }, function () {
                var _a = _this.state, currentImage = _a.currentImage, images = _a.images;
                if (currentImage === _this.props.imageRequirements.length) {
                    _this.props.onConfirm(images.reduce(function (prev, current) { return __spreadArray(__spreadArray([], prev, true), current.arr, true); }, []));
                }
                _this.setStateCallback();
            });
        }; };
        _this.state = {
            currentImage: 0,
            images: [],
        };
        return _this;
    }
    UploadImageListProcessStep.prototype.render = function () {
        var _this = this;
        var _a = this.props, imageRequirements = _a.imageRequirements, startStep = _a.startStep, onUpload = _a.onUpload;
        var currentImage = this.state.currentImage;
        return (React.createElement(React.Fragment, null, imageRequirements.map(function (ir, index) {
            if (index > currentImage)
                return null;
            return (React.createElement(UploadImageProcessStep, { key: ir.key, label: "Step ".concat((startStep || 0) + index, "."), subtitle: (ir.many
                    ? React.createElement("span", null,
                        "Upload ",
                        React.createElement("b", null, ir.label),
                        " images.")
                    : React.createElement("span", null,
                        "Upload an image of the ",
                        React.createElement("b", null, ir.label),
                        ".")), multiple: ir.many, optional: ir.optional, onUpload: onUpload, onConfirm: _this.handleConfirm(ir.key, ir.type), onEdit: _this.handleEdit(ir.key, index) }));
        })));
    };
    UploadImageListProcessStep.defaultProps = {
        startStep: 1,
    };
    return UploadImageListProcessStep;
}(React.Component));
export default UploadImageListProcessStep;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { ProcessStep, CompletedStep } from 'Components.Structure';
import { Input, Button, ButtonGroup } from 'Components.Form';
import { OdometerUnit } from 'Types/commissioning';
import { useIntl, FormattedMessage } from 'Intl';
var UnitButtonGroup = styled(ButtonGroup)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: .5rem;\n  justify-content: flex-end;\n  > ", " {\n    flex: initial;\n  }\n"], ["\n  margin-bottom: .5rem;\n  justify-content: flex-end;\n  > ", " {\n    flex: initial;\n  }\n"])), Button);
var ConfirmOdometerProcessStep = function (props) {
    var _a = useState(false), confirmed = _a[0], setConfirmed = _a[1];
    var _b = useState(props.vehicle.odometerReading || 0), odometerReading = _b[0], setOdometerReading = _b[1];
    var _c = useState(props.vehicle.odometerUnit || OdometerUnit.Miles), odometerUnit = _c[0], setOdometerUnit = _c[1];
    var formatMessage = useIntl().formatMessage;
    var baseId = props.baseId;
    return (React.createElement(ProcessStep, { baseId: baseId, label: props.label, subtitle: formatMessage('confirmOdometer.description'), confirmed: confirmed, confirmDisabled: odometerReading == null, activeContent: (React.createElement(React.Fragment, null,
            React.createElement(UnitButtonGroup, null,
                React.createElement(Button, { id: "".concat(baseId, "_miles_button"), onClick: function () {
                        setOdometerUnit(OdometerUnit.Miles);
                    }, primary: odometerUnit === OdometerUnit.Miles },
                    React.createElement(FormattedMessage, { id: 'common.miles' })),
                React.createElement(Button, { id: "".concat(baseId, "_kilometers_button"), onClick: function () {
                        setOdometerUnit(OdometerUnit.Kilometres);
                    }, primary: odometerUnit === OdometerUnit.Kilometres },
                    React.createElement(FormattedMessage, { id: 'common.kilometres' }))),
            React.createElement(Input, { id: "".concat(baseId, "_odometerReading_input"), alignText: "center", value: odometerReading, min: 0, max: 100000000, onChange: function (evt) {
                    var nextOdometerReading = parseInt(evt.currentTarget.value, 10);
                    if (Number.isNaN(nextOdometerReading))
                        return;
                    if (nextOdometerReading > 100000000)
                        return;
                    setOdometerReading(nextOdometerReading);
                }, type: "number" }))), confirmedContent: (React.createElement(React.Fragment, null, props.odometerReading != null && (React.createElement(CompletedStep, { title: props.odometerReading, subtitle: formatMessage('confirmOdometer.confirmation') })))), onConfirm: function () {
            if (odometerReading == null)
                return;
            props.onConfirm(odometerReading, odometerUnit);
            setConfirmed(true);
        }, onEditStep: function () {
            props.onEdit();
            setConfirmed(false);
        } }));
};
export default ConfirmOdometerProcessStep;
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styled from 'styled-components';
import { ProcessStep, CompletedStep, Loading } from 'Components.Structure';
import { Button, ButtonGroup, Select } from 'Components.Form';
import { injectIntl, FormattedMessage } from 'Intl';
import { resolveAccessoryTypeTranslation, resolveAccessoryTranslation } from 'Utils';
import { CommissioningApi } from 'Api';
var SelectWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: .5rem;\n"], ["\n  margin-top: .5rem;\n"])));
var ConfirmAccessoryProcessStep = /** @class */ (function (_super) {
    __extends(ConfirmAccessoryProcessStep, _super);
    function ConfirmAccessoryProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.commissioningApi = new CommissioningApi();
        _this.assignDeviceAccessory = function () {
            var device = _this.props.device;
            if (device.accessories != null) {
                var accessory = device.accessories.find(function (x) { return x.typeKey === _this.props.accessoryType.key; });
                _this.setState({
                    include: accessory != null ? true : null,
                }, _this.fetchAccessories(accessory != null ? accessory.id : null));
            }
        };
        _this.fetchAccessories = function (accessoryId) { return function () { return __awaiter(_this, void 0, void 0, function () {
            var intl, result, accessories;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        intl = this.props.intl;
                        if (!this.state.include)
                            return [2 /*return*/];
                        this.setState({
                            loading: true,
                        });
                        return [4 /*yield*/, this.commissioningApi.LookupAccessories({
                                mod: this.props.device.model,
                                at: this.props.accessoryType.id,
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.isSuccess) {
                            accessories = result.payload.map(function (a) { return ({
                                label: intl.formatMessage(resolveAccessoryTranslation(a.catalogueId || '')),
                                value: a.id,
                                catalogueId: a.catalogueId,
                            }); });
                            this.setState({
                                accessories: accessories,
                                loading: false,
                            }, this.setSelectedAccessory(accessoryId));
                        }
                        return [2 /*return*/];
                }
            });
        }); }; };
        _this.setSelectedAccessory = function (accessoryId) { return function () {
            var accessories = _this.state.accessories;
            if (accessoryId == null || accessories == null)
                return;
            var selectedAccessory = accessories.find(function (x) { return x.value === accessoryId; });
            if (selectedAccessory == null)
                return;
            _this.setState({
                selectedAccessory: selectedAccessory,
            });
        }; };
        _this.handleAccessoryChange = function (option) {
            _this.setState({
                selectedAccessory: option,
            });
        };
        _this.handleIncludeButtonClick = function (include) { return function () {
            _this.setState({
                include: include,
                selectedAccessory: null,
            }, _this.fetchAccessories(null));
        }; };
        _this.handleConfirm = function () {
            var _a = _this.state, include = _a.include, selectedAccessory = _a.selectedAccessory;
            if (include == null || (include && selectedAccessory == null))
                return;
            _this.setState({
                confirmed: true,
            });
            _this.props.onConfirm({
                include: include,
                selectedAccessory: selectedAccessory,
                accessoryType: _this.props.accessoryType,
            });
        };
        _this.handleEdit = function () {
            _this.setState({
                confirmed: false,
            });
            _this.props.onEdit();
        };
        _this.state = {
            include: null,
            accessories: null,
            loading: false,
            confirmed: false,
            selectedAccessory: null,
        };
        return _this;
    }
    ConfirmAccessoryProcessStep.prototype.componentDidMount = function () {
        this.assignDeviceAccessory();
    };
    ConfirmAccessoryProcessStep.prototype.render = function () {
        var _a = this.props, accessoryType = _a.accessoryType, label = _a.label, _b = _a.baseIdWithIteration, baseId = _b.baseId, i = _b.i, intl = _a.intl;
        var _c = this.state, include = _c.include, selectedAccessory = _c.selectedAccessory, loading = _c.loading, accessories = _c.accessories, confirmed = _c.confirmed;
        return (React.createElement(ProcessStep, { baseIdWithIteration: this.props.baseIdWithIteration, label: label, subtitle: intl.formatMessage('confirmAccessory.description', {
                accessoryType: intl.formatMessage(resolveAccessoryTypeTranslation(accessoryType.key)),
            }), confirmDisabled: include == null || (include && !selectedAccessory), loading: loading, confirmed: confirmed, activeContent: (React.createElement(React.Suspense, { fallback: React.createElement(Loading, null) },
                React.createElement(ButtonGroup, null,
                    React.createElement(Button, { id: "".concat(baseId, "_includeYes_button_").concat(i), primary: include != null && include, onClick: this.handleIncludeButtonClick(true) },
                        React.createElement(FormattedMessage, { id: 'common.yes' })),
                    React.createElement(Button, { id: "".concat(baseId, "_includeNo_button_").concat(i), primary: include != null && !include, onClick: this.handleIncludeButtonClick(false) },
                        React.createElement(FormattedMessage, { id: 'common.no' }))),
                include && accessories && (React.createElement(SelectWrapper, null,
                    React.createElement(Select, { id: "".concat(baseId, "_accessories_select_").concat(i), options: accessories, value: selectedAccessory, onChange: this.handleAccessoryChange, noOptionsMessage: function () { return intl.formatMessage('confirmAccessory.noAccessoriesFound'); } }))))), confirmedContent: (React.createElement(React.Fragment, null, include != null && ((include && selectedAccessory) || !include) && (React.createElement(CompletedStep, { title: include
                    ? intl.formatMessage('confirmAccessory.accessorySelectedConfirmation')
                    : intl.formatMessage('confirmAccessory.noAccessorySelectedConfirmation'), subtitle: include && selectedAccessory ? "".concat(selectedAccessory.label) : null })))), onConfirm: this.handleConfirm, onEditStep: this.handleEdit }));
    };
    return ConfirmAccessoryProcessStep;
}(React.PureComponent));
export default injectIntl(ConfirmAccessoryProcessStep);
var templateObject_1;

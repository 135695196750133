import reloadToast from './reloadToast';
var registerPwaOpeningHandler = function (reg) {
    var hidden = 'hidden';
    var visibilityChange = 'visibilitychange';
    if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden';
        visibilityChange = 'visibilitychange';
    }
    else if (typeof document['msHidden'] !== 'undefined') {
        hidden = 'msHidden';
        visibilityChange = 'msvisibilitychange';
    }
    else if (typeof document['webkitHidden'] !== 'undefined') {
        hidden = 'webkitHidden';
        visibilityChange = 'webkitvisibilitychange';
    }
    window.document.addEventListener(visibilityChange, function () {
        if (!document[hidden]) {
            // manually force detection of a potential update when the pwa is opened
            reg.update();
        }
    });
    return reg;
};
var handleServiceWorkerRegistrationSuccess = function (registration) {
    var registrationResult = registration;
    registerPwaOpeningHandler(registrationResult);
    if (registrationResult.waiting) {
        reloadToast();
    }
    registrationResult.onupdatefound = function () {
        var newWorker = registration.installing;
        if (newWorker) {
            newWorker.onstatechange = function () {
                switch (newWorker.state) {
                    case 'installed':
                        {
                            if (registrationResult.active) {
                                reloadToast();
                            }
                            else {
                                // First ever install.
                            }
                            break;
                        }
                    default:
                        break;
                }
            };
        }
    };
};
var handleServiceWorkerRegistrationFailed = function (registrationError) {
    // eslint-disable-next-line no-console
    console.log('SW registration failed: ', registrationError);
};
var handleWindowLoad = function () {
    if (!navigator.serviceWorker || process.env.NODE_ENV === 'development')
        return;
    navigator.serviceWorker.register('/service-worker.js')
        .then(handleServiceWorkerRegistrationSuccess)
        .catch(handleServiceWorkerRegistrationFailed);
};
var registerServiceWorker = function () {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', handleWindowLoad);
    }
};
export default {
    registerServiceWorker: registerServiceWorker,
};

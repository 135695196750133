var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Check } from 'Icons';
var CheckCircleContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 100%;\n  background-color: ", ";\n  width: 2rem;\n  height: 2rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  border-radius: 100%;\n  background-color: ", ";\n  width: 2rem;\n  height: 2rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])), function (props) { return props.theme.colors.green[50]; });
var CheckCircle = function () { return (React.createElement(CheckCircleContainer, null,
    React.createElement(Check, { fill: "white", width: "1.1rem", height: "1.1rem" }))); };
export default CheckCircle;
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'Components.Form';
import { CompletedStep, ProcessStep } from 'Components.Structure';
var ProcessStepInput = styled(Input)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: .5rem;\n"], ["\n  margin-bottom: .5rem;\n"])));
var ConfirmSerialNumberProcessStep = function (props) {
    var _a = useState(false), confirmed = _a[0], setConfirmed = _a[1];
    var _b = useState(props.device.serialNumber || ''), serialNumber = _b[0], setSerialNumber = _b[1];
    return (React.createElement(ProcessStep, { confirmed: confirmed, label: props.label, subtitle: "Confirm the device's serial number.", onEditStep: function () {
            props.onEdit();
            setConfirmed(!confirmed);
        }, confirmDisabled: serialNumber == null || serialNumber.length === 0, onConfirm: function () {
            if (serialNumber == null)
                return;
            setConfirmed(!confirmed);
            props.onConfirm(serialNumber);
        }, confirmedContent: (React.createElement(React.Fragment, null, props.serialNumber != null && (React.createElement(CompletedStep, { title: props.serialNumber, subtitle: "Serial Number Confirmed" })))), activeContent: (React.createElement(ProcessStepInput, { type: "text", alignText: "center", value: serialNumber, onChange: function (evt) {
                setSerialNumber(evt.currentTarget.value);
            } })) }));
};
export default ConfirmSerialNumberProcessStep;
var templateObject_1;

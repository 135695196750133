var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer, Flip } from 'react-toastify';
import styled, { ThemeProvider } from 'styled-components';
import { AutonomiseTheme } from '@visiontrack/component-library';
import { Button } from 'Components.Form';
import { NotFoundView, IndexView, LoginView, CallbackView, AccessDeniedView, ForgottenPasswordView } from 'Views';
import { Loading, GlobalStyle } from 'Components.Structure';
import 'react-toastify/dist/ReactToastify.css';
import '@here/maps-api-for-javascript/bin/mapsjs-ui.css';
export var theme = {
    fonts: {
        family: "'Open Sans', Helvetica, Arial, sans-serif;",
        sizes: {
            h1: '1.312rem',
            h2: '1.25rem',
            h3: '1.187rem',
            h4: '1.125rem',
            h5: '1.062rem',
            h6: '1rem',
            default: '1rem',
            small: '0.75rem',
            tiny: '0.625rem',
        },
        weights: {
            regular: 400,
            semibold: 600,
            bold: 700,
        },
    },
    colors: {
        black: '#000000',
        white: '#ffffff',
        greys: {
            dark: '#404040',
            mediumdark: '#7f7f7f',
            mediumlight: '#bfbfbf',
            light: '#f2f2f2',
        },
        blues: {
            default: '#387dc1',
            dark: '#265380',
            light: '#9abddf',
        },
        greens: {
            default: '#0FAF24',
            dark: '#0B7D1A',
            light: '#86D690',
        },
        oranges: {
            default: '#F7AF06',
            dark: '#C28800',
            light: '#FAD681',
        },
        reds: {
            default: '#f50000',
            dark: '#c10000',
            light: '#f97e7e',
        },
        vrn: '#f7d706',
    },
};
var ErrorContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"])));
var ErrorMessage = styled.h4(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: white;\n"], ["\n  color: white;\n"])));
var ErrorState = function (props) { return (React.createElement(ErrorContainer, null,
    React.createElement(ErrorMessage, null, "Something went wrong."),
    React.createElement(Button, { onClick: props.onReload }, "Reload"))); };
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App(props) {
        var _this = _super.call(this, props) || this;
        _this.handleRecover = function () {
            _this.setState({
                hasErrored: false,
            });
        };
        _this.state = {
            hasErrored: false,
        };
        return _this;
    }
    App.prototype.componentDidCatch = function () {
        this.setState({
            hasErrored: true,
        });
    };
    App.prototype.render = function () {
        return (React.createElement(ThemeProvider, { theme: AutonomiseTheme },
            React.createElement(React.Fragment, null,
                React.createElement(GlobalStyle, null),
                !this.state.hasErrored ? (React.createElement(React.Fragment, null,
                    React.createElement(Suspense, { fallback: React.createElement(Loading, { lightText: true }) },
                        React.createElement(Router, null,
                            React.createElement(Switch, null,
                                React.createElement(Route, { path: "/forgotten-password", component: ForgottenPasswordView }),
                                React.createElement(Route, { path: "/access-denied", component: AccessDeniedView }),
                                React.createElement(Route, { path: "/callback", component: CallbackView }),
                                React.createElement(Route, { path: "/login", component: LoginView }),
                                React.createElement(Route, { path: "/", component: IndexView }),
                                React.createElement(Route, { path: "*", component: NotFoundView })))),
                    React.createElement(ToastContainer, { transition: Flip }))) : (React.createElement(ErrorState, { onReload: this.handleRecover })))));
    };
    return App;
}(React.Component));
export default App;
var templateObject_1, templateObject_2;

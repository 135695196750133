var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'Components.Form';
import { CompletedStep, ProcessStep } from 'Components.Structure';
import { useIntl } from 'Intl';
var ProcessStepInput = styled(Input)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: .5rem;\n"], ["\n  margin-bottom: .5rem;\n"])));
var ConfirmSimNumberProcessStep = function (props) {
    var _a = useState(false), confirmed = _a[0], setConfirmed = _a[1];
    var _b = useState(props.device.simNumber || ''), simNumber = _b[0], setSimNumber = _b[1];
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(ProcessStep, { baseId: props.baseId, confirmed: confirmed, label: props.label, subtitle: formatMessage('confirmSimNumber.description'), onEditStep: function () {
            props.onEdit();
            setConfirmed(!confirmed);
        }, onConfirm: function () {
            if (simNumber == null)
                return;
            setConfirmed(!confirmed);
            props.onConfirm(simNumber);
        }, confirmedContent: (React.createElement(React.Fragment, null, props.simNumber != null && (React.createElement(CompletedStep, { title: props.simNumber, subtitle: formatMessage('confirmSimNumber.confirmation') })))), activeContent: (React.createElement(ProcessStepInput, { id: "".concat(props.baseId, "_simNumber_input"), type: "number", alignText: "center", value: simNumber, onChange: function (evt) { return (setSimNumber(evt.currentTarget.value)); } })) }));
};
export default ConfirmSimNumberProcessStep;
var templateObject_1;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { ProcessStep, CompletedStep } from 'Components.Structure';
import { PanicEventViewer } from './components';
var ConfirmPanicButtonEventProcessStep = /** @class */ (function (_super) {
    __extends(ConfirmPanicButtonEventProcessStep, _super);
    function ConfirmPanicButtonEventProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.handleEventFound = function () {
            _this.setState({
                confirmed: true,
            });
            _this.props.onConfirm(false);
        };
        _this.handleEdit = function () {
            _this.setState({
                confirmed: false,
            });
            _this.props.onEdit();
        };
        _this.handleTimeout = function () {
            _this.setState({ commandTimeout: true });
        };
        _this.handleSelfCertify = function () {
            _this.setState({ confirmed: true });
            _this.props.onConfirm(true);
        };
        _this.state = {
            confirmed: false,
            commandTimeout: false,
        };
        return _this;
    }
    ConfirmPanicButtonEventProcessStep.prototype.render = function () {
        var _a = this.props, label = _a.label, subtitle = _a.subtitle, completedTitle = _a.completedTitle, completedSubtitle = _a.completedSubtitle, deviceId = _a.deviceId, fromAlarms = _a.fromAlarms, baseId = _a.baseId, accessorySelfCertifed = _a.accessorySelfCertifed, showSelfCertify = _a.showSelfCertify;
        var commandTimeout = this.state.commandTimeout;
        return (React.createElement(ProcessStep, { baseId: baseId, label: label, subtitle: subtitle, confirmed: this.state.confirmed, activeContent: (React.createElement(PanicEventViewer, { baseId: baseId, fromAlarms: fromAlarms, deviceId: deviceId, onEventFound: this.handleEventFound, onTimeout: this.handleTimeout })), confirmedContent: (React.createElement(CompletedStep, { title: completedTitle, subtitle: completedSubtitle })), confirmDisabled: true, onConfirm: function () { }, onEditStep: this.handleEdit, showSelfCertify: showSelfCertify && commandTimeout, onSelfCertify: this.handleSelfCertify, hasSelfCertifed: accessorySelfCertifed }));
    };
    ConfirmPanicButtonEventProcessStep.defaultProps = {
        fromAlarms: false,
        completedSubtitle: null,
    };
    return ConfirmPanicButtonEventProcessStep;
}(React.PureComponent));
export default ConfirmPanicButtonEventProcessStep;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { toast } from 'react-toastify';
import { AuthService } from 'Services';
var resolveUrl = function (_a) {
    var url = _a.url, params = _a.params, config = _a.config;
    var baseUri = config != null && config.baseUri != null
        ? config.baseUri : window.location.origin;
    var cleanUrl = url[0] === '/'
        ? url : "/".concat(url);
    var encodedUrl = encodeURI("".concat(baseUri).concat(cleanUrl));
    var nextUrl = new URL(encodedUrl);
    if (params != null && Object.keys(params).length !== 0) {
        nextUrl.search = new URLSearchParams(params).toString();
    }
    return nextUrl;
};
var resolveHeaders = function (config) {
    var headers = {
        'Content-Type': 'application/json',
    };
    if (config == null || config.useBearerToken == null || config.useBearerToken) {
        var bearerToken = AuthService.getAccessToken();
        if (bearerToken == null)
            throw new Error('Bearer token null.');
        headers.Authorization = "Bearer ".concat(bearerToken);
    }
    if (config != null && config.contentType != null) {
        if (typeof config.contentType === 'boolean' && !config.contentType) {
            delete headers['Content-Type'];
        }
        else if (typeof config.contentType === 'string') {
            headers['Content-Type'] = config.contentType;
        }
    }
    return new Headers(headers);
};
var ApiErrorCode;
(function (ApiErrorCode) {
    ApiErrorCode[ApiErrorCode["SomethingWentWrong"] = 1] = "SomethingWentWrong";
    // Common Errors
    ApiErrorCode[ApiErrorCode["AccessDenied"] = 403] = "AccessDenied";
    ApiErrorCode[ApiErrorCode["NotFound"] = 404] = "NotFound";
    // Shared
    ApiErrorCode[ApiErrorCode["FoundDeviceNoVehicle"] = 1000] = "FoundDeviceNoVehicle";
    ApiErrorCode[ApiErrorCode["VehicleHasDeviceAssignment"] = 1001] = "VehicleHasDeviceAssignment";
    ApiErrorCode[ApiErrorCode["FoundVehicleNoDevice"] = 1002] = "FoundVehicleNoDevice";
    ApiErrorCode[ApiErrorCode["DeviceHasVehicleAssigned"] = 1003] = "DeviceHasVehicleAssigned";
})(ApiErrorCode || (ApiErrorCode = {}));
var getMessage = function (response, payload) {
    var _a;
    if (process.env.NODE_ENV !== 'development') {
        console.warn(payload); // eslint-disable-line no-console
        if (typeof payload === 'object' && typeof payload.detail === 'string' && [400, 403].includes(response.status)) {
            if (((_a = window.ATNM) === null || _a === void 0 ? void 0 : _a.intl) != null && typeof (payload === null || payload === void 0 ? void 0 : payload.errorCode) === 'number') {
                return window.ATNM.intl.formatMessage("errorCode.".concat(payload.errorCode));
            }
            return payload.detail;
        }
        return 'Something went wrong.';
    }
    var toastMessage = [];
    if (payload == null)
        return '[Empty Content]';
    if (typeof (payload) === 'string')
        return payload;
    if (typeof (payload) === 'object') {
        if (typeof (payload.detail) === 'string')
            toastMessage.push(payload.detail);
        var status_1 = response.status, statusText = response.statusText;
        if (status_1 != null)
            toastMessage.push(status_1);
        if (statusText != null)
            toastMessage.push(statusText);
        if (payload != null && typeof payload === 'object') {
            if (payload.detail != null)
                toastMessage.push(payload.detail);
        }
    }
    // As a catch-all just stringify the response if we get
    // this far with no message content.
    if (toastMessage.length === 0)
        return JSON.stringify(payload);
    return toastMessage.join(' | ');
};
/**
 * Base API class.
 */
var BaseApi = /** @class */ (function () {
    function BaseApi() {
        var _this = this;
        this.handleSuccess = function (response) { return __awaiter(_this, void 0, void 0, function () {
            var contentType, contentLength, typeValid, lengthValid, result, _a, textContent, message;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(response.status >= 200 && response.status <= 399)) return [3 /*break*/, 4];
                        contentType = response.headers.get('Content-Type');
                        contentLength = parseInt(response.headers.get('Content-Length') || '', 10);
                        typeValid = typeof (contentType) === 'string' && contentType.indexOf('application/json') !== -1;
                        lengthValid = (Number.isNaN(contentLength) || contentLength > 0);
                        if (!(typeValid && lengthValid)) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.json()];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        _a = {};
                        _b.label = 3;
                    case 3:
                        result = _a;
                        return [2 /*return*/, {
                                isSuccess: true,
                                payload: result,
                            }];
                    case 4: return [4 /*yield*/, response.text()];
                    case 5:
                        textContent = _b.sent();
                        message = getMessage(response, JSON.parse(textContent));
                        toast.error(message);
                        return [2 /*return*/, {
                                isSuccess: false,
                                payload: {
                                    message: message,
                                },
                            }];
                }
            });
        }); };
        this.handleFailure = function (response) { return __awaiter(_this, void 0, void 0, function () {
            var textContent, _a, message;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(typeof response.text === 'function')) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.text()];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        _a = response;
                        _b.label = 3;
                    case 3:
                        textContent = _a;
                        message = getMessage(response, textContent);
                        toast.error(message);
                        return [2 /*return*/, {
                                isSuccess: false,
                                payload: {
                                    message: message,
                                },
                            }];
                }
            });
        }); };
    }
    BaseApi.prototype.getPortalBase = function () {
        return AuthService.getPortalBase();
    };
    BaseApi.prototype.makeAjax = function (properties) {
        return __awaiter(this, void 0, void 0, function () {
            var data, method, config, otherProperies, url, headers, body;
            var _this = this;
            return __generator(this, function (_a) {
                data = properties.data, method = properties.method, config = properties.config, otherProperies = __rest(properties, ["data", "method", "config"]);
                url = resolveUrl(__assign({ config: config }, otherProperies));
                headers = resolveHeaders(config);
                if (method === 'get' || method === 'delete') {
                    return [2 /*return*/, fetch(url.toString(), { method: method, headers: headers }).then(function (resp) { return _this.handleSuccess(resp); }).catch(function (reason) { return _this.handleFailure(reason); })];
                }
                if (headers.get('Content-Type') === 'application/json') {
                    body = JSON.stringify(data);
                }
                else {
                    body = data;
                }
                return [2 /*return*/, fetch(url.toString(), { method: method, body: body, headers: headers }).then(function (resp) { return _this.handleSuccess(resp); }).catch(function (reason) { return _this.handleFailure(reason); })];
            });
        });
    };
    BaseApi.prototype.get = function (properties) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.makeAjax(__assign(__assign({}, properties), { method: 'get' }))];
            });
        });
    };
    BaseApi.prototype.post = function (properties) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.makeAjax(__assign(__assign({}, properties), { method: 'post' }))];
            });
        });
    };
    BaseApi.prototype.put = function (properties) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.makeAjax(__assign(__assign({}, properties), { method: 'put' }))];
            });
        });
    };
    BaseApi.prototype.delete = function (properties) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.makeAjax(__assign(__assign({}, properties), { method: 'delete' }))];
            });
        });
    };
    return BaseApi;
}());
export default BaseApi;

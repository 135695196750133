import Check from './check.svg';
import Cross from './cross.svg';
import Camera from './camera.svg';
import Refresh from './refresh.svg';
import QuestionOctagon from './question-octagon.svg';
import Expand from './expand.svg';
Camera.displayName = 'Icon.Camera';
Check.displayName = 'Icon.Check';
Cross.displayName = 'Icon.Cross';
Refresh.displayName = 'Icons.Refresh';
QuestionOctagon.displayName = 'Icons.QuestionOctagon';
Expand.displayName = 'Icons.Expand';
export { Check, Cross, Camera, Refresh, QuestionOctagon, Expand, };

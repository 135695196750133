import React, { useState } from 'react';
import 'react-html5-camera-photo/build/css/index.css';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { ImagePreview } from 'Components.Camera';
import { Button } from 'Components.Form';
import { FormattedMessage } from 'Intl';
var CameraTools = function (props) {
    var _a = useState(''), dataUri = _a[0], setDataUri = _a[1];
    var handleTakePhotoAnimationDone = function (nextDataUri) {
        setDataUri(nextDataUri);
    };
    var handleTakePhoto = function (nextDataUri) {
        props.onConfirm(nextDataUri);
    };
    var handleRetake = function () {
        setDataUri('');
    };
    return (React.createElement("div", null, (dataUri)
        ? (React.createElement(React.Fragment, null,
            React.createElement(ImagePreview, { dataUri: dataUri }),
            React.createElement(Button, { id: "".concat(props.baseId, "_retake_button"), primary: true, fullWidth: true, onClick: handleRetake },
                React.createElement(FormattedMessage, { id: 'cameraTools.retakePhoto' }))))
        : (React.createElement(Camera, { onTakePhoto: handleTakePhoto, onTakePhotoAnimationDone: handleTakePhotoAnimationDone, idealFacingMode: FACING_MODES.ENVIRONMENT, idealResolution: { width: 640, height: 480 }, imageType: IMAGE_TYPES.JPG, imageCompression: 0.97, isMaxResolution: true, isImageMirror: false, isSilentMode: false, isDisplayStartCameraError: true, isFullscreen: false, sizeFactor: 1 }))));
};
CameraTools.defaultProps = {
    baseId: '',
};
export default CameraTools;

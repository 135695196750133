var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import BarLoader from 'react-spinners/BarLoader';
import { useIntl } from 'Intl';
var LoadingContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  color: ", ";\n  flex: 1;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  justify-content: center;\n  color: ", ";\n  flex: 1;\n  height: 100%;\n"])), function (props) { return (props.lightText ? 'white' : 'inherit'); });
var Loading = function (props) {
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(LoadingContainer, { lightText: props.lightText },
        props.includeText && formatMessage('common.loading'),
        React.createElement(BarLoader, { color: props.barColor })));
};
Loading.defaultProps = {
    lightText: false,
    barColor: '#ffffff',
    includeText: true,
};
export default Loading;
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var Label = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: ", ";\n"], ["\n  font-weight: ", ";\n  font-size: ", ";\n"])), function (props) { return props.theme.type.weight.semibold; }, function (props) { return props.theme.type.sizes.small; });
var Text = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n"])), function (props) { return props.theme.colors.grey[25]; }, function (props) { return props.theme.type.sizes.default; });
var Wrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: column;\n  margin-bottom: .5rem;\n\n  & + & {\n    margin-top: .3rem;\n  }\n"], ["\n  display: flex;\n  flex-flow: column;\n  margin-bottom: .5rem;\n\n  & + & {\n    margin-top: .3rem;\n  }\n"])));
var LabelledValue = function (props) { return (React.createElement(Wrapper, { id: props.id },
    props.label && React.createElement(Label, null, props.label),
    React.createElement(Text, null, props.children))); };
LabelledValue.defaultProps = {
    label: null,
    id: null,
};
export default LabelledValue;
var templateObject_1, templateObject_2, templateObject_3;

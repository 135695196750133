var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { ConfirmAccessoryProcessStep } from 'Components.Structure';
import { injectIntl } from 'Intl';
var ReviewAccessoriesProcessStep = /** @class */ (function (_super) {
    __extends(ReviewAccessoriesProcessStep, _super);
    function ReviewAccessoriesProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.handleAccessoryConfirm = function (car) {
            var accessoryIndex = _this.state.accessoryIndex;
            var _a = _this.props, accessoryTypes = _a.accessoryTypes, onConfirm = _a.onConfirm;
            if (accessoryTypes == null)
                return;
            if (accessoryIndex < accessoryTypes.length) {
                var nextIndex_1 = accessoryIndex + 1;
                var reviewComplete_1 = nextIndex_1 === accessoryTypes.length;
                _this.setState(function (prevState) { return ({
                    accessoryIndex: reviewComplete_1 ? prevState.accessoryIndex : nextIndex_1,
                    selectedAccessories: car.include && car.selectedAccessory != null
                        ? __spreadArray(__spreadArray([], prevState.selectedAccessories, true), [
                            {
                                id: car.selectedAccessory.value,
                                name: car.selectedAccessory.label,
                                catalogueId: car.selectedAccessory.catalogueId,
                                accessoryType: car.accessoryType,
                            },
                        ], false) : prevState.selectedAccessories,
                }); }, function () {
                    if (reviewComplete_1) {
                        onConfirm(_this.state.selectedAccessories);
                    }
                });
            }
        };
        _this.handleAccessoryEdit = function (id) { return function () {
            var accessoryTypes = _this.props.accessoryTypes;
            if (accessoryTypes == null)
                return;
            var foundIndex = accessoryTypes.findIndex(function (a) { return a.id === id; });
            _this.setState(function (prevState) { return ({
                accessoryIndex: foundIndex,
                selectedAccessories: prevState.selectedAccessories.filter(function (a, i) { return i < foundIndex; }),
            }); });
            _this.props.onEdit();
        }; };
        _this.state = {
            accessoryIndex: 0,
            selectedAccessories: [],
        };
        return _this;
    }
    ReviewAccessoriesProcessStep.prototype.componentDidMount = function () {
        var _a = this.props, accessoryTypes = _a.accessoryTypes, onSkipAccessories = _a.onSkipAccessories;
        if (accessoryTypes == null || accessoryTypes.length === 0) {
            onSkipAccessories();
        }
    };
    ReviewAccessoriesProcessStep.prototype.render = function () {
        var _this = this;
        var _a = this.props, accessoryTypes = _a.accessoryTypes, device = _a.device, startStep = _a.startStep, baseId = _a.baseId, intl = _a.intl;
        var accessoryIndex = this.state.accessoryIndex;
        if (accessoryTypes == null)
            return null;
        return (React.createElement(React.Fragment, null, accessoryTypes
            .filter(function (r, i) { return i <= accessoryIndex; })
            .map(function (r, i) { return (React.createElement(ConfirmAccessoryProcessStep, { baseIdWithIteration: { baseId: baseId, i: i }, label: intl.formatMessage('common.stepLabel', {
                stepNumber: (startStep || 0) + i,
            }), key: r.key, device: device, accessoryType: r, onConfirm: _this.handleAccessoryConfirm, onEdit: _this.handleAccessoryEdit(r.id) })); })));
    };
    ReviewAccessoriesProcessStep.defaultProps = {
        startStep: 1,
    };
    return ReviewAccessoriesProcessStep;
}(React.PureComponent));
export default injectIntl(ReviewAccessoriesProcessStep);

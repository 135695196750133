var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonGroup } from 'Components.Form';
import { CompletedStep, ProcessStep } from 'Components.Structure';
import { FormattedMessage } from 'Intl';
var ProcessStepButtonGroup = styled(ButtonGroup)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: .5rem;\n"], ["\n  margin-bottom: .5rem;\n"])));
var YesNoProcessStep = function (props) {
    var _a = useState(false), confirmed = _a[0], setConfirmed = _a[1];
    var _b = useState(props.defaultChoice), yesNo = _b[0], setYesNo = _b[1];
    return (React.createElement(ProcessStep, { baseId: props.baseId, confirmed: confirmed, label: props.label, subtitle: props.subtitle, onEditStep: function () {
            props.onEdit();
            setConfirmed(!confirmed);
        }, confirmDisabled: yesNo == null, onConfirm: function () {
            if (yesNo == null)
                return;
            setConfirmed(!confirmed);
            props.onConfirm(yesNo);
        }, confirmedContent: (React.createElement(React.Fragment, null, yesNo != null && (React.createElement(CompletedStep, { title: yesNo
                ? props.yesLabel || (React.createElement(FormattedMessage, { id: 'common.yes' }))
                : props.noLabel || (React.createElement(FormattedMessage, { id: 'common.no' })), subtitle: yesNo ? props.completedYesSubtitle : props.completedNoSubtitle })))), activeContent: (React.createElement(ProcessStepButtonGroup, null,
            React.createElement(Button, { id: "".concat(props.baseId, "_yes_button"), primary: yesNo != null && yesNo, onClick: function () { return setYesNo(true); } }, props.yesLabel || (React.createElement(FormattedMessage, { id: 'common.yes' }))),
            React.createElement(Button, { id: "".concat(props.baseId, "_no_button"), primary: yesNo != null && !yesNo, onClick: function () { return setYesNo(false); } }, props.noLabel || (React.createElement(FormattedMessage, { id: 'common.no' }))))) }));
};
YesNoProcessStep.defaultProps = {
    yesLabel: null,
    noLabel: null,
    completedYesSubtitle: 'Selected Yes',
    completedNoSubtitle: 'Selected No',
    defaultChoice: null,
    baseId: null,
};
export default YesNoProcessStep;
var templateObject_1;

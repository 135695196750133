function debounce(func, debounceTime, immediate) {
    var timeout;
    var args;
    var context;
    var timestamp;
    var result;
    var wait = debounceTime || 100;
    function later() {
        var last = Date.now() - timestamp;
        if (last < wait && last >= 0) {
            timeout = setTimeout(later, wait - last);
        }
        else {
            timeout = null;
            if (!immediate) {
                result = func.apply(context, args);
                context = null;
                args = null;
            }
        }
    }
    function debounced() {
        context = this;
        args = arguments; // eslint-disable-line prefer-rest-params
        timestamp = Date.now();
        var callNow = immediate && !timeout;
        if (!timeout)
            timeout = setTimeout(later, wait);
        if (callNow) {
            result = func.apply(context, args);
            context = null;
            args = null;
        }
        return result;
    }
    debounced.clear = function () {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
    };
    debounced.flush = function () {
        if (timeout) {
            result = func.apply(context, args);
            context = null;
            args = null;
            clearTimeout(timeout);
            timeout = null;
        }
    };
    return debounced;
}
export default debounce;

export var vehicleTypes = [
    {
        label: "Car",
        value: 1,
        intlKey: "vehicleType.1"
    },
    {
        label: "Van",
        value: 2,
        intlKey: "vehicleType.2"
    },
    {
        label: "Tractor Trailer",
        value: 3,
        intlKey: "vehicleType.3",
    },
    {
        label: "Small Rigid Truck",
        value: 4,
        intlKey: "vehicleType.4",
    },
    {
        label: "Large Rigid Truck",
        value: 5,
        intlKey: "vehicleType.5",
    }
];

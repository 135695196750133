var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import './CustomScrollbars.scss';
var CustomScrollbars = React.forwardRef(function (props, ref) { return (React.createElement(Scrollbars, __assign({ ref: ref, className: props.className, renderThumbVertical: function (_a) {
        var style = _a.style, scrollbarProps = __rest(_a, ["style"]);
        return (React.createElement("div", __assign({}, scrollbarProps, { style: __assign(__assign({}, style), { width: '8px' }), className: "vt-custom-scrollbar" })));
    }, renderThumbHorizontal: function (_a) {
        var style = _a.style, scrollbarProps = __rest(_a, ["style"]);
        return (React.createElement("div", __assign({}, scrollbarProps, { style: __assign(__assign({}, style), { width: '8px' }), className: "vt-custom-scrollbar" })));
    } }, props), props.children)); });
export default CustomScrollbars;

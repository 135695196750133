var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { CheckCircle } from 'Components.Structure';
var CompletedStepContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: row;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-flow: row;\n  align-items: center;\n"])));
var CompletedStepInfo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n  padding-left: .5rem;\n"], ["\n  flex: 1;\n  padding-left: .5rem;\n"])));
var CompletedStepHeader = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: bold;\n"], ["\n  font-weight: bold;\n"])));
var CompletedStepSubtitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: ", ";\n"], ["\n  font-size: ", ";\n"])), function (props) { return props.theme.type.sizes.small; });
var CompletedStep = function (_a) {
    var title = _a.title, subtitle = _a.subtitle;
    return (React.createElement(CompletedStepContainer, null,
        React.createElement(CheckCircle, null),
        React.createElement(CompletedStepInfo, null,
            React.createElement(CompletedStepHeader, null, title || '-'),
            subtitle && React.createElement(CompletedStepSubtitle, null, subtitle))));
};
export default CompletedStep;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

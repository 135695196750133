var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Button } from 'Components.Form';
import { CalendarDate } from 'Components.Structure';
import { HealthCheckType } from 'Types/commissioning';
import { useIntl, FormattedMessage } from 'Intl';
import HealthCheckText from './HealthCheckText';
import DeviceHealthRow from './DeviceHealthRow';
var DeviceHealthContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: .5rem;\n  border-radius: 3px;\n  background-color: #fafafa;\n  display: flex;\n  flex-flow: column;\n"], ["\n  padding: .5rem;\n  border-radius: 3px;\n  background-color: #fafafa;\n  display: flex;\n  flex-flow: column;\n"])));
var HealthCheckDivider = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-top: 1px solid ", ";\n  margin: .5rem 0;\n"], ["\n  border-top: 1px solid ", ";\n  margin: .5rem 0;\n"])), function (props) { return props.theme.colors.grey[25]; });
var HealthCheckNeverReported = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  margin: 0;\n"], ["\n  color: ", ";\n  margin: 0;\n"])), function (props) { return props.theme.colors.red[50]; });
var resolveHelpOptions = function (key) {
    switch (key) {
        case HealthCheckType.Firmware:
            return [
                'confirmDeviceHealth.firmwareError.blacklisted',
            ];
        case HealthCheckType.SdCard:
            return [
                'confirmDeviceHealth.sdError.locked',
            ];
        case HealthCheckType.Storage:
            return [
                'confirmDeviceHealth.storageError.storageFull',
                'confirmDeviceHealth.storageError.corruptedFiles',
            ];
        case HealthCheckType.Location:
            return [
                'confirmDeviceHealth.locationReceivedError.notPointingToPlatform',
                'confirmDeviceHealth.locationReceivedError.notTurnedOn',
                'confirmDeviceHealth.locationReceivedError.noSimCard',
                'confirmDeviceHealth.locationReceivedError.noGsmSignal',
                'confirmDeviceHealth.locationReceivedError.noGpsSignal',
            ];
        case HealthCheckType.GPS:
            return [
                'confirmDeviceHealth.gpsError.noSignal',
            ];
        case HealthCheckType.TelemetryRaw:
            return [
                'confirmDeviceHealth.driveDataError.notReceived',
            ];
        default:
            throw new Error('One or more health check types not accounted for.');
    }
};
var resolveEngineerActions = function (key) {
    switch (key) {
        case HealthCheckType.GPS:
            return [
                'confirmDeviceHealth.gpsError.locationServicesAndDistanceResolution',
            ];
        default:
            return null;
    }
};
var DeviceHealthStep = function (props) {
    if (props.deviceHealth == null)
        return null;
    var formatMessage = useIntl().formatMessage;
    var _a = props.deviceHealth, checks = _a.checks, lastLocation = _a.lastLocation, lastReported = _a.lastReported;
    return (React.createElement(DeviceHealthContainer, null,
        checks.map(function (c, i) { return (React.createElement(DeviceHealthRow, { baseIdWithIteration: {
                baseId: props.baseId,
                i: i,
            }, key: c.key, labelType: c.key, valid: c.valid, helpOptions: resolveHelpOptions(c.key), engineerActions: resolveEngineerActions(c.key) })); }),
        React.createElement(HealthCheckDivider, null),
        React.createElement(HealthCheckText, { id: "".concat(props.baseId, "_lastLocation_container"), label: formatMessage('confirmDeviceHealth.lastLocation') }, lastLocation || (React.createElement(FormattedMessage, { id: 'common.unknown' }))),
        React.createElement(HealthCheckText, { id: "".concat(props.baseId, "_lastReported_container"), label: formatMessage('confirmDeviceHealth.lastReported') }, lastReported
            ? React.createElement(CalendarDate, { date: lastReported, fallback: "-" })
            : (React.createElement(HealthCheckNeverReported, null,
                React.createElement(FormattedMessage, { id: 'confirmDeviceHealth.neverReported' })))),
        React.createElement(Button, { id: "".concat(props.baseId, "_runAgain_button"), primaryText: true, onClick: props.onRefresh },
            React.createElement(FormattedMessage, { id: 'common.runAgain' }))));
};
export default DeviceHealthStep;
var templateObject_1, templateObject_2, templateObject_3;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { ConfirmPanicButtonEventProcessStep } from 'Components.Structure';
import { injectIntl } from 'Intl';
import { resolveAccessoryTranslation } from 'Utils';
var onlyHandledAccessoryTypes = function (accessory) {
    var _a;
    return (((_a = accessory.accessoryType) === null || _a === void 0 ? void 0 : _a.key) === 'PANIC_BUTTONS');
};
var CalibrateAccessoriesProcessStep = /** @class */ (function (_super) {
    __extends(CalibrateAccessoriesProcessStep, _super);
    function CalibrateAccessoriesProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.handleConfirm = function (hasSelfCertifed) {
            var _a = _this.state, currentIndex = _a.currentIndex, accessories = _a.accessories;
            var length = accessories.filter(onlyHandledAccessoryTypes).length;
            var nextIndex = currentIndex + 1;
            var calibrateComplete = length === nextIndex;
            var newAccessories = accessories;
            var accessory = newAccessories[currentIndex];
            accessory.tested = true;
            accessory.selfCertified = hasSelfCertifed;
            _this.setState({
                currentIndex: calibrateComplete ? currentIndex : nextIndex,
                accessories: newAccessories,
            }, function () {
                if (calibrateComplete) {
                    _this.props.onConfirm(accessories);
                }
            });
        };
        _this.handleEdit = function (index) { return function () {
            _this.setState({
                currentIndex: index,
            });
            _this.props.onEdit();
        }; };
        _this.handleIntegratedPanicConfirm = function (hasSelfCertifed) {
            _this.setState({
                integratedPanicCalibrated: true,
            });
            if (_this.state.accessories.filter(onlyHandledAccessoryTypes).length === 0) {
                var accessories_1 = [];
                _this.state.accessories.forEach(function (accessory) {
                    var newAccessory = accessory;
                    newAccessory.selfCertified = hasSelfCertifed;
                    accessories_1.push(newAccessory);
                });
                _this.props.onConfirm(accessories_1);
            }
        };
        _this.handleIntegratedPanicEdit = function () {
            _this.setState({
                currentIndex: 0,
                integratedPanicCalibrated: false,
            });
            _this.props.onEdit();
        };
        _this.state = {
            currentIndex: 0,
            integratedPanicCalibrated: props.hasIntegratedPanicButton ? false : null,
            accessories: [],
        };
        return _this;
    }
    CalibrateAccessoriesProcessStep.prototype.componentDidMount = function () {
        var _a = this.props, hasIntegratedPanicButton = _a.hasIntegratedPanicButton, accessories = _a.accessories, onConfirm = _a.onConfirm;
        if (!hasIntegratedPanicButton && accessories.filter(onlyHandledAccessoryTypes).length === 0) {
            onConfirm(accessories);
        }
        this.setState({ accessories: accessories });
    };
    CalibrateAccessoriesProcessStep.prototype.render = function () {
        var _this = this;
        var _a = this.props, deviceId = _a.deviceId, startStep = _a.startStep, baseId = _a.baseId, intl = _a.intl, accessorySelfCertifed = _a.accessorySelfCertifed, showSelfCertify = _a.showSelfCertify;
        var _b = this.state, currentIndex = _b.currentIndex, integratedPanicCalibrated = _b.integratedPanicCalibrated, accessories = _b.accessories;
        return (React.createElement(React.Fragment, null,
            integratedPanicCalibrated != null && (React.createElement(ConfirmPanicButtonEventProcessStep, { baseId: "".concat(baseId, "_confirmPanicButtonEvent"), label: intl.formatMessage('common.stepLabel', {
                    stepNumber: startStep,
                }), subtitle: intl.formatMessage('calibrateAccessories.integratedPanicDescription'), completedTitle: intl.formatMessage('calibrateAccessories.eventReceivedTitle'), completedSubtitle: intl.formatMessage('calibrateAccessories.integratedCompletedSubtitle'), deviceId: deviceId, onConfirm: this.handleIntegratedPanicConfirm, onEdit: this.handleIntegratedPanicEdit, accessorySelfCertifed: accessorySelfCertifed, showSelfCertify: showSelfCertify })),
            integratedPanicCalibrated == null || integratedPanicCalibrated ? accessories
                .filter(onlyHandledAccessoryTypes)
                .map(function (accessory, index) {
                if (index > currentIndex)
                    return null;
                var accessoryName = accessory.catalogueId != null
                    ? intl.formatMessage(resolveAccessoryTranslation(accessory.catalogueId))
                    : accessory.name;
                return (React.createElement(ConfirmPanicButtonEventProcessStep, { baseId: "".concat(baseId, "_confirmPanicButtonEvent_").concat(index), label: intl.formatMessage('common.stepLabel', {
                        stepNumber: integratedPanicCalibrated != null
                            ? (startStep || 0) + index + 1
                            : (startStep || 0) + index,
                    }), subtitle: intl.formatMessage('calibrateAccessories.externalPanicDescription'), completedTitle: intl.formatMessage('calibrateAccessories.eventReceivedTitle'), completedSubtitle: intl.formatMessage('calibrateAccessories.accessoryRaisedPanicEvent', {
                        name: accessoryName,
                    }), deviceId: deviceId, fromAlarms: true, onConfirm: _this.handleConfirm, onEdit: _this.handleEdit(index), accessorySelfCertifed: accessorySelfCertifed, showSelfCertify: showSelfCertify }));
            }) : null));
    };
    CalibrateAccessoriesProcessStep.defaultProps = {
        hasIntegratedPanicButton: false,
        startStep: 1,
    };
    return CalibrateAccessoriesProcessStep;
}(React.PureComponent));
export default injectIntl(CalibrateAccessoriesProcessStep);

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var Input = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: none;\n  border-radius: 3px;\n  background-color: ", ";\n  height: ", ";\n  box-sizing: border-box;\n  width: 100%;\n  padding: .5rem;\n  font-family: ", ";\n  text-align: ", ";\n\n  &:focus {\n    outline: none;\n  }\n\n  & + & {\n    margin-top: .5rem;\n  }\n"], ["\n  border: none;\n  border-radius: 3px;\n  background-color: ", ";\n  height: ", ";\n  box-sizing: border-box;\n  width: 100%;\n  padding: .5rem;\n  font-family: ", ";\n  text-align: ", ";\n\n  &:focus {\n    outline: none;\n  }\n\n  & + & {\n    margin-top: .5rem;\n  }\n"])), function (props) { return props.theme.colors.grey[0]; }, function (props) { return props.height ? props.height : '2rem'; }, function (props) { return props.theme.type.family; }, function (props) { return (props.alignText === 'center' && 'center')
    || (props.alignText === 'left' && 'left)')
    || (props.alignText === 'right' && 'right')
    || 'left'; });
export default Input;
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Button } from 'Components.Form';
import { FormattedMessage } from 'Intl';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 1rem 0;\n  display: flex;\n  justify-content: center;\n"], ["\n  padding: 1rem 0;\n  display: flex;\n  justify-content: center;\n"])));
var ContinueProcess = function (props) { return (React.createElement(Container, null,
    React.createElement(Button, { id: "".concat(props.buttonId, "_saveAndContinue_button"), success: true, taller: true, disabled: props.disabled, onClick: props.onClick }, props.label || React.createElement(FormattedMessage, { id: 'common.saveAndContinue' })))); };
ContinueProcess.defaultProps = {
    label: null,
    disabled: false,
    buttonId: null,
};
export default ContinueProcess;
var templateObject_1;

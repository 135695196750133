var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var FileInput = styled.input.attrs({
    type: 'file',
})(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export default FileInput;
var templateObject_1;

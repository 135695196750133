var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useIntl } from 'Intl';
var ReactSelect = React.lazy(function () { return import(/* webpackChunkName: "react-select" */ 'react-select'); });
var AsyncReactSelect = React.lazy(function () { return import(/* webpackChunkName: "react-select.async" */ 'react-select/async'); });
var customStyle = {
    control: function (provided) { return (__assign(__assign({}, provided), { borderRadius: '3px', border: 'none', backgroundColor: '#f3f3f3', fontSize: '.8rem' })); },
    menu: function (provided) { return (__assign(__assign({}, provided), { fontSize: '.8rem', borderRadius: '3px' })); },
};
var Select = function (props) {
    var _a;
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(React.Suspense, { fallback: null },
        React.createElement(ReactSelect, __assign({ styles: __assign(__assign({}, customStyle), props.styles), placeholder: (_a = props.placeholder) !== null && _a !== void 0 ? _a : formatMessage('common.selectPlaceholder'), isMulti: props.IsMulti }, props))));
};
export var AsyncSelect = function (props) {
    var _a;
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(React.Suspense, { fallback: null },
        React.createElement(AsyncReactSelect, __assign({ styles: __assign(__assign({}, customStyle), props.styles), placeholder: (_a = props.placeholder) !== null && _a !== void 0 ? _a : formatMessage('common.selectPlaceholder'), loadingMessage: function () { var _a; return (_a = props.loadingMessage) !== null && _a !== void 0 ? _a : formatMessage('common.loading'); } }, props))));
};
export default Select;

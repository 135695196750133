import { lazy } from 'react';
import NotFoundView from './NotFoundView';
import LoginView from './LoginView';
import CallbackView from './CallbackView';
import AccessDeniedView from './AccessDeniedView';
var IndexView = lazy(function () { return import(/* webpackChunkName: "index-view" */ './IndexView'); });
var CommissionDeviceView = lazy(function () { return import(/* webpackChunkName: "commission-device-view" */ './CommissionDeviceView'); });
var JobHistoryView = lazy(function () { return import(/* webpackChunkName: "commission-history-view" */ './JobHistoryView'); });
var ForgottenPasswordView = lazy(function () { return import(/* webpackChunkName: "forgotten-password" */ './ForgottenPasswordView'); });
var DecommissionDeviceView = lazy(function () { return import(/* webpackChunkName: "commission-device-view" */ './DecommissionDeviceView'); });
var ReportingView = lazy(function () { return import(/* webpackChunkName: "reporting-view" */ './ReportingView'); });
var ServiceCallView = lazy(function () { return import(/* webpackChunkName: "service-call-view" */ './ServiceCallView'); });
var VehicleLocationView = lazy(function () { return import(/* webpackChunkName: "service-call-view" */ './VehicleLocationsView/VehicleLocationsView'); });
export { NotFoundView, IndexView, LoginView, CallbackView, CommissionDeviceView, JobHistoryView, AccessDeniedView, ForgottenPasswordView, DecommissionDeviceView, ReportingView, ServiceCallView, VehicleLocationView, };

import StorageService from './StorageService';
var AuthService = /** @class */ (function () {
    function AuthService() {
    }
    AuthService.getItem = function (key) {
        var item = StorageService.get(key);
        if (item != null) {
            return JSON.parse(item);
        }
        return null;
    };
    AuthService.getAccessToken = function () {
        if (window.ATNM == null)
            return null;
        var user = AuthService.getItem("oidc.user:".concat(window.ATNM.userManager.settings.authority, ":").concat(window.ATNM.userManager.settings.client_id));
        if (user == null)
            return null;
        return user.access_token;
    };
    AuthService.getAccessTokenDetail = function () {
        var accessToken = AuthService.getAccessToken();
        if (accessToken == null)
            return null;
        var splitToken = accessToken.split('.');
        if (splitToken[1] == null)
            return null;
        return JSON.parse(atob(splitToken[1]));
    };
    AuthService.isEngineer = function () {
        var user = AuthService.getAccessTokenDetail();
        return user != null && user.role.includes('engineer');
    };
    AuthService.isVTAdmin = function () {
        var user = AuthService.getAccessTokenDetail();
        return user != null && user.role.includes('vt-admin');
    };
    AuthService.isLoggedIn = function () {
        var token = AuthService.getAccessTokenDetail();
        if (token == null)
            return false;
        var organisationIds = token.organisation_id;
        if (window.appInsights != null && token.email != null) {
            window.appInsights.setAuthenticatedUserContext(token.email, Array.isArray(organisationIds) ? organisationIds[0] : organisationIds, true);
        }
        else if (window.appInsights != null && token.first_name != null && token.last_name != null) {
            window.appInsights.setAuthenticatedUserContext("".concat(token.first_name, " ").concat(token.last_name), Array.isArray(organisationIds) ? organisationIds[0] : organisationIds, true);
        }
        var exp = token.exp;
        return exp && new Date(exp * 1000) > new Date();
    };
    AuthService.logout = function () {
        if (window.ATNM == null) {
            throw new Error('Unable to log out, window.ATNM not set.');
        }
        window.ATNM.userManager.signoutRedirect();
    };
    AuthService.getPortalBase = function () {
        var handshake = StorageService.getPersisted({ key: 'vt:eng:endpoints' });
        if (handshake == null)
            return null;
        var json = JSON.parse(handshake);
        return json.baseUri;
    };
    return AuthService;
}());
export default AuthService;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styled from 'styled-components';
import { ProcessStep, CompletedStep } from 'Components.Structure';
import { Input } from 'Components.Form';
import { injectIntl, FormattedMessage } from 'Intl';
var VrnVinContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: column;\n  margin-bottom: .5rem;\n  border-bottom: 1px solid #f2f2f2;\n  padding-bottom: .5rem;\n"], ["\n  display: flex;\n  flex-flow: column;\n  margin-bottom: .5rem;\n  border-bottom: 1px solid #f2f2f2;\n  padding-bottom: .5rem;\n"])));
var Or = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", ";\n  text-align: center;\n  text-transform: uppercase;\n  padding: .2rem 0;\n  flex: 1;\n"], ["\n  font-size: ", ";\n  text-align: center;\n  text-transform: uppercase;\n  padding: .2rem 0;\n  flex: 1;\n"])), function (props) { return props.theme.type.sizes.small; });
var resolveConfirmDisabled = function (state) { return (state.make.length === 0
    || state.model.length === 0
    || ((state.vrn.length === 0 || !state.vrn.replace(/\s/g, '').length) && (state.vin.length === 0 || !state.vin.replace(/\s/g, '').length))); };
var CreateVehicleProcessStep = /** @class */ (function (_super) {
    __extends(CreateVehicleProcessStep, _super);
    function CreateVehicleProcessStep(props) {
        var _this = _super.call(this, props) || this;
        _this.handleConfirm = function () {
            var _a = _this.state, make = _a.make, model = _a.model, vrn = _a.vrn, vin = _a.vin;
            _this.props.onConfirm({
                make: make,
                model: model,
                vrn: vrn,
                vin: vin,
            });
            _this.setState({
                confirmed: true,
            });
        };
        _this.handleEditStep = function () {
            _this.props.onEdit();
            _this.setState({
                confirmed: false,
            });
        };
        _this.handleInputChange = function (evt) {
            var _a;
            var currentTarget = evt.currentTarget;
            _this.setState((_a = {},
                _a[currentTarget.name] = currentTarget.value,
                _a));
        };
        _this.state = {
            confirmed: false,
            make: '',
            model: '',
            vrn: '',
            vin: '',
        };
        return _this;
    }
    CreateVehicleProcessStep.prototype.render = function () {
        var _a = this.props, baseId = _a.baseId, intl = _a.intl;
        return (React.createElement(ProcessStep, { baseId: baseId, label: this.props.label, subtitle: intl.formatMessage('createVehicle.description'), confirmed: this.state.confirmed, confirmDisabled: resolveConfirmDisabled(this.state), activeContent: (React.createElement(React.Fragment, null,
                React.createElement(VrnVinContainer, null,
                    React.createElement(Input, { id: "".concat(baseId, "_vrn_input"), type: "text", placeholder: intl.formatMessage('common.vrn'), name: "vrn", value: this.state.vrn, onChange: this.handleInputChange }),
                    React.createElement(Or, null,
                        React.createElement(FormattedMessage, { id: 'common.or' })),
                    React.createElement(Input, { id: "".concat(baseId, "_vin_input"), type: "text", placeholder: intl.formatMessage('common.vin'), name: "vin", value: this.state.vin, onChange: this.handleInputChange })),
                React.createElement(Input, { id: "".concat(baseId, "_make_input"), type: "text", placeholder: intl.formatMessage('common.vehicleMake'), name: "make", value: this.state.make, onChange: this.handleInputChange }),
                React.createElement(Input, { id: "".concat(baseId, "_model_input"), type: "text", placeholder: intl.formatMessage('common.vehicleModel'), name: "model", value: this.state.model, onChange: this.handleInputChange }))), confirmedContent: (React.createElement(React.Fragment, null, this.props.vehicle && (React.createElement(CompletedStep, { title: this.props.vehicle.vrn || this.props.vehicle.vin, subtitle: "".concat(this.props.vehicle.make || '-', " ").concat(this.props.vehicle.model || '-') })))), stepLevel: 1, onConfirm: this.handleConfirm, onEditStep: this.handleEditStep }));
    };
    return CreateVehicleProcessStep;
}(React.Component));
export default injectIntl(CreateVehicleProcessStep);
var templateObject_1, templateObject_2;
